<template lang="html">
  <div class="">
    <form
      id="new_comment"
      class="ui reply form"
      :class="{ loading: loading }"
      @submit.stop.prevent="submitForm"
    >
      <div class="field">
        <text-editor
          ref="editor"
          v-model="newComment.comment"
          :submit-on-enter="true"
          class="top attached"
          @submit="submitForm"
        />

        <div class="ui bottom attached segment grid">
          <div class="sixteen wide column">
            <a
              class="ui primary button "
              @click="submitForm"
            >
              <span v-if="isPersisted">
                Update
              </span>
              <span v-else>
                Comment
              </span>
            </a>

            <uploads-index-view
              :key="newComment.id + 'uploader'"
              ref="uploader"
              :url="uploadsUrl"
              :allow-uploads="true"
              class="margin-top-small"
              @complete="uploadComplete"
            />
          </div>
        </div>

        <small>Hints: @mention someone to notify them via e-mail. Cmd+enter to
          submit</small>
      </div>

      <div
        v-if="errors"
        class="ui error message visible"
      >
        <div class="ui relaxed list">
          <div
            v-for="err in errors"
            class="item"
          >
            {{ err }}
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import store from "store";
import UploadsIndexView from "views/uploads/index.vue";
import TextEditor from "components/textEditor.vue";
import EventBus from "components/eventbus";

export default {
  store,
  components: {
    UploadsIndexView,
    TextEditor,
  },
  props: ["event", "commentsPath", "initialComment", "initialUploadsUrl"],
  data() {
    const $this = this;

    let blankComment = {
      id: null,
      title: null,
      comment: null,
      commentable_id: $this.event && $this.event.id,
      commentable_type: "Event",
    };

    return {
      newComment: $this.initialComment || blankComment,
      loading: false,
      uploadsUrl:
        $this.initialUploadsUrl ||
        "/uploads?assetable_type=Comment&assetable_id=",
      errors: null,
    };
  },
  computed: {
    isPersisted() {
      return this.newComment.id;
    },
  },

  watch: {
    "newComment.comment": function(val) {
      if (val == "" || !val) {
        EventBus.$emit("eventModalClean", "comments");
      } else {
        EventBus.$emit("eventModalDirty", "comments");
      }
    },
  },

  mounted() {},

  methods: {
    submitForm() {
      this.loading = true;
      if (!this.isPersisted) {
        this.create();
      } else {
        this.update();
      }
      return false;
    },

    update() {
      this.errors = null;
      this.$store
        .dispatch("comment/update", this.newComment)
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.$refs.uploader.removeAllFiles();
          this.loading = false;
          this.$emit("updated", this.newComment);
        });
    },

    create() {
      this.errors = null;
      this.loading = true;
      this.$store
        .dispatch("comment/create", this.newComment)
        .catch((err) => {
          this.errors = err.response.data;
        })
        .finally(() => {
          this.clearForm();
          this.$refs.uploader.removeAllFiles();
          this.loading = false;
        });
    },
    clearForm() {
      this.$refs.editor.clear();
      EventBus.$emit("eventModalClean", "comments");
      this.$set(this.newComment, "upload_ids", []);
    },
    uploadComplete(asset) {
      this.newComment.upload_ids = this.newComment.upload_ids || [];
      this.newComment.upload_ids.push(asset.id);
    },
  },
};
</script>

<style lang="css"></style>
