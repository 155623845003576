<template lang="html">
  <div
    id="my-pitches"
    class="ui segment"
  >
    <div class="content">
      <h3 class="header">
        Proposed
      </h3>
    </div>
    <div class="content">
      <a
        v-if="pitches.length < 1"
        :href="newPitchLink"
        data-remote="true"
      >
        <div class="ui fluid button">
          <i class="ui icon add to calendar" />Add proposed
        </div>
      </a>
      <table
        v-if="pitches.length > 0"
        class="table ui top attached"
      >
        <tr v-for="pitch in pitches">
          <td>
            <a
              href="#"
              @click.prevent.stop="openEvent(pitch)"
            >{{
              pitch.title
            }}</a><small
              class="floated right"
            >({{ pitch.start | dateMonth }})</small>
          </td>
        </tr>
      </table>
    </div>
    <a
      v-if="pitches.length > 0"
      :href="newPitchLink"
      data-remote="true"
    >
      <div class="ui bottom attached button">
        <i class="ui icon add to calendar" />New pitch
      </div>
    </a>
  </div>
</template>

<script>
import EventBus from "components/eventbus";

const $ = window.jQuery;

export default {
  filters: {
    dateMonth(value) {
      const m = window.moment(value);
      return m.format("MMMM");
    },
  },
  props: {
    calendar: Object,
    newPitchLink: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      pitches: [],
    };
  },
  mounted() {
    const $this = this;
    $this.$nextTick(() => {
      $this.reloadData();
    });

    EventBus.$on("reloadPitches", () => {
      $this.reloadData(true);
    });
  },
  methods: {
    openEvent(pitch) {
      window.EventBus.$emit("openEventModal", pitch.url);
    },
    reloadData() {
      const calendarId = this.calendar.id;
      const that = this;
      const url = `/calendars/${calendarId}/events/pitches`;
      // console.log(url);
      return $.read(url).done((data) => {
        that.pitches = data;
      });
    },
  },
};
</script>

<style></style>
