const $ = window.jQuery;

export default {
  inserted(el) {
    $(document).ready(() => {
      // document.addEventListener("turbo:load", () => {
      // console.log(el);

      $(el).draggable({
        start() {
          return $(this).css({
            visibility: "hidden"
          });
        },
        stop() {
          return $(this).css({
            visibility: "visible"
          });
        },
        helper() {
          return $(this)
            .clone()
            .css({
              width: $(this).width()
            });
        },
        zIndex: 999,
        revert: true,
        revertDuration: 0
      });
    });
  }
};
