import { Controller } from "@hotwired/stimulus";
import { parse } from "smtp-address-parser";
import { throttle } from "lodash";

window.emailParse = parse;
const $ = window.jQuery;

export default class extends Controller {
  static targets = ["email", "name", "company", "logo", "logoSrc"];

  static values = { host: String };

  parseEmail(e) {
    if (e.target.value) {
      try {
        console.log(e.target.value);
        var parsed = parse(e.target.value);
      } catch (error) {
        console.log(error);
      }
    }

    if (parsed) {
      var [company, tld] = parsed.domainPart.DomainName.split(".");

      if (!tld || tld.length < 2) {
        return false;
      }

      if (["gmail", "yahoo", "hotmail", "aol"].includes(company)) {
        return false;
      }

      // var fullName = parsed.localPart.DotString.replace(/\W+/g, " ")
      //   .replace(/\d+/, "")
      //   .trim();
      // if (fullName.split(" ").length > 1) {
      //   this.nameTarget.value = startCase(
      //     camelCase(
      //       parsed.localPart.DotString.replace(/\W+/g, " ").replace(/\d+/, "")
      //     )
      //   );
      // }

      // this.companyTarget.value = startCase(camelCase(company));

      // this.nameTarget.closest(".field").classList.remove("hidden");
      // this.companyTarget.closest(".field").classList.remove("hidden");

      if (parsed.domainPart.DomainName != this.logoTarget.src) {
        this.logoTarget.src = `//logo.clearbit.com/${parsed.domainPart.DomainName}`;
        this.logoSrcTarget.value = `https://logo.clearbit.com/${parsed.domainPart.DomainName}`;
      }

      // this.showUrlPreview(this.companyTarget);
    } else {
      // this.nameTarget.value = "";
      // this.companyTarget.value = "";
      // this.showUrlPreview(this.companyTarget);
      this.logoTarget.src = "";
      this.logoSrcTarget.value = "";
    }
  }

  clearbitError() {
    $(".clearbit").hide();
  }

  initialize() {
    this.parseEmail = throttle(this.parseEmail, 200);
  }

  connect() {}
}
