<template>
  <div class="field">
    <div class="two fields">
      <div class="field">
        <div class="ui calendar rangestart">
          <div class="ui input left icon">
            <i class="calendar alternate outline icon"></i>
            <input v-model.lazy="startDate" type="text" placeholder="Start" />
          </div>
        </div>
      </div>
      <div class="field">
        <div class="ui calendar rangeend">
          <div class="ui input left icon">
            <i class="calendar alternate outline icon"></i>
            <input v-model.lazy="endDate" type="text" placeholder="End" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { $ } = window;
const { moment } = window;

export default {
  name: "DateRangeRule",

  props: ["value"],

  data() {
    if (this.value != null) {
      var [initialStart, initialEnd] = this.value.split(",");
    }
    return {
      startDate: initialStart || null,
      endDate: initialEnd || null,
      rangeStart: null,
      rangeEnd: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initCalendars();
    });
  },
  watch: {
    value(val, oldVal) {
      if (val == "") {
        this.reset();
      }
    },
    startDate(val, oldVal) {
      if (val !== oldVal) {
        this.emitValue();
      }
    },
    endDate(val, oldVal) {
      if (val !== oldVal) {
        this.emitValue();
      }
    }
  },
  methods: {
    reset() {
      if (this.rangeStart) {
        this.rangeStart.calendar("clear");
      }
      if (this.rangeEnd) {
        this.rangeEnd.calendar("clear");
      }
    },
    emitValue() {
      if (this.startDate && this.endDate) {
        this.$emit("input", `${this.startDate},${this.endDate}`);
      }
    },
    initCalendars() {
      const $this = this;
      const formatter = {
        date: date => {
          if (!date) return "";
          return moment(date).format("YYYY-MM-DD");
        }
      };

      $this.rangeStart = $($this.$el).find(".rangestart");
      $this.rangeEnd = $($this.$el).find(".rangeend");

      $this.rangeStart.calendar({
        type: "date",
        endCalendar: $this.rangeEnd,
        formatter,
        onChange(date, text) {
          $this.rangeStart.find("input").trigger("change");
          $this.startDate = text;
        }
      });
      $this.rangeEnd.calendar({
        type: "date",
        startCalendar: $this.rangeStart,
        formatter,
        onChange(date, text) {
          $this.rangeEnd.find("input").trigger("change");
          $this.endDate = text;
        }
      });
    }
  }
};
</script>

<style lang="css" scoped></style>
