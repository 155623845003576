import consumer from "./consumer";

consumer.subscriptions.create("AppearancesChannel", {
  // Called once when the subscription is created.
  initialized() {
    this.update = this.update.bind(this);
  },

  // Called when the subscription is ready for use on the server.
  connected() {
    this.install();
    this.update();
  },

  // Called when the WebSocket connection is closed.
  disconnected() {
    this.uninstall();
  },

  received(data) {
    if (data.user != window.currentUserId) {
      if (data.presence == "online") {
        window
          .$(`[data-id='${data.user}']`)
          .addClass("online")
          .removeClass("away");
      } else if (data.presence == "away") {
        window
          .$(`[data-id='${data.user}']`)
          .addClass("away")
          .removeClass("online");
      }
    }
  },

  // Called when the subscription is rejected by the server.
  rejected() {
    this.uninstall();
  },

  update() {
    this.documentIsActive() ? this.appear() : this.away();
  },

  appear() {
    this.perform("appear", { appearing_on: this.appearingOn() });
  },

  away() {
    this.perform("away");
  },

  install() {
    window.addEventListener("blur", this.update);
    window.addEventListener("focus", this.update);
    document.addEventListener("turbo:load", this.update);
    document.addEventListener("visibilitychange", this.update);
  },

  uninstall() {
    window.removeEventListener("focus", this.update);
    window.removeEventListener("blur", this.update);
    document.removeEventListener("turbo:load", this.update);
    document.removeEventListener("visibilitychange", this.update);
  },

  documentIsActive() {
    return document.visibilityState === "visible" && document.hasFocus();
  },

  appearingOn() {
    const element = document.querySelector("[data-appearing-on]");
    return element ? element.getAttribute("data-appearing-on") : null;
  },
});
