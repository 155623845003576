// import Vue from "vue/dist/vue.esm";
import axios from "axios";
// import _ from "lodash";

const http = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

http.interceptors.request.use(
  config => {
    config.headers["X-CSRF-TOKEN"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    return config;
  },
  error => Promise.reject(error.response)
);

const store = {
  namespaced: true,
  state: {
    all: []
  },
  getters: {},
  mutations: {
    ALL(state, items) {
      state.all = items;
    }
  },
  actions: {
    loadAll({ commit }) {
      return new Promise(resolve => {
        http.get("/sponsors.json").then(res => {
          commit("ALL", res.data);
          resolve(res);
        });
      });
    }
  }
};

export default store;
