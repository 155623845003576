<template lang="html">
  <div class="ui minimal comments">
    <comment
      v-for="comment in comments"
      :key="comment.id"
      :initial-comment="comment"
      :comments-path="commentsPath"
      :user-id="userId"
      :can-edit="eventEditable"
    />

    <paginate
      v-if="totalResults > 0"
      class="margin-bottom-small "
      :total-results="totalResults"
      :per-page="perPage"
      @click="loadPage"
    />

    <comment-form
      :event="event"
      :comments-path="commentsPath"
      class="margin-top-medium"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import store from "store";
//import UploadsIndexView from "views/uploads/index.vue";
import CommentForm from "views/comments/form.vue";
import Comment from "views/comments/comment.vue";
import Paginate from "components/pagination.vue";

//const $ = { window };

export default {
  store,
  components: {
    //UploadsIndexView,
    CommentForm,
    Comment,
    Paginate,
  },
  props: ["event", "commentsPath", "eventEditable", "userId"],
  data() {
    return {
      currentPage: 1,
      perPage: 5,
      unsubscribeStore: null,
    };
  },
  computed: {
    ...mapState("comment", {
      comments: "all",
      totalResults: "count",
    }),
    // ...mapGetters("comment", {
    //   event: "event"
    // })
  },
  watch: {
    "event.id": function(val) {
      if (val) this.loadComments();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadComments();
    });

    this.unsubscribeStore = this.$store.subscribe((mutation) => {
      if (mutation.type === "event/CREATE") {
        this.loadComments();
      }

      if (mutation.type === "comment/CREATE") {
        this.loadComments();
      }

      if (mutation.type === "comment/DESTROY") {
        this.loadComments();
      }
    });
  },
  destroyed() {
    if (this.unsubscribeStore) {
      this.unsubscribeStore.call();
    }
  },
  methods: {
    ...mapActions("comment", {
      getAll: "getAll",
      setCount: "setCount",
    }),
    loadPage(page) {
      this.currentPage = page;
      this.loadComments();
    },
    loadComments() {
      this.getAll({
        event_id: this.event.id,
        per: this.perPage,
        page: this.currentPage,
      });
    },
  },
};
</script>

<style lang="css"></style>
