<template lang="html">
  <div
    class="ui dropdown"
    :class="{ multiple: multiple, scrolling: items.length > 10 }"
  >
    <slot name="menuIcon">
      <i class="icon dropdown" :class="{ iconRight: iconRight }" />
    </slot>

    <slot name="menuName">
      <span class="default text">{{ name }}</span>
    </slot>

    <div class="menu">
      <div v-if="items.length > 10" class="ui icon search input">
        <i class="search icon" />
        <input type="text" placeholder="Search ..." />
      </div>
      <div v-if="includeBlank" class="item" data-value="">
        <slot name="blankName">Any {{ name }}</slot>
      </div>
      <div
        v-for="item in items"
        :key="`${item.value}${item.label}`"
        :data-value="item.value"
        :data-selected="initialSelected && initialSelected.includes(item.value)"
        class="item"
      >
        <slot :item="item" name="label">
          {{ item.label }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { map } from "lodash";
const $ = window.jQuery;

export default {
  props: {
    items: Array,
    name: String,
    useLabels: true,
    multiple: {
      type: Boolean,
      default: false,
    },
    includeBlank: {
      type: Boolean,
      default: true,
    },
    initialSelected: Array,
    iconRight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      blankName: this.menuName,
    };
  },
  mounted() {
    this.initDropDown();
  },
  updated() {
    this.initDropDown();
  },
  destroyed() {
    this.initDropDown();
    this.$emit("change", "");
  },
  methods: {
    initDropDown() {
      const $this = this;
      $($this.$el).dropdown({
        useLabels: this.useLabels,
        forceSelection: false,
        onHide() {
          $this.$emit("hide");
        },
        onChange(value, text, selectedItem) {
          $this.$emit("change", value, text, selectedItem);
          $this.$emit("input", value, text, selectedItem);
        },
      });

      if (Array.isArray($this.initialSelected)) {
        $($this.$el).dropdown(
          "set selected",
          $this.initialSelected.map((i) => i.toString())
        );
      } else {
        $($this.$el).dropdown("set selected", $this.initialSelected);
      }

      $($this.$el).data("dropdown-loaded", true);
    },
    show() {
      $(this.$el).dropdown("show");
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  align-self: auto !important;

  .left.floated.label {
    margin-right: 0.5rem;
  }
}

.iconRight {
  float: right;
  margin-left: 1em !important;
}
</style>
