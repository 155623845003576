import { Controller } from "@hotwired/stimulus";

const $ = window.jQuery;

export default class extends Controller {
  static targets = [
    "answerOptions",
    "questionType",
    "validationOptions",
    "textLength",
    "numericRange",
    "placeholderOptions",
    "required",
  ];
  static values = {
    questionType: String,
  };

  initialize() {}

  connect() {
    $(this.element).find(".ui.checkbox").checkbox();

    this.questionTypeValue = this.questionTypeTarget.value;
  }

  changeType() {
    this.questionTypeValue = this.questionTypeTarget.value;
  }

  questionTypeValueChanged() {
    // switch (this.questionTypeValue) {
    //   case "Questions::Checkbox":
    //   case "Questions::Radio":
    //   case "Questions::Select":
    //   case "Questions::Date":
    //     this.answerOptionsTarget.classList.remove("hidden");
    //     this.validationOptionsTarget.classList.add("hidden");
    //     this.placeholderOptionsTarget.classList.add("hidden");
    //     this.answerOptionsTarget.classList.add("hidden");
    //     break;
    //   case "Questions::Short":
    //   case "Questions::Long":
    //     this.validationOptionsTarget.classList.remove("hidden");
    //     this.numericRangeTarget.classList.add("hidden");
    //     this.textLengthTarget.classList.remove("hidden");
    //     this.answerOptionsTarget.classList.add("hidden");
    //     this.placeholderOptionsTarget.classList.remove("hidden");
    //     break;
    //   case "Questions::Numeric":
    //     this.validationOptionsTarget.classList.remove("hidden");
    //     this.numericRangeTarget.classList.remove("hidden");
    //     this.textLengthTarget.classList.add("hidden");
    //     this.answerOptionsTarget.classList.add("hidden");
    //     this.placeholderOptionsTarget.classList.add("hidden");
    //     break;
    //   case "Questions::Information":
    //     this.answerOptionsTarget.classList.add("hidden");
    //     this.placeholderOptionsTarget.classList.add("hidden");
    //     this.validationOptionsTarget.classList.add("hidden");
    //     this.requiredTarget.classList.add("hidden");
    //     break;
    //   default:
    //     this.requiredTarget.classList.remove("hidden");
    //     this.answerOptionsTarget.classList.add("hidden");
    //     this.placeholderOptionsTarget.classList.remove("hidden");
    //     this.validationOptionsTarget.classList.add("hidden");
    // }
  }
}
