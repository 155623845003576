// import "idempotent-babel-polyfill";
// import Vue from "vue/dist/vue.esm";

// if (document.querySelectorAll(".sponsor.card").length > 0) {
// 	document.querySelectorAll(".sponsor.card").forEach(el => {
// 		const view = {
// 			el,
// 			props: {
// 				initialSponsor: Object
// 			},
// 			data() {
// 				const $this = this;
// 				return {
// 					sponsor: $this.initialSponsor || {}
// 				};
// 			},
// 			methods: {},
// 			mounted() {
// 				const $this = this;
// 				this.$nextTick(() => {
// 					$this.sponsor = JSON.parse($this.$el.dataset.sponsor);
// 				});
// 			}
// 		};
// 		new Vue(view);
// 	});
// }
