<template lang="html">
  <div class="comment">
    <avatar
      class="ui image avatar"
      :username="comment.user_login"
      :src="comment.user_gravatar"
      size="40"
    />

    <div class="content padding-top-tiny">
      <span class="author">
        {{ comment.user_login }}
      </span>
      <div class="metadata">
        <div class="date">
          <timeago :since="comment.created_at" />
        </div>
        <div v-if="comment.created_at !== comment.updated_at" class="date">
          (edited <timeago :since="comment.updated_at" />)
        </div>
      </div>

      <comment-form
        v-if="isEditing"
        :initial-comment="comment"
        :comments-path="commentsPath"
        :initial-uploads-url="uploadsUrl()"
        @updated="updateComment"
      />

      <div v-if="!isEditing" class="text" v-html="autolinkedComment" />

      <!-- <div class="v-uploads clearboth" > -->
      <uploads-index-view
        v-show="!isEditing"
        ref="uploads"
        :url="uploadsUrl()"
        class="clearboth"
      />
      <!-- </div> -->

      <div v-if="canDestroy()" class="actions">
        <a class="link ui" @click="destroy()">
          <i class="ui icon trash small fitted" />
          Delete
        </a>
        <a v-if="!isEditing" class="link ui" @click="edit()">
          <i class="ui icon edit small fitted" />
          Edit
        </a>
        <a v-if="isEditing" class="link ui" @click="stopEditing()">
          <i class="ui icon times small fitted" />
          Cancel
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Avatar from "components/avatar.vue";
import VueTimeago from "vue-timeago";
import UploadsIndexView from "views/uploads/index.vue";
import CommentForm from "views/comments/form.vue";
import EventBus from "components/eventbus";
import linkifyHtml from "linkify-html";
import enLocale from "vue-timeago/locales/en-US.json";

Vue.use(VueTimeago, {
  name: "timeago", // component name, `timeago` by default
  locale: "en-US",
  locales: { "en-US": enLocale },
});

export default {
  components: {
    UploadsIndexView,
    Avatar,
    // VueTimeago,
    CommentForm,
  },
  props: ["initialComment", "commentsPath", "userId", "canEdit"],
  data() {
    return {
      comment: this.initialComment,
      isEditing: false,
    };
  },
  computed: {
    autolinkedComment() {
      return linkifyHtml(unescape(this.comment.comment), {
        target: "_blank",
      });
    },
  },
  mounted() {
    this.$el.getElementsByTagName("a").forEach((element) => {
      element.setAttribute("target", "_blank");
    });
  },
  methods: {
    stopEditing() {
      this.isEditing = false;
      EventBus.$emit("eventModalClean", "comments");
    },
    edit() {
      this.isEditing = true;
    },
    updateComment(updatedComment) {
      this.comment.comment = updatedComment.comment;
      this.$refs.uploads.reloadData();
      this.stopEditing();
    },
    uploadsUrl() {
      const url = `/uploads?assetable_type=Comment&assetable_id=${this.comment.id}`;
      return url;
    },
    canDestroy() {
      return this.canEdit || this.comment.user_id === this.userId;
    },
    destroy() {
      this.$store.dispatch("comment/destroy", this.comment).then(() => {
        EventBus.$emit("commentDestroyed", this.comment);
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
