import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import EventModal from "views/events/eventModal.vue";

document.addEventListener("turbo:load", function () {
  if (document.getElementById("event-modal")) {
    window.eventModal = new Vue({
      el: "#event-modal",
      components: {
        EventModal,
      },
    });
  }
});
