import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["rateForm"];

  initialize() {}

  connect() {}

  saveRate(e) {
    window.$(this.rateFormTarget).addClass("loading");
    this.rateFormTarget.requestSubmit();
    e.preventDefault();
  }
}
