import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import EventBus from "components/eventbus";
import shortkey from "vue-shortkey";
Vue.use(shortkey, { prevent: ["input", "textarea", "div[contenteditable]"] });

const $ = window.jQuery;

document.addEventListener("turbo:load", function () {
  if (document.getElementById("shortcuts")) {
    new Vue({
      el: "#shortcuts",
      data() {
        return {
          powerbarSearchQuery: "",
        };
      },
      mounted() {
        const $this = this;
        EventBus.$on("showHelp", this.toggleHelp);
        EventBus.$on("showPowerbar", this.togglePowerbar);

        $("#helpButton").click((e) => {
          $this.toggleHelp();
          e.preventDefault();
          return false;
        });

        document.addEventListener("keydown", (e) => {
          if (e.which == 18) {
            this.addBodyClass("alt");
          }
          if (e.which == 91) {
            this.addBodyClass("meta");
          }
          if (e.which == 16) {
            this.addBodyClass("shift");
          }
        });

        document.addEventListener("keyup", (e) => {
          if (e.which == 18) {
            this.removeBodyClass("alt");
          }
          if (e.which == 91) {
            this.removeBodyClass("meta");
          }
          if (e.which == 16) {
            this.removeBodyClass("shift");
          }
          if (e.which == 27) {
            this.closePowerbar();
            this.closeHelp();
          }
        });

        $this.$nextTick(() => {
          $this.initPowerBar();

          $this.initHelp();
        });
      },
      methods: {
        hideOtherDimmers() {
          $(".ui.dimmer.modals").dimmer("hide");
        },
        initHelp() {
          const $this = this;
          $($this.$refs.help).dimmer({
            closable: true,
            onShow() {
              $this.hideOtherDimmers();
              $this.closePowerbar();
            },
          });
        },
        initPowerBar() {
          const $this = this;
          $($this.$refs.powerbar).dimmer({
            closable: true,
            duration: 10,
            onShow() {
              $this.hideOtherDimmers();
              $this.closeHelp();
            },
          });

          $(this.$el)
            .find(".ui.search")
            .search({
              type: "category",
              // debug: true,
              // verbose: true,
              cache: false,
              maxResults: false,
              minCharacters: 0,
              duration: 50,
              searchDelay: 25,
              // searchOnFocus: true,
              selectFirstResult: true,
              apiSettings: {
                url: "/search.json?q={query}",
              },
              fields: {
                results: "results",
                title: "name",
                description: "_",
                url: "url",
              },
            });
        },
        focusPowerBar() {
          this.$refs.powerbarInput.focus();
        },
        togglePowerbar() {
          this.powerbarSearchQuery = "";
          $(this.$refs.powerbar).dimmer("toggle");
          this.$nextTick(() => {
            this.focusPowerBar();
          });
        },
        closePowerbar() {
          $(this.$refs.powerbar).dimmer("hide");
        },
        toggleHelp() {
          $(this.$refs.help).dimmer("toggle");
        },
        closeHelp() {
          $(this.$refs.help).dimmer("hide");
        },
        noOp() {
          return false;
        },
        addBodyClass(string) {
          document.querySelector("body").classList.add(string);
        },
        removeBodyClass(string) {
          document.querySelector("body").classList.remove(string);
        },
      },
    });
  }
});
