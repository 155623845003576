<template lang="html">
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        rootMargin: '200px',
      },
    }"
  >
    <template v-if="isVisible">
      <vue-dropzone
        v-if="showUploader"
        id="dropzone"
        ref="dropzone"
        class="dropzone ui placeholder segment margin-bottom-zero"
        :options="dropzoneOptions"
        @vdropzone-success="uploadComplete" />
      <div v-show="uploads.length > 0" class="ui segment basic">
        <div>
          <draggable
            v-model="uploads"
            @end="saveOrder"
            :disabled="!sortEnabled"
            class="ui cards"
            :class="cardCount"
          >
            <a
              v-for="upload in uploads"
              class="ui card"
              :key="upload.id"
              :href="upload.asset.url_content"
              target="_blank"
              @click="click(upload, $event)"
            >
              <div class="content">
                <img
                  v-if="contentType(upload) === 'image'"
                  class="ui image responsive"
                  :src="upload.asset.url_content_thumb"
                />
                <div v-if="contentType(upload) != 'image'" class="centered">
                  <i
                    class="icon file outline huge"
                    :class="contentType(upload)"
                  />
                  <div class="left aligned margin-top-small wrapped">
                    <span class="ui black small text">
                      {{ upload.asset.data_file_name }}
                    </span>
                  </div>
                </div>
              </div>
              <div v-if="showUploader" class="extra content">
                <i
                  class="ui icon trash link"
                  @click.stop.prevent="deleteUpload(upload)"
                />
              </div>
            </a>
          </draggable>
        </div>
      </div>
      <div
        v-if="pagination.prev || pagination.next || showSearch"
        class="ui pagination menu mini margin-top-small pointer"
      >
        <div
          v-if="pagination.prev"
          class="item"
          @click.stop.prevent="goTo(pagination.prev)"
        >
          <i class="icon left arrow" />Previous
        </div>
        <div
          v-if="pagination.next"
          class="item"
          @click.stop.prevent="goTo(pagination.next)"
        >
          Next<i class="icon right arrow" />
        </div>
        <div v-if="showSearch" class="right aligned item">
          <div class="ui transparent icon input">
            <input
              v-model.lazy="searchQuery"
              v-debounce="300"
              class="prompt"
              type="text"
              placeholder="Search ..."
            /><i class="search link icon" />
          </div>
        </div></div
    ></template>
  </div>
</template>

<script>
import Vue from "vue";

import vue2Dropzone from "vue2-dropzone";
import debounce from "v-debounce";
import draggable from "vuedraggable";
import ResizeObserver from "resize-observer-polyfill";
import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);

require("es5-shim");
require("es6-shim");

const $ = window.jQuery;

export default {
  components: {
    vueDropzone: vue2Dropzone,
    draggable,
  },
  directives: { debounce },
  props: {
    ckeditor: {
      type: Boolean,
      default: false,
    },
    lazyLoad: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: "/uploads",
    },
    formParameterName: {
      type: String,
      default: "upload_ids[]",
    },
    allowSearching: {
      type: Boolean,
      default: false,
    },
    allowUploads: {
      type: Boolean,
      default: false,
    },
    maxFilesize: {
      type: Number,
      default: 50,
    },
    maxFiles: {
      type: Number,
      default: 25,
    },
    showUploadsList: {
      type: Boolean,
      default: true,
    },
    form: {
      type: String,
      default: null,
    },
    sortEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const $this = this;
    return {
      elementWidth: null,
      searchQuery: null,
      currentPage: 1,
      totalCount: null,
      dataUrl: $this.url,
      pagination: {},
      showUploader: $this.allowUploads,
      showSearch: $this.allowSearching,
      uploads: [],
      isVisible: $this.lazyLoad ? false : true,
      dropzoneOptions: {
        maxFilesize: $this.maxFilesize,
        maxFiles: $this.maxFiles,
        url: `${$this.url}`,
        paramName: "data",
        dictDefaultMessage:
          "<p><i class='ui icon cloud upload alternate'></i><br >Drag files here or click to upload</div>",
        headers: {
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      },
    };
  },
  computed: {
    previousPage() {
      return this.pagination.prev;
    },
    nextPage() {
      return this.pagination.next;
    },
    queryParams() {
      return {
        q: this.searchQuery,
        page: this.currentPage,
      };
    },
    cardCount() {
      if (this.elementWidth / 6 < 100) {
        return "three";
      } else {
        return "six";
      }
    },
  },
  watch: {
    dataUrl() {
      this.reloadData();
    },
    searchQuery() {
      this.currentPage = 1;
      this.reloadData();
    },
    isVisible(newVal, oldVal) {
      // console.log(newVal);
    },
  },
  mounted() {
    const $this = this;
    $this.$nextTick(() => {
      $this.reloadData();

      const ro = new ResizeObserver(function () {
        $this.elementWidth = $($this.$el).width();
      });

      ro.observe($this.$el);
    });
  },

  methods: {
    saveOrder() {
      const $this = this;
      window.$.ajax({
        url: $this.dataUrl,
        type: "PATCH",
        dataType: "json",
        data: {
          order: $this.uploads.map((upload) => upload.asset.id),
        },
      }).then(() => {
        $this.$emit("updated", this.uploads);
      });
    },
    visibilityChanged(value) {
      this.isVisible = this.isVisible || value;
    },
    goTo(href) {
      if (!href) return;
      const queryDict = {};
      href
        .split("?")[1]
        .split("&")
        .forEach((item) => {
          const [key, value] = item.split("=");
          queryDict[key] = value;
        });
      this.currentPage = queryDict.page;
      this.reloadData();
    },
    click(upload, event) {
      if (!this.ckeditor) {
        return true;
      }
      this.ckeditorInsert(upload);
      event.stopPropagation();
      event.preventDefault();
      return false;
    },
    ckeditorInsert(upload) {
      const queryDict = {};
      window.location.search
        .substr(1)
        .split("&")
        .forEach((item) => {
          const [key, value] = item.split("=");
          queryDict[key] = value;
        });
      const CKEditorFuncNum = queryDict.CKEditorFuncNum;
      const CKEditorName = queryDict.CKEditor;
      const CKEDITOR = window.opener.CKEDITOR;
      const EDITOR = CKEDITOR.instances[CKEditorName];
      CKEDITOR.tools.callFunction(CKEditorFuncNum, upload.asset.url_content);
      window.close();
      return false;
    },
    deleteUpload(upload) {
      const [base, queryString] = this.url.split("?");
      this.uploads.splice(this.uploads.indexOf(upload), 1);
      $.destroy(`${base}/${upload.asset.id}?${queryString}`);
      this.$emit("delete", upload);
    },
    contentType(upload) {
      const contentType = upload.asset.data_file_name.split(".").slice(-1)[0];
      switch (contentType.toLowerCase()) {
        case "jpeg":
        case "png":
        case "jpg":
        case "gif":
          return "image";
        case "docx":
        case "doc":
          return "word";
        case "ppt":
          return "powerpoint";
        case "xlsx":
        case "xls":
          return "excel";
        case "txt":
          return "text";
        case "wav":
        case "mp3":
          return "audio";
        case "zip":
          return "archive";
        default:
          return contentType;
      }
    },
    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles();
      this.uploads = [];
    },
    uploadComplete(file, response) {
      this.$refs.dropzone.removeFile(file);
      this.uploads.unshift(response);
      this.$emit("complete", response.asset);
      if (this.form) {
        $(this.form).append(
          `<input type='hidden' class="upload_ids" name='${this.formParameterName}' value='${response.asset.id}'>`
        );
      }
    },
    reloadData() {
      if (!this.showUploadsList) {
        return;
      }
      const $this = this;
      $.read(this.dataUrl, this.queryParams).done((data, headers, xhr) => {
        $this.pagination = {};
        // $this.currentPage = parseInt(xhr.getResponseHeader("x-page"), 10);
        $this.totalCount = parseInt(xhr.getResponseHeader("total"), 10);
        const linkHeader = xhr.getResponseHeader("link");
        if (linkHeader)
          linkHeader.split(",").forEach((header) => {
            const [link, rel] = header.split(";");
            const type = rel.replace(/rel="(.+)"/, "$1").trim();
            const href = link.replace(/<(.*?)>/, "$1").trim();
            $this.pagination[type] = href;
          });
        $this.replaceUploads(data);
      });
    },
    replaceUploads(uploads) {
      this.uploads = uploads;
    },
  },
};
</script>

<style scoped>
.wrapped {
  word-break: break-all;
  line-height: 0.9em;
  max-height: 3em;
  overflow-y: hidden;
}
</style>

<style lang="scss">
@import "dropzone/dist/dropzone.css";

.comment form {
  margin-bottom: 2em;
}

.dropzone.segment {
  // margin-top: 1em !important;
  width: 100% !important;
  box-shadow: none !important;
  min-height: 100px;
  border: 2px dashed #d8d8d8;
  background-color: #fafafa;
  min-height: auto !important;
  .dz-preview.dz-image-preview {
    background: none;
  }
  &:hover {
    // border-style: dotted;
  }
}
</style>
