<template lang="html">
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        rootMargin: '200px',
      },
    }"
  >
    <div v-if="isVisible">
      <div v-if="!isIgStory">
        <div
          class="ui top attached header"
          :style="{
            backgroundColor:
              colors[inferredTypeAsIconClass(deliverable.inferred_type)] ||
              '#cccccc',
          }"
        >
          <i
            class="icon"
            :class="inferredTypeAsIconClass(deliverable.inferred_type)"
          />

          <!-- <template v-if="deliverable.url">
          {{ inferredTypeAsLabel(deliverable.inferred_type) | toCamelCase }}
        </template> -->
          <!-- <div v-if="!deliverable.url" class="content"> -->
          <div class="content">
            <dropdown
              class="inline left floated"
              :value="deliverable.inferred_type"
              :include-blank="false"
              :items="deliverableTypes"
              :icon-right="true"
              @change="setInferredType"
            >
              <span slot="menuName" class="text">
                {{
                  inferredTypeAsLabel(deliverable.inferred_type) ||
                  "Choose" | humanize
                }}
              </span>
            </dropdown>
          </div>
        </div>
        <a
          v-if="persisted && deletable"
          class="ui top right floating circular floating icon label white outlined tiny"
          @click.stop.prevent="remove"
        >
          <i class="icon delete fitted" />
        </a>

        <form class="ui form clearing" @submit.stop.prevent="save">
          <div v-if="errors" class="ui error message visible" v-html="errors" />

          <div class="ui fields equal width">
            <div class="field">
              <input
                v-model="deliverable.name"
                name="name"
                class="fluid input"
                placeholder="Enter Title"
                type="text"
              />
            </div>

            <div class="field">
              <div
                class="ui icon input"
                :class="{
                  disabled: isIgStory,
                }"
              >
                <input
                  v-model="deliverable.url"
                  type="text"
                  name="url"
                  class="fluid input"
                  placeholder="Enter URL"
                  @paste="handlePaste"
                />
              </div>
            </div>

            <div class="ui buttons compact">
              <a v-if="!persisted" class="ui button primary" @click="save">
                Add
              </a>
            </div>
          </div>

          <div v-if="!isIgStory">
            <a href="#" @click.stop.prevent="toggleMore">
              <span v-if="!showMore">
                <i class="icon right caret fitted" />
                Preview & edit
              </span>
              <span v-if="showMore">
                <i class="icon down caret fitted" />
                Show less
              </span>
            </a>
          </div>

          <div v-show="showMore" class="ui padded grid stackable">
            <div class="seven wide column">
              <div class="field">
                <label for="">Preview</label>
                <deliverable-card
                  v-if="showMore"
                  ref="previewCard"
                  :key="deliverable.id"
                  :initial-deliverable="deliverable"
                  class="fluid margin-top-zero"
                  @cardLoaded="cardLoaded"
                />
              </div>
            </div>

            <div class="nine wide column">
              <div class="field">
                <label>{{ isIgReel ? "Reel" : "Custom" }} Statistics </label>

                <statistic-field
                  v-for="(
                    statistic, index
                  ) in deliverable.manual_update_statistics"
                  :key="statistic.id || index"
                  v-model="deliverable.manual_update_statistics[index]"
                  :metric-types="metricTypes"
                  @remove="removeStatistic"
                  @change="refreshPreviewCard"
                />

                <a href="#" class="ui" @click.stop.prevent="addStatistic">
                  <i class="icon add fitted" />
                  Add Statistic
                </a>
              </div>

              <div class="ui divider margin-y-medium" />

              <div class="field">
                <div class="ui checkbox">
                  <input
                    v-model="deliverable.hide_embed"
                    type="checkbox"
                    name="hide_embed"
                  />
                  <label for="hide_embed">Hide embed</label>
                </div>
              </div>

              <div v-if="showMore" class="field">
                <label for=""> Add images in a carousel </label>
                <uploads-index-view
                  :key="'uploader-deliverable-' + deliverable.id"
                  :url="uploadsUrl"
                  :allow-uploads="true"
                  :sort-enabled="true"
                  @updated="updateUploads"
                  @complete="addUpload"
                  @delete="removeUpload"
                />
              </div>

              <div class="ui divider margin-y-medium" />

              <div v-show="showMore" class="field">
                <label for="">Description</label>
                <text-editor
                  :key="`cke-${deliverable.id}`"
                  v-model="deliverable.description"
                  :autofocus="false"
                />
              </div>
            </div>
          </div>

          <button class="ui transition hidden" type="submit" />
        </form>
      </div>

      <div v-if="isIgStory" class="ui form clearing">
        <statistic-field
          v-for="(statistic, index) in deliverable.manual_update_statistics"
          :key="statistic.id || index"
          v-model="deliverable.manual_update_statistics[index]"
          :metric-types="metricTypes"
          :allow-additions="false"
          @remove="removeStatistic"
          @change="refreshPreviewCard"
        />

        <a href="#" class="ui" @click.stop.prevent="addStatistic">
          <i class="icon add fitted" />
          Add statistic manually
        </a>

        <div class="field margin-top-small">
          <label for=""> Add statistics from screenshots </label>
          <uploads-index-view
            :key="'uploader-deliverable-' + deliverable.id"
            :url="`${uploadsUrl}&has_statistics=true`"
            :allow-uploads="true"
            @complete="addUploadAndSave"
            @delete="removeUploadAndSave"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueObserveVisibility from "vue-observe-visibility";
import Vue from "vue/dist/vue.esm";
import { mapState, mapGetters } from "vuex";
import store from "store";
import textEditor from "components/textEditor.vue";
import EventBus from "components/eventbus";
import Toasted from "vue-toasted";
import _ from "lodash";

import Dropdown from "components/dropdown.vue";
import StatisticField from "views/deliverables/components/statisticField.vue";
import deliverableCard from "views/deliverables/components/deliverableCard.vue";
import UploadsIndexView from "views/uploads/index.vue";

Vue.use(VueObserveVisibility);

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
  singleton: true,
});

const $ = window.jQuery;

export default {
  store,
  components: {
    Dropdown,
    textEditor,
    StatisticField,
    UploadsIndexView,
    deliverableCard,
  },
  props: {
    endpoint: String,
    initialDeliverable: Object,
    metricTypes: Array,
    clearAfterSave: Boolean,
    deletable: {
      default: true,
      type: Boolean,
    },
    deliverableTypes: {
      default() {
        return [
          { value: "activecampaign", label: "ActiveCampaign" },
          { value: "aweber", label: "AWeber" },
          { value: "bitly", label: "Bitly" },
          { value: "convertkit", label: "ConvertKit" },
          { value: "drip", label: "Drip" },
          { value: "facebook", label: "Facebook" },
          { value: "instagram", label: "Instagram Stories" },
          { value: "instagram", label: "Instagram" },
          { value: "mailchimp", label: "MailChimp" },
          { value: "other", label: "Other" },
          { value: "pinterest", label: "Pin" },
          { value: "sendfox", label: "SendFox" },
          { value: "tik_tok", label: "TikTok" },
          { value: "twitter", label: "Tweet" },
          { value: "url", label: "Article" },
          { value: "youtube", label: "YouTube" },
          { value: "email", label: "Email" },
        ];
      },
      type: Array,
    },
  },
  data() {
    const $this = this;
    return {
      isVisible: false,
      deliverable: $this.initialDeliverable,
      instagramReelsData: null,
      errors: null,
      loading: false,
      currentRequest: null,
      showMore:
        ($this.initialDeliverable && $this.isIgStory) ||
        ($this.initialDeliverable.manual_update_statistics &&
          $this.initialDeliverable.manual_update_statistics.length > 0 &&
          $this.initialDeliverable.manual_update_statistics[0].value),
    };
  },
  computed: {
    ...mapState("deliverable", ["colors"]),
    ...mapGetters("deliverable", [
      "inferredTypeAsIconClass",
      "inferredTypeAsLabel",
    ]),
    isIgReel() {
      return (
        this.initialDeliverable.url &&
        this.initialDeliverable.url.includes("/reel/")
      );
    },
    isIgStory() {
      return this.deliverable.inferred_type === "instagram_stories";
    },
    persisted() {
      return this.deliverable.id;
    },
    uploadsUrl() {
      return `/uploads?assetable_type=Deliverable&assetable_id=${this.deliverable.id}`;
    },
    url() {
      let url = `${this.endpoint}`;
      if (this.persisted) {
        url += `/${this.deliverable.id}`;
      }
      return url;
    },
    requestType() {
      let type = `post`;
      if (this.persisted) {
        type = `patch`;
      }
      return type;
    },
    asParams() {
      const params = {
        deliverable: this.deliverable,
      };

      this.$set(
        params.deliverable,
        "statistics_attributes",
        this.deliverable.manual_update_statistics
      );
      return params;
    },
  },
  watch: {
    "deliverable.hide_embed": function (newVal, old) {
      if (newVal !== old) {
        this.update();
      }
    },
    "deliverable.name": function (newVal, old) {
      if (newVal !== old) {
        this.update();
      }
    },
    "deliverable.url": function (newVal, old) {
      if (newVal !== old) {
        this.update();
      }
      if (newVal) {
        this.deliverable.inferred_type = null;
      }
    },
    "deliverable.description": function (newVal, old) {
      if ((old && newVal !== old) || (!old && newVal != "")) {
        this.update();
      }
    },
    "deliverable.inferred_type": function (newVal, old) {
      if (newVal && newVal !== old) {
        this.update();
      }
    },
  },
  mounted() {
    EventBus.$on("saveAllDeliverables", this.save);
    this.loadEmbed();

    if (
      this.deliverable.manual_update_statistics.length < 1 &&
      !this.isIgStory
    ) {
      this.initManualStats();
    }

    if (typeof deliverablesChannel !== "undefined") {
      const $this = this;
      window.deliverablesChannel(
        $this.deliverable.id,
        "deliverable-manual-stats-updated",
        function (data) {
          $this.$set(
            $this.deliverable,
            "manual_update_statistics",
            data.statistics
          );
        }
      );
    }
    // if (typeof hb !== "undefined") {
    //   const channel = hb.pusher.subscribe(`deliverable-${this.deliverable.id}`);

    //   channel.bind("deliverable-manual-stats-updated", (data) => {
    //     this.$set(this.deliverable, "manual_update_statistics", data);
    //   });
    // }
  },
  updated() {
    this.loadEmbed();
  },
  destroyed() {
    EventBus.$off("saveAllDeliverables", this.save);
  },
  methods: {
    visibilityChanged(value) {
      this.isVisible = this.isVisible || value;
    },
    handlePaste(e) {
      let text = e.clipboardData.getData("Text");
      if (text.includes("background-image")) {
        this.instagramReelsData = text;

        var reelUrl = `https://www.instagram.com${
          text.match(/.*href="(.*?)".*/)[1]
        }`;
        this.deliverable.url = reelUrl;

        $(e.target).popup({
          on: "manual",
          content: "👍 Reels stats added!",
          position: "top center",
          variation: "inverted",
        });
        $(e.target).popup("show");

        this.update();
        e.preventDefault();
        return false;
      }
    },
    initManualStats() {
      this.$set(this.deliverable, "manual_update_statistics", [
        { deliverable_id: this.deliverable.id },
      ]);
    },
    mergeManualStats(stats) {
      stats.forEach((stat) => {
        const existingStat = _.find(this.deliverable.manual_update_statistics, [
          "id",
          stat.id,
        ]);
        if (existingStat) {
          Object.assign(existingStat, stat);
        } else {
          this.deliverable.manual_update_statistics.concat(stat);
        }
      });
    },
    cardLoaded(deliverable) {
      if (typeof window.iframely !== "undefined") {
        window.iframely.load();
      }
      this.$set(this.deliverable, "inferred_type", deliverable.inferred_type);
      if (deliverable.manual_update_statistics.length > 0) {
        this.mergeManualStats(deliverable.manual_update_statistics);
      } else {
        this.initManualStats();
      }
    },
    setInferredType(type) {
      this.$set(this.deliverable, "inferred_type", type);
    },
    updateUploads(uploads) {
      this.$set(
        this.deliverable,
        "uploads",
        uploads.map((u) => u.asset)
      );
    },
    addUploadAndSave(asset) {
      this.addUpload(asset);
      this.save();
    },
    addUpload(asset) {
      this.deliverable.uploads.push(asset);
    },
    removeUploadAndSave(upload) {
      this.removeUpload(upload);
      // this.save();
    },
    removeUpload(upload) {
      const i = _.findIndex(this.deliverable.uploads, { id: upload.asset.id });
      this.deliverable.uploads.splice(i, 1);
    },

    loadEmbed() {
      this.$nextTick(() => {
        $(this.$el).find(".ui.embed").embed();
      });
    },
    refreshPreviewCard() {
      const $this = this;
      if ($this.$refs.previewCard) {
        $this.$refs.previewCard.loadData();
      }
    },
    removeStatistic(statistic) {
      this.refreshPreviewCard();

      if (statistic.id) {
        // eslint-disable-next-line no-alert
        this.deliverable.manual_update_statistics = this.deliverable.manual_update_statistics.filter(
          (x) => x.id !== statistic.id
        );
      } else {
        this.deliverable.manual_update_statistics = this.deliverable.manual_update_statistics.filter(
          (x) => x !== statistic
        );
      }
    },
    addStatistic() {
      this.$set(
        this.deliverable,
        "manual_update_statistics",
        this.deliverable.manual_update_statistics || []
      );
      this.deliverable.manual_update_statistics.push({
        deliverable_id: this.deliverable.id,
      });
    },
    toggleMore() {
      this.showMore = !this.showMore;
    },
    remove() {
      this.deleteDeliverable();
    },
    deleteDeliverable() {
      const $this = this;
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure you want to delete this deliverable?")) {
        $this.$emit("delete", $this.deliverable);
        if ($this.persisted) {
          $.destroy(`/deliverables/${$this.deliverable.id}`);
        }
      }
    },
    save() {
      if (!this.deliverable.name && !this.deliverable.url) {
        return false;
      }

      this.errors = null;
      this.loading = true;
      const $this = this;

      let params = { ...$this.asParams };
      if (this.instagramReelsData) {
        params.instagram_reels_data = this.instagramReelsData;
        this.instagramReelsData = null;
      }

      this.currentRequest = window.$.ajax({
        url: $this.url,
        type: $this.requestType,
        dataType: "json",
        data: params,
        beforeSend: function () {
          if ($this.currentRequest != null) {
            $this.currentRequest.abort();
          }
        },
        success: $this.saveSuccess,
        error: $this.saveError,
        complete: $this.saveComplete,
      });
    },
    saveComplete() {
      if (this.clearAfterSave) {
        this.deliverable = {};
      }
      // this.hideSearchModal();
      this.loading = false;
    },
    saveSuccess(data) {
      this.deliverable.id = data.id;

      if (data.manual_update_statistics.length > 0) {
        // this.$set(
        //   this.deliverable,
        //   "manual_update_statistics",
        //   data.manual_update_statistics
        // );
      } else {
        this.initManualStats();
      }
      this.$set(this.deliverable, "statisticsValues", data.statisticsValues);
      this.$set(this.deliverable, "inferred_type", data.inferred_type);
      this.$emit("save", data);
      Vue.toasted.success("Saved");
    },
    saveError(request) {
      this.loading = false;
      if (request.statusText == "abort") {
        //nothing
      } else if (request.responseJSON) {
        this.errors = `Error: ${request.responseJSON.errors}`;
      } else {
        this.errors = "There was an error saving your data.";
      }
    },
    // eslint-disable-next-line prefer-arrow-callback
    update: _.debounce(function () {
      if (this.persisted) {
        this.save();
      }
    }, 750),
  },
};
</script>

<style lang="scss" scoped>
/* #deliverablesSearchModal {
  position: fixed;
} */

.ui.top.attached.header {
  text-transform: capitalize;
  color: #fff;
  margin: -1em;
  margin-bottom: 1em;
  border-width: 0;
}

div.ui[class*="9:16"].embed {
  padding-bottom: 177%;
}

.top.right.attached.label {
  z-index: 3;
}
</style>
