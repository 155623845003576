<template>
	<div class="menu">
		<a
			class="item"
			:class="{ active: isSelected('active') }"
			@click="change('active')"
			>Active</a
		>
		<a
			class="item"
			:class="{ active: isSelected('completed') }"
			@click="change('completed')"
			>Completed</a
		>
		<a
			class="item"
			:class="{ active: isSelected('all') }"
			@click="change('all')"
			>All</a
		>
	</div>
</template>

<script>
export default {
	props: ["selected"],
	methods: {
		change(val) {
			this.$emit("change", val);
		},
		isSelected(filter) {
			return filter === this.selected;
		}
	}
};
</script>
<style></style>
