<template>
  <div class="ui secondary margin-left-zero menu stackable">
    <div ref="filtersMenu" class="ui basic popup">
      <filters :field-definitions="fieldDefinitions" :admin="admin" />
    </div>

    <div
      v-if="showCalendarNameHeader && selectedCalendarObjects.length > 0"
      class="ui header large margin-bottom-zero margin-top-tiny"
    >
      {{ selectedCalendarObjects[0].name }}
    </div>
    <div v-else class="ui header large margin-bottom-zero margin-top-tiny">
      Dashboard
    </div>

    <div class="right menu">
      <div class="ui right aligned white item pointer">
        <div
          class="ui left icon input transparent"
          :class="{ loading: isLoading, active: searchQuery }"
        >
          <i
            class="icon"
            :class="{
              'loading notched circle': isLoading,
              search: !isLoading,
              fitted: !searchQuery,
            }"
          />
          <input
            v-model.lazy="searchQuery"
            v-debounce="300"
            :placeholder="searchPlaceholder"
            class="prompt"
            type="text"
            size="30"
          />
        </div>
      </div>

      <div class="item fitted">
        <div ref="advancedSearchButton" class="ui white button">
          <i class="icon filter" :class="{ pink: filtersActive }" />
          <span class="ui text" :class="{ pink: filtersActive }">Filters</span>
        </div>
      </div>

      <div v-if="currentView == '/board'" class="item fitted">
        <div class="ui white button dropdown">
          <input v-model.lazy="selectedSort" type="hidden" name="sort" />
          <!-- <div class="text">Sort: Date</div> -->
          <i class="icon sort amount down" />
          {{ availableSorts[selectedSort].label }}
          <div class="menu">
            <div
              v-for="(obj, sort) of availableSorts"
              class="item"
              :data-value="sort"
            >
              {{ obj.label }}
            </div>
          </div>
        </div>
      </div>

      <!--       <dropdown
        v-if="calendars.length > 1 && showCalendarDropdown"
        class="white item column"
        name="Calendar"
        :use-labels="false"
        :initial-selected="selectedCalendars"
        :items="calendarsAsOptions"
        :multiple="true"
        :include-blank="false"
        @change="calendarsChanged"
      >
        <i slot="menuIcon" class="icon calendar" />
        <span slot="menuName" class="text default">Select Calendar</span
        ><span slot="label" slot-scope="props"
          ><div
            class="ui left floated red empty circular label"
            :style="{ 'background-color': props.item.color + ' !important' }"
          />
          {{ props.item.label }}</span
        >
      </dropdown>
      <dropdown
        v-if="categories.length > 1"
        v-model="selectedCategory"
        class="white item column"
        :items="categoriesAsOptions"
        name="Category"
        @change="categoryChanged"
      >
        <i slot="menuIcon" class="icon layer group" /><span
          slot="menuName"
          class="text"
          >All Categories</span
        ><span slot="label" slot-scope="props"
          ><div
            class="ui left floated red empty circular label"
            :style="{ 'background-color': props.item.color + ' !important' }"
          />
          {{ props.item.label }}</span
        >
      </dropdown>
      <dropdown
        v-if="users.length > 1 && admin"
        v-model="selectedUser"
        class="white item column"
        :items="usersAsOptions"
        name="User"
        @change="userChanged"
      >
        <i slot="menuIcon" class="icon user" /><span
          slot="menuName"
          class="text"
          >Everyone</span
        >
      </dropdown> -->

      <div if="editable" class="item fitted" data-turbo="false">
        <div class="ui icon buttons">
          <a
            href="#/"
            class="ui white button"
            data-tooltip="View as calendar"
            data-position="bottom left"
            data-variation="mini"
            :class="{ active: route === '#/' }"
          >
            <i class="grey icon calendar" />
          </a>
          <a
            v-if="kanbanEnabled"
            href="#/board"
            class="ui white button"
            data-tooltip="View as board"
            data-position="bottom center"
            data-variation="mini"
            :class="{ active: route === '#/board' }"
          >
            <i class="grey icon columns" />
          </a>

          <a
            href="#/table"
            class="ui white button"
            data-tooltip="View as table"
            data-position="bottom right"
            data-variation="mini"
            :class="{ active: route === '#/table' }"
          >
            <i class="grey icon bars" />
          </a>
          <a
            v-if="reportEnabled && reportViewPath"
            :href="reportViewPath"
            data-tooltip="View report in new tab"
            data-position="bottom right"
            data-variation="mini"
            target="_blank"
            class="ui white button"
          >
            <i class="grey icon pie chart" />
          </a>
        </div>
      </div>

      <div v-if="editable" class="item fitted margin-right-zero">
        <div class="ui buttons">
          <a
            v-if="isMobileDevice"
            id="powerbarButton"
            class="ui icon button mobile-only"
            @click.stop.prevent="openPowerBar"
          >
            <i class="icon search" />
          </a>
          <a
            :class="{ loading: loadingNewEventForm }"
            class="ui primary icon button"
            @click="newEvent()"
          >
            <i class="icon add" />
            New
          </a>

          <div
            class="ui floating dropdown icon button primary"
            data-direction="downward"
            data-action="nothing"
          >
            <i class="dropdown icon" />
            <div class="menu">
              <a
                :class="{ loading: loadingNewEventForm }"
                class="item"
                @click.stop.prevent="newEvent()"
              >
                <i class="icon calendar" />
                Campaign
              </a>

              <a
                :class="{ loading: loadingNewEventForm }"
                class="item"
                @click.stop.prevent="newEvent({ aasm_state: 'proposed' })"
              >
                <i class="icon lightbulb" />
                Proposed Campaign
              </a>

              <a
                href="/events/templates?modal=true"
                data-modal="true"
                class="item"
              >
                <i class="icon copy" /> Campaign From Template
              </a>

              <a
                :class="{ loading: loadingNewEventForm }"
                class="item"
                @click.stop.prevent="newTask()"
              >
                <i class="icon check mark" />
                Task
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueRouter from "vue-router";
import store from "store";
import { mapState, mapGetters } from "vuex";
import debounce from "v-debounce";
import Dropdown from "components/dropdown.vue";
import EventBus from "components/eventbus";
import Filters from "views/events/eventFilters.vue";
import fieldDefinitions from "views/events/FieldDefinitions.js";

Vue.use(VueRouter);

const $ = window.jQuery;

export default {
  store,
  components: {
    dropdown: Dropdown,
    Filters,
  },
  directives: { debounce },

  props: {
    reportEnabled: {
      type: Boolean,
      default: false,
    },
    kanbanEnabled: {
      type: Boolean,
      default: true,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      route: window.location.hash,
      loadingNewEventForm: false,
      fieldDefinitions,
    };
  },
  computed: {
    ...mapState("route", {
      currentView: "path",
    }),
    ...mapState("calendar", {
      editable: "editable",
      calendars: "calendars",
      isLoading: "isLoading",
      selectedCalendars: "selectedCalendars",
      availableSorts: "availableSorts",
    }),
    ...mapGetters("calendar", {
      categories: "categories",
      users: "users",
      selectedCalendarObjects: "selectedCalendarObjects",
      filtersActive: "filtersActive",
      showCalendarDropdown: "showCalendarDropdown",
    }),
    showCalendarNameHeader() {
      return !this.showCalendarDropdown && this.selectedCalendars.length === 1;
    },

    selectedSort: {
      get() {
        return this.$store.state.calendar.selectedSort;
      },
      set(value) {
        this.$store.commit("calendar/updateSelectedSort", value);
      },
    },

    searchQuery: {
      get() {
        return this.$store.state.calendar.searchQuery;
      },
      set(value) {
        this.$store.commit("calendar/updateSearchQuery", value);
      },
    },

    searchPlaceholder() {
      if (!this.taggable) {
        return "Search ...";
      } else {
        return "Search title, tags, sponsors ...";
      }
    },
    isMobileDevice() {
      return window.innerWidth < 768;
    },
  },
  watch: {},

  mounted() {
    const $this = this;
    window.addEventListener(
      "hashchange",
      function () {
        $this.route = document.location.hash;
      },
      false
    );

    $($this.$refs.advancedSearchButton).popup({
      popup: $($this.$refs.filtersMenu),
      exclusive: false,
      position: "bottom right",
      duration: 10,
      transition: "drop",
      delay: {
        show: 5,
      },
      movePopup: false,
      forcePosition: true,
      lastResort: "bottom right",
      on: "click",
      distanceAway: 0,
    });
  },
  updated() {
    this.$nextTick(() => {
      $(this.$el)
        .find(".dropdown")
        .dropdown({
          direction: "downward",
        })
        .dropdown("refresh")
        .data("dropdown-loaded", true);
    });
  },
  methods: {
    openPowerBar() {
      EventBus.$emit("showPowerbar");
    },
    newEvent(
      params = {
        aasm_state: "approved",
        calendar_id: this.selectedCalendars[0],
      }
    ) {
      if (!this.editable) {
        return;
      }

      this.loadingNewEventForm = true;
      $.get("/events/new", {
        event: params,
      }).then(() => {
        this.loadingNewEventForm = false;
      });
    },
    newTask(params = {}) {
      if (!this.editable) {
        return;
      }
      EventBus.$emit("openNewEventModal", { class_name: "task" });
    },
  },
};
</script>

<style lang="scss" scoped>
.ui.basic.popup {
  box-shadow: 0 16px 30px 5px rgba(0, 0, 0, 0.16);
  max-width: 500px !important;
}

.ui.icon.buttons {
  .button.active {
    .icon.grey {
      color: #fff;
    }
  }
}

.right.item {
  padding: 0.49571429em 0.92857143em !important;
}

.item.multiple {
  padding-top: 0.2185em !important;
  padding-bottom: 0.2185em !important;
}

.right.menu .item .input.transparent input {
  padding-top: 0.57142857em !important;
  padding-bottom: 0.57142857em !important;
}
</style>
