import consumer from "./consumer";

consumer.subscriptions.create("EventsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    switch (data.action) {
      case "event-destroyed":
        window.EventBus.$emit("eventDestroyed", data.event);
        window.EventBus.$emit("reloadPitches");
        window.removeEvent(data.event);
        break;
      case "event-updated":
        window.EventBus.$emit("eventUpdated", data.event);
        window.EventBus.$emit("reloadPitches");
        window.refreshEvent(data.event);
        break;
      case "event-pitch-updated":
        window.EventBus.$emit("eventPitchUpdated", data.event);
        window.EventBus.$emit("reloadPitches");
        window.refreshEvent(data.event);
        break;
      case "event-deliverables-updated":
        window.EventBus.$emit("eventDeliverablesUpdated", data.deliverables);
        break;
      default:
        break;
    }
  },
});
