<template>
  <div
    class="vue-avatar--wrapper"
    :style="[style]"
    :title="username"
    :data-id="id"
  >
    <div :style="[style, initialBackgroundAndFontStyle, customStyle]">
      {{ userInitial }}
    </div>
    <div v-if="src" :style="[style, imgBackgroundAndFontStyle, customStyle]" />
  </div>
</template>

<script>
export default {
  name: "Avatar",
  props: {
    id: {
      type: String,
      default: "",
      required: false,
    },
    username: {
      type: String,
      required: true,
    },
    initials: {
      type: String,
      default: "",
    },
    backgroundColor: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    customStyle: {
      type: Object,
      default: function () {
        return {};
      },
    },
    size: {
      type: String,
      default: "50",
    },
    src: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    lighten: {
      type: Number,
      default: 80,
    },
  },
  data() {
    const $this = this;
    return {
      sizeAsString: parseInt($this.size, 10),
      backgroundColors: [
        "#F44336",
        "#FF4081",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFC107",
        "#FF9800",
        "#FF5722",
        "#795548",
        "#9E9E9E",
        "#607D8B",
      ],
    };
  },
  computed: {
    background() {
      if (this.username.length == 0) {
        return "#ffffff";
      } else {
        return (
          this.backgroundColor ||
          this.randomBackgroundColor(
            this.username.length,
            this.backgroundColors
          )
        );
      }
    },
    fontColor() {
      return this.color || this.lightenColor(this.background, this.lighten);
    },
    isImage() {
      return Boolean(this.src);
    },
    initialBackgroundAndFontStyle() {
      return {
        zposition: "absolute",
        ztop: 0,
        backgroundColor: this.background,
        font: `${Math.floor(
          this.sizeAsString / 2.5
        )}px/100px Helvetica, Arial, sans-serif`,
        fontWeight: "bold",
        color: this.fontColor,
        lineHeight: `${
          this.sizeAsString + Math.floor(this.sizeAsString / 20)
        }px`,
      };
    },
    imgBackgroundAndFontStyle() {
      let styleObject = {
        position: "absolute",
        backgroundSize: "cover",
        top: 0,
      };

      if (this.src && this.src != "null") {
        styleObject.background = `transparent url(${this.src}) no-repeat scroll 50% 50% / cover content-box border-box `;
        // ${this.sizeAsString}px ${this.sizeAsString}px
      }

      return styleObject;
    },
    style() {
      return {
        width: `${this.sizeAsString}px`,
        height: `${this.sizeAsString}px`,
        borderRadius: this.rounded ? "50%" : 0,
        textAlign: "center",
        verticalAlign: "middle",
      };
    },
    userInitial() {
      const initials = this.initials || this.initialsFromUserName;
      return initials;
    },
    initialsFromUserName() {
      const parts = this.username.split(/[ -]/);
      let initials = "";
      for (let i = 0; i < parts.length; i += 1) {
        initials += parts[i].charAt(0);
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, "");
      }
      initials = initials.substr(0, 3).toUpperCase();
      return initials;
    },
  },
  mounted() {
    this.$emit("avatar-initials", this.username, this.userInitial);
  },
  methods: {
    randomBackgroundColor(seed, colors) {
      return colors[seed % colors.length];
    },
    lightenColor(hex, amt) {
      let usePound = false;
      let theHex = hex;
      if (hex[0] === "#") {
        theHex = hex.slice(1);
        usePound = true;
      }
      const num = parseInt(theHex, 16);
      let r = (num >> 16) + amt; // eslint-disable-line no-bitwise
      if (r > 255) r = 255;
      else if (r < 0) r = 0;
      let b = ((num >> 8) & 0x00ff) + amt; // eslint-disable-line no-bitwise
      if (b > 255) b = 255;
      else if (b < 0) b = 0;
      let g = (num & 0x0000ff) + amt; // eslint-disable-line no-bitwise
      if (g > 255) g = 255;
      else if (g < 0) g = 0;
      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16); // eslint-disable-line no-bitwise
    },
  },
};
</script>

<style scoped lang="scss">
// .online {
//   animation: online-pulse 2s infinite;
// }

.vue-avatar--wrapper.online {
  position: relative;
  &:after {
    content: "";
    width: 25%;
    height: 25%;
    border-radius: 100%;
    background-color: green;
    border: 2px solid #fff;
    display: block;
    position: absolute;
    bottom: 10%;
    right: 0px;
  }
}

// .away {
//   box-shadow: 0px 0px 0px 0.2em grey;
// }

@keyframes online-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(49, 102, 0, 1);
  }

  70% {
    box-shadow: 0 0 0 0.5em rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
