<template>
  <div class="ui form task">
    <div v-if="$store.error" class="error message">
      {{ $store.error }}
    </div>

    <div class="fields">
      <div class="five wide field">
        <input
          ref="input"
          v-model="task.name"
          :placeholder="placeholder"
          class="new-task"
          @keypress.enter.prevent="submit"
        />
      </div>
      <!-- @keydown.shift.50="showUserSelection" -->

      <div v-if="!task.event_id" class="three wide field">
        <select
          v-model.lazy="task.calendar_id"
          class="ui calendar dropdown selection nowrap"
          data-placeholder="Select Calendar"
        >
          <option v-for="option in calendarsAsOptions" :value="option.value">
            <div
              class="ui left floated red empty circular label"
              :style="{ 'background-color': option.color + ' !important' }"
            />
            {{ option.label }}
          </option>
        </select>
      </div>

      <div class="three wide field">
        <select
          ref="userSelection"
          v-model.lazy="task.user_id"
          class="ui dropdown compact selection nowrap"
          data-placeholder="Select User"
        >
          <option v-for="option in usersAsOptions" :value="option.value">
            <avatar
              class="ui image avatar margin-right-mini"
              :username="option.label"
              :src="option.gravatar"
              size="22"
            />
            {{ option.label }}
          </option>
        </select>
      </div>

      <div ref="calendarPopup" class="ui popup">
        <div class="ui calendar" />
        <div v-if="task.event_id || parentEvent" class="field">
          <div class="ui checkbox margin-top-tiny">
            <input v-model="task.relative" type="checkbox" />
            <label>Relative to campaign date</label>
          </div>
        </div>
      </div>

      <div class="three wide field" @keypress.enter.prevent="submit">
        <div ref="calendarPopupTrigger" class="ui field">
          <div class="ui input left icon">
            <i class="calendar link icon" />
            <input
              type="text"
              :value="calendarFormattedDueDate"
              placeholder="Due Date"
              @focus.stop="openCalendarPopup"
            />
          </div>
        </div>
      </div>

      <div class="one wide computer three wide mobile field">
        <div v-if="!task.id" class="ui icon buttons">
          <button
            v-if="!task.id"
            type="submit"
            class="ui button primary"
            :class="{ loading, disabled: !task.name }"
            @click="submit()"
          >
            Add
          </button>
          <a v-if="allowCancel" class="ui button" @click="removeTask">
            <i class="icon delete padding-top-tiny" />
          </a>
        </div>
        <div v-if="task.id" class="ui icon tiny buttons compact">
          <button
            type="submit"
            class="ui button primary"
            :class="{ loading }"
            @click="submit()"
          >
            <i class="save icon" />
          </button>
          <a v-if="allowCancel" class="ui button" @click="cancelEditing">
            <i class="icon minus padding-top-tiny" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "components/dropdown.vue";
import store from "store";
import { mapState, mapGetters } from "vuex";
import Avatar from "components/avatar.vue";

// import _ from "lodash";

const { currentUserId, $ } = window;

export default {
  store,
  components: {
    Avatar,
  },
  props: {
    parentEvent: {
      type: Object,
      default: null,
    },
    allowCancel: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Task name",
    },
    // users: {
    //   type: Array,
    //   default() {
    //     return [];
    //   },
    // },
    task: {
      type: Object,
      default: null,
    },
    inPlace: false,
  },
  data() {
    return {
      loading: false,
      showUserDropdown: false,
    };
  },
  methods: {
    submit() {
      if (this.task.id) {
        this.updateTask();
      } else {
        this.addTask();
      }
    },
    removeTask() {
      this.$emit("removeTask");
    },
    cancelEditing() {
      this.$emit("cancelEditing");
    },
    changeUser(value, text) {
      if (value && text) {
        this.task.user_login = text;
        this.task.user_id = value;
      } else if (text == "Any User") {
        this.task.user_login = null;
        this.task.user_id = null;
      }
    },
    addTask() {
      this.hideCalendarPicker();
      this.loading = true;
      this.$store
        .dispatch("task/create", { item: this.task, inPlace: this.inPlace })
        .then(() => {
          if (!this.inPlace) {
            this.clear();
          }
          this.focusName();
          this.loading = false;
        });
    },
    updateTask() {
      this.hideCalendarPicker();
      this.loading = true;
      this.$store.dispatch("task/update", this.task).then(() => {
        this.loading = false;
        this.cancelEditing();
      });
    },
    focusName() {
      this.$el.querySelector("input.new-task").focus();
    },
    clearUserDropdown() {
      $(this.$refs.userSelection).dropdown("clear");
    },
    hideCalendarPicker() {
      $(this.$el).find(".calendar.field").calendar("blur");
    },
    clearCalendarPicker() {
      $(this.$el).find(".calendar.field").calendar("clear");
    },
    clear() {
      this.hideCalendarPicker();
      this.clearCalendarPicker();
      this.clearUserDropdown();
    },
    initCalendarPopup() {
      $(this.$refs.calendarPopupTrigger).popup({
        popup: $(this.$refs.calendarPopup),
        position: "bottom center",
        on: "click",
      });
    },
    openCalendarPopup() {
      $(this.$el).find(this.$refs.calendarPopupTrigger).popup("show");
      $(this.$refs.calendarPopup).find(".ui.calendar").calendar("focus");
    },
    closeCalendarPopup() {
      $(this.$el).find(this.$refs.calendarPopupTrigger).popup("hide");
    },
    initCalendar() {
      const $this = this;
      const dueOnPicker = $(this.$refs.calendarPopup).find(".ui.calendar");

      dueOnPicker.calendar({
        inline: true,
        type: "date",
        initialDate: $this.parentEvent
          ? window.moment($this.parentEvent.start).toDate()
          : null,
        onChange(date, text) {
          $this.$set($this.task, "due_on", text);
          $this.closeCalendarPopup();
        },
      });

      this.initCalendarPopup();
    },
    initDropdowns() {
      $(this.$el)
        .find(".dropdown.selection:not(.no-js)")
        .each((i, element) => {
          if (!$(element).data("dropdown-loaded")) {
            $(element)
              .dropdown({
                fullTextSearch: true,
                placeholder: $(element).data("placeholder") || "auto",
              })
              .closest(".dropdown")
              .data("dropdown-loaded", true);
          }
        });
    },
  },
  computed: {
    ...mapState("calendar", {
      calendars: "calendars",
    }),
    ...mapGetters("calendar", {
      users: "users",
    }),
    calendarFormattedDueDate() {
      if (!this.task.due_on || this.task.due_on === "") {
        return "";
      }
      return moment(this.task.due_on).format("MMMM D, YYYY");
    },
    usersAsOptions() {
      return this.users.map((user) => ({
        label: user.login,
        value: user.id,
        gravatar: user.gravatar,
      }));
    },
    calendarsAsOptions() {
      return this.calendars.map((calendar) => ({
        label: calendar.name,
        value: calendar.id,
        color: calendar.color,
      }));
    },
    currentUserId() {
      return currentUserId;
    },
  },
  mounted() {
    this.initCalendar();
    this.initDropdowns();
  },
  updated() {},
};
</script>

<!-- <style src="tributejs/dist/tribute.css"></style> -->
<style scoped>
.ui.selection.dropdown .text {
  white-space: nowrap !important;
}

input::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 300;
  color: #e6e6e6;
}
input::-moz-placeholder {
  font-style: italic;
  font-weight: 300;
  color: #e6e6e6;
}
input::input-placeholder {
  font-style: italic;
  font-weight: 300;
  color: #e6e6e6;
}
</style>
