<template>
  <div>
    <div v-sticky="{ zIndex: 1 }">
      <div :id="toolbarId" />
    </div>
    <ckeditor
      id="test"
      ref="ckeditor"
      v-model="text"
      :config="editorConfig"
      :class="{ empty: text == '' || !text }"
      :data-placeholder="placeholder"
      class="clearboth ckeditor"
      type="inline"
      @ready="editorReady"
      @blur="editorBlur"
      @focus="editorFocus"
      @input="editorInput"
    />
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import store from "store";
import { mapGetters } from "vuex";
import CKEditor from "ckeditor4-vue";
import VueSticky from "vue-sticky";

CKEDITOR.disableAutoInline = true;

Vue.use(CKEditor);

export default {
  store,
  components: {
    ckeditor: CKEditor.component,
  },
  directives: {
    sticky: VueSticky,
  },

  props: {
    value: String,
    submitOnEnter: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    initialConfig: {
      type: Object,
      default() {
        return null;
      },
    },
    initialUsers: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    toolbars: {
      type: Object,
      default() {
        return {
          default: [
            ["Link", "Unlink"],
            ["Image", "EmojiPanel"],
            ["Bold", "Italic"],
            ["NumberedList", "BulletedList", "-", "Outdent", "Indent"],
          ],
          bare: [
            ["Link", "Unlink"],
            ["Bold", "Italic"],
          ],
          full: [
            { name: "links", items: ["Link", "Unlink", "Image"] },
            {
              name: "basicstyles",
              items: ["Bold", "Italic", "Underline", "Styles", "RemoveFormat"],
            },
            {
              name: "paragraph",
              items: [
                "NumberedList",
                "BulletedList",
                "-",
                "Outdent",
                "Indent",
                "-",
                "Blockquote",
                "-",
                "JustifyLeft",
                "JustifyCenter",
                "JustifyRight",
                "JustifyBlock",
                "-",
              ],
            },

            { name: "colors", items: ["TextColor", "BGColor"] },
            {
              name: "insert",
              items: ["Table", "HorizontalRule"],
            },
          ],
        };
      },
    },
    toolbar: {
      type: String,
    },
  },

  data() {
    const $this = this;
    return {
      text: $this.value,
      focused: false,
      cancelEnterKey: false,
    };
  },
  computed: {
    ...mapGetters("calendar", ["users"]),
    editorConfig() {
      const $this = this;

      if (this.initialConfig) {
        return this.initialConfig;
      }

      const config = {
        disableNativeSpellChecker: false,
        enterMode: CKEDITOR.ENTER_BR,
        filebrowserBrowseUrl: "/uploads",
        filebrowserImageBrowseLinkUrl: "/uploads",
        filebrowserImageBrowseUrl: "/uploads",
        uploadUrl: "/uploads.json",

        allowedContent: true,
        removePlugins: "elementspath,floatingspace,exportpdf",

        resize_maxWidth: "100",
        resize_enabled: false,

        toolbar: $this.toolbars.default,

        extraPlugins:
          "editorplaceholder,clipboard,image2,sharedspace,openlink,textmatch,autolink,uploadimage,mentions,emoji,pastefromword,pastefromgdocs,removeformat",

        emoji_minChars: 1,
        openlink_modifier: 0,

        mentions: [
          {
            feed: $this.dataFeed,
            marker: "@",
            itemTemplate: `<li data-id="{id}">
              <v-avatar
                class="ui image avatar"
                username="{login}"
                src="{gravatar}"
                size="24"
              ></v-avatar>{login}</li>`,
            outputTemplate:
              '<span contenteditable="false" class="ui label" data-login="{login}">@{login}</span>&nbsp;',
            minChars: 0,
          },
        ],

        autolink_urlRegex: /^((https?|ftp):\/\/(-\.)?|www\.)([^\s\/?\.#]+\.?)+(\/[^\s]*)?[^\s\.,]$/i,

        extraAllowedContent: "img(*)[*]{*};video(*)[*]{*}",

        sharedSpaces: {
          top: `${this.toolbarId}`,
        },
        startupFocus: $this.autofocus,
      };

      if ($this.toolbar) {
        config.toolbar = $this.toolbars[$this.toolbar];
      }

      return config;
    },
    toolbarId() {
      return `cke-${Math.random().toString(36)}-toolbar`;
    },
  },
  mounted() {},
  methods: {
    dataFeed(opts, callback) {
      let allUsers = this.users.concat(this.initialUsers);

      var matchProperty = "login",
        data = allUsers.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) == 0
          );
        });

      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });

      callback(data);
    },
    clear() {
      this.$refs.ckeditor.instance.setData("");
    },
    editorChange() {},
    editorBlur() {
      this.focused = false;
    },
    editorFocus() {
      this.focused = true;
    },
    editorInput() {
      this.$emit("input", this.text);
    },
    editorReady(editor) {
      const $this = this;
      $this.$nextTick(() => {
        //editor.focus();
        editor.setKeystroke(CKEDITOR.CTRL + 220, "removeFormat"); // CTRL+ \\

        editor.on("fileUploadRequest", function (evt) {
          var fileLoader = evt.data.fileLoader,
            formData = new FormData(),
            xhr = fileLoader.xhr;

          xhr.setRequestHeader(
            "X-CSRF-Token",
            window.$('meta[name="csrf-token"]').attr("content")
          );

          formData.append("data", fileLoader.file, fileLoader.fileName);
          xhr.send(formData);

          evt.stop();
        });

        editor.on("fileUploadResponse", function (evt) {
          evt.stop();

          var data = evt.data,
            xhr = data.fileLoader.xhr,
            response = xhr.response;

          const json = JSON.parse(response);

          if (json.error) {
            data.message = json.error;
            evt.cancel();
          } else {
            data.url = json.asset.url_content;
          }
        });

        editor.on("key", function (event) {
          if (event.data.keyCode === 13 && $this.cancelEnterKey) {
            event.cancel();
          }
        });

        if (this.submitOnEnter) {
          editor.on("key", (e) => {
            if (e.data.domEvent.$.keyCode === 13 && e.data.domEvent.$.metaKey) {
              $this.$emit("submit", e.data.domEvent);
              e.data.domEvent.stopPropagation();
              e.data.domEvent.preventDefault();
              return false;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hovering {
  .ckeditor {
    border: 1px solid rgba(34, 36, 38, 0);
    &:hover {
      border: 1px solid rgba(34, 36, 38, 0.15);
    }
  }
}

.top.attached {
  .ckeditor {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-left: -1px;
    margin-right: -1px;
  }
}

.floating-menu {
  .ckeditor {
    border-top-left-radius: 0.28571429rem !important;
    border-top-right-radius: 0.28571429rem !important;
    border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
  }
}

.ckeditor {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none !important;
  padding: 0.78571429em 1em;
  margin-left: -1px;
  margin-right: -1px;
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  &.empty {
    &:before {
      content: attr(data-placeholder);
      position: absolute;
      opacity: 0.5;
    }
  }
}

/deep/ .ckeditor .cke_editable {
  min-height: 4rem;
}

/deep/ .cke_chrome {
  background: #fff;
  border: none;
  margin-left: -1px;
  margin-right: -1px;
  .cke_inner {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #fff;

    .cke_top {
      border: none;
      background: none;
    }
  }
}
</style>

<!-- 

    CKEDITOR.disableAutoInline = true;
    CKEDITOR.stylesSet.add( 'fomantic-ui', [
        { name: 'Header 1', element: 'h1', attributes: { 'class': 'ui header' } },
        { name: 'Header 2', element: 'h2', attributes: { 'class': 'ui header' } },
        { name: 'Header 3', element: 'h3', attributes: { 'class': 'ui header' } },
        { name: 'Celled Table', element: 'table', attributes: { 'class': 'ui celled table' } },
        { name: 'Striped Table', element: 'table', attributes: { 'class': 'ui striped table' } },
        { name: 'Basic Table', element: 'table', attributes: { 'class': 'ui basic table' } }
        { name: 'Basic Button', element: 'a', attributes: { 'class': 'ui button' } }          
        { name: 'Primary Button', element: 'a', attributes: { 'class': 'ui primary button' } }          
    ]);    

    CKEDITOR.config.removePlugins = 'elementspath'

    CKEDITOR.config.extraPlugins = "image2,autogrow"
    CKEDITOR.config.resize_maxWidth = "100"
    CKEDITOR.config.resize_enabled = false

    CKEDITOR.config.toolbar_None =
      [
        ['']
      ];
    CKEDITOR.config.toolbar_Bare =
      [
        ['Link','Unlink'],['Bold','Italic'],['NumberedList','BulletedList','-','Outdent','Indent']
      ];

    CKEDITOR.config.toolbar_Mini =
      [
        ['Link','Unlink'],['Image'],['Bold','Italic'],
        ['NumberedList','BulletedList','-','Outdent','Indent']
      ];

   
    CKEDITOR.on 'dialogDefinition', ( ev ) ->
      dialogName = ev.data.name
      dialogDefinition = ev.data.definition

      if dialogName == 'table'
        info = dialogDefinition.getContents( 'info' )
        info.get( 'txtWidth' )[ 'default' ] = '100%';
        info.get( 'txtBorder' )[ 'default' ] = '0'; -->
