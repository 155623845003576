import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import multipleUserSelect from "components/multipleUserSelect.vue";

document.addEventListener("turbo:load", function () {
  if (document.querySelectorAll(".v-user-selection")) {
    document.querySelectorAll(".v-user-selection").forEach((el) => {
      new Vue({
        el: el,
        components: {
          multipleUserSelect,
        },
      });
    });
  }
});
