import Vue from "vue/dist/vue.esm";
import { some, findIndex, uniqBy } from "lodash";

const { $, moment } = window;

const initialState = {
  events: [],
  tasks: [],
  editable: true,
  isLoading: false,
  calendars: [],
  selectedCalendars: localStorage.getItem("selectedCalendars")
    ? JSON.parse(localStorage.getItem("selectedCalendars"))
    : [],
  selectedCategory: "",
  selectedUser: "",
  selectedQuickDate: "",
  searchQuery: "",
  fieldFilters: {},
  statuses: ["proposed", "approved", "working", "completed", "rejected"],
  availableSorts: {
    date: { label: "Date", value: "starts_at|desc" },
    user: { label: "User", value: "username|asc" },
    category: { label: "Category", value: "event_category_id|asc" },
  },
  selectedSort: localStorage.getItem("kanban:selectedSort")
    ? localStorage.getItem("kanban:selectedSort")
    : "date",
};

const getters = {
  selectedSortParam(state) {
    return state.availableSorts[state.selectedSort].value;
  },

  filtersActive(state, getters) {
    return getters.advancedFiltersActive || getters.quickFiltersActive;
  },

  showCalendarDropdown() {
    return (
      !window.location.pathname.includes("/calendars/") ||
      window.location.pathname == "/"
    );
  },

  advancedFiltersActive(state) {
    return some(state.fieldFilters, (obj) => {
      return obj.value;
    });
  },
  quickFiltersActive(state, getters) {
    return (
      state.selectedCategory != "" ||
      state.selectedUser != "" ||
      state.selectedQuickDate != "" ||
      (getters.showCalendarDropdown &&
        (state.selectedCalendars.length != 0 &&
          state.selectedCalendars.length != state.calendars.length &&
          state.selectedCalendars[0] != "")) //stupid
    );
  },

  categories(state, getters) {
    // if (state.calendars.length === 1) {
    //   state.selectedCalendars = [state.calendars[0].id.toString()];
    // }

    let cals = getters.selectedCalendarObjects;
    if (cals.length === 0) {
      cals = state.calendars;
    }

    const cats = [];
    cals.forEach((cal) => {
      cal.event_categories.forEach((cat) => {
        cats.push(cat);
      });
    });
    return cats;
  },
  selectedCalendarObjects(state) {
    return state.calendars.filter((calendar) =>
      state.selectedCalendars.includes(calendar.id)
    );
  },
  users(state, getters) {
    let cals = getters.selectedCalendarObjects;

    if (cals.length === 0) {
      cals = state.calendars;
    }

    const users = [];
    cals.forEach((cal) => {
      cal.users.forEach((user) => {
        users.push(user);
      });
    });
    return uniqBy(users, "id");
  },
  selectedDateRange(state) {
    let startDate = moment();
    let endDate = moment();
    let startDelta = -3;
    let endDelta = 12;
    let unit = "month";

    if (state.selectedQuickDate != "") {
      switch (state.selectedQuickDate) {
        case "this_month":
          startDelta = 0;
          endDelta = 0;
          unit = "month";
          break;
        case "next_month":
          startDelta = 1;
          endDelta = 1;
          unit = "month";
          break;
        case "prev_month":
          startDelta = -1;
          endDelta = -1;
          unit = "month";
          break;
        case "this_week":
          startDelta = 0;
          endDelta = 0;
          unit = "week";
          break;
        case "next_week":
          startDelta = 1;
          endDelta = 1;
          unit = "week";
          break;
        case "prev_week":
          startDelta = -1;
          endDelta = -1;
          unit = "week";
          break;
      }

      startDate = startDate
        .add(startDelta, `${unit}s`)
        .startOf(unit)
        .format();
      endDate = endDate
        .add(endDelta, `${unit}s`)
        .endOf(unit)
        .format();

      return { startDate, endDate };
    } else {
      return { startDate: null, endDate: null };
    }
  },
  selectedStartDate(state, getters) {
    return getters.selectedDateRange.startDate;
  },
  selectedEndDate(state, getters) {
    return getters.selectedDateRange.endDate;
  },
};

const actions = {
  reloadCalendars({ commit }) {
    $.get("/calendars.json", (data) => {
      commit("updateCalendars", data);
    });
  },
  loadCalendars({ commit, state }) {
    if (state.calendars.length === 0) {
      $.get("/calendars.json", (data) => {
        commit("updateCalendars", data);
      });
    }
  },
  setLoading({ commit }, boolean) {
    commit("LOADING", boolean);
  },
  setEvents({ commit }, { events }) {
    commit("EVENTS", { events });
  },
  setTasks({ commit }, { tasks }) {
    commit("TASKS", { tasks });
  },
  addEvent({ commit }, { event }) {
    commit("ADD_EVENT", { event });
  },
  clearFieldFilters({ state, commit }) {
    Object.keys(state.fieldFilters).forEach((key) => {
      commit("clearFieldFilter", key);
    });
  },
  clearFilter({ commit }, key) {
    commit("clearFieldFilter", key);
  },
  clearQuickFilters({ state, getters }) {
    state.selectedCategory = "";
    state.selectedUser = "";
    state.selectedQuickDate = "";
    if (getters.showCalendarDropdown) {
      state.selectedCalendars = [];
    }
  },
};

const mutations = {
  LOADING(state, boolean) {
    state.isLoading = boolean;
  },
  ADD_EVENT(state, { event }) {
    const eventIndex = findIndex(state.events, { id: event.id });
    if (eventIndex !== -1) {
      Vue.set(state.events, eventIndex, event);
    } else {
      state.events.push(event);
    }
  },
  EVENTS(state, { events }) {
    state.events = events;
  },
  TASKS(state, { tasks }) {
    state.tasks = tasks;
  },

  setEditable(state, editable) {
    state.editable = editable;
  },

  updateCalendars(state, calendars) {
    state.calendars = calendars;
  },

  updateSelectedCalendars(state, calendarIds) {
    state.selectedCalendars = calendarIds;
  },

  updateSelectedCategory(state, categoryId) {
    state.selectedCategory = categoryId;
  },

  updateSelectedUser(state, userId) {
    state.selectedUser = userId;
  },

  updateSelectedQuickDate(state, date) {
    state.selectedQuickDate = date;
  },

  updateSearchQuery(state, searchQuery) {
    state.searchQuery = searchQuery;
  },

  updateFieldFilters(state, filters) {
    state.fieldFilters = filters;
  },

  updateSelectedSort(state, sort) {
    state.selectedSort = sort;
    localStorage.setItem("kanban:selectedSort", sort);
  },

  clearFieldFilter(state, field) {
    state.fieldFilters[field].value = "";
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
