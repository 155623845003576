import { Controller } from "@hotwired/stimulus";

const $ = window.jQuery;

// Connects to data-controller="reactions"
export default class extends Controller {
  static targets = ["input", "form"];
  connect() {
    this.initDropdown();

    this.element.querySelectorAll(".pointer").forEach((label) => {
      label.addEventListener("click", (event) => {
        this.inputTarget.value = event.target.dataset.emoji;
        this.formTarget.requestSubmit();
      });
    });
  }

  initDropdown() {
    const $this = this;
    var dropdown = $(this.element).find(".dropdown");
    dropdown.dropdown({
      on: "hover",
      delay: {
        hide: 300,
        show: 0,
      },
      onChange(text, value) {
        $this.inputTarget.value = value;
        $this.formTarget.requestSubmit();
      },
    });
    dropdown.data("dropdown-loaded", true);
  }
}
