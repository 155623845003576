<template lang="html">
  <div class="ui card" @mousedown.alt.stop="zoomStory">
    <div
      ref="zoomer"
      class="ui inverted blurring page dimmer zoomer"
      @mousedown.stop.prevent="zoomStory"
    >
      <div class="content">
        <div class="ui card left aligned">
          <instagram-story-embed :deliverable="deliverable" />
        </div>
      </div>
    </div>

    <div v-if="editable" ref="modal" class="ui small modal">
      <div class="content">
        <div class="ui grid">
          <div class="four wide column">
            <div class="ui card">
              <instagram-story-embed :deliverable="deliverable" />
            </div>
          </div>
          <div class="twelve wide column">
            <h2 class="ui header">Add Custom Stats</h2>
            <deliverable-form
              ref="form"
              :key="deliverable.id"
              :deletable="false"
              :initial-deliverable="deliverable"
              :metric-types="[]"
              :endpoint="endpoint"
            />
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui cancel button">Close</div>
        <div class="ui primary button" @click="save">Save</div>
      </div>
    </div>

    <instagram-story-embed :deliverable="deliverable" />

    <div class="bottom overlay editable right aligned padding-xy-tiny">
      <a
        v-if="editable"
        class="ui margin-right-tiny"
        @click.stop.prevent="editDeliverable"
        data-tooltip="Click to add link clicks and sticker taps."
        data-variation="small"
        data-inverted
      >
        <i class="large icons">
          <i class="link icon pencil alternate inverted" />

          <i
            v-if="hasManualStatistics"
            class="bottom right corner tiny circle icon pink"
          />
          <i v-else class="bottom right corner tiny add icon pink" />
        </i>

        <!-- <i class="large icons" v-if="hasManualStatistics">
          <i class="link icon pencil alternate inverted" />
          <i class="bottom right corner tiny circle icon pink" />
        </i>


        <i v-else class="link icon lightbulb inverted large fitted" /> -->
      </a>
      <a v-if="deletable" class="ui" @click.stop.prevent="deleteDeliverable">
        <i class="link icon trash alternate inverted large fitted" />
      </a>
    </div>

    <div v-if="selectable" class="bottom overlay">
      <i
        class="ui right floated link icon check circular pink inverted fitted"
      />
    </div>
  </div>
</template>

<script>
// import EventBus from "components/eventbus";
// import _ from "lodash";
import Vue from "vue/dist/vue.esm";
import DeliverableForm from "views/deliverables/form.vue";
import InstagramStoryEmbed from "views/deliverables/components/instagramStoryEmbed.vue";

const $ = window.jQuery;

export default {
  components: {
    DeliverableForm,
    InstagramStoryEmbed,
  },
  props: [
    "deliverable",
    "selectable",
    "deletable",
    "editable",
    "videoControlSelector",
    "endpoint",
    "metricTypes",
  ],
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    hasManualStatistics() {
      return (
        this.deliverable.manual_update_statistics[0] &&
        this.deliverable.manual_update_statistics[0].id
      );
      // this.deliverable.manual_update_statistics.length > 0;
    },
  },
  mounted() {
    const $this = this;
    this.$nextTick(() => {
      $this.initHeartBeat();

      $(this.$refs.modal).modal({
        centered: true,
        allowMultiple: true,
        closable: false,
        observeChanges: true,
        onShow: function () {
          $(".modal.visible.active .close.icon").hide();
          if (!$this.hasManualStatistics) {
            $this.deliverable.manual_update_statistics = [
              {
                deliverable_id: $this.deliverable.id,
                metric_type: "engagements",
                label: "Link Clicks",
              },
              {
                deliverable_id: $this.deliverable.id,
                metric_type: "engagements",
                label: "Sticker Taps",
              },
              {
                deliverable_id: $this.deliverable.id,
                metric_type: "engagements",
                label: "Likes",
              },
            ];
          }
        },
        onHidden: function () {
          if (
            !$this.hasManualStatistics
            // $this.deliverable.manual_update_statistics[0] &&
            // !$this.deliverable.manual_update_statistics[0].id
          ) {
            $this.deliverable.manual_update_statistics = [];
          }
          setTimeout(() => {
            $(".modal.active").modal("refresh");
          }, 250);

          $(".modal.visible.active .close.icon").show();
        },
      });
    });
  },
  methods: {
    loadData() {
      $.read(`/deliverables/${this.deliverable.id}.json`).done((data) => {
        Object.assign(this.deliverable, data);
        this.$set(this.deliverable, "statisticsValues", data.statisticsValues);
        // this.deliverable = Object.assign({}, this.deliverable, data);
      });
    },
    initHeartBeat() {
      if (typeof deliverablesChannel !== "undefined") {
        const $this = this;
        window.deliverablesChannel(
          $this.deliverable.id,
          "deliverable-updated",
          function (data) {
            $this.loadData();
          }
        );
      }

      // if (typeof hb !== "undefined") {
      //   const channel = hb.pusher.subscribe(
      //     `deliverable-${this.deliverable.id}`
      //   );

      //   channel.bind("deliverable-updated", (data) => {
      //     this.loadData();
      //   });
      // }
    },
    zoomStory() {
      if (this.$parent.$options.name == "slide") {
        this.openModal();
      } else {
        $(this.$el).find(this.$refs.zoomer).dimmer("toggle");
      }
    },
    openModal() {
      this.$emit("openModal", this.deliverable);
    },
    save() {
      // EventBus.$emit("saveAllDeliverables");
      this.$refs.form.save();
    },
    editDeliverable() {
      if (this.deliverable.manual_update_statistics.length < 1) {
        this.deliverable.manual_update_statistics.push({
          deliverable_id: this.deliverable.id,
        });
      }
      this.editing = true;
      $(this.$refs.modal).modal("show").modal("refresh");
    },
    deleteDeliverable() {
      const $this = this;
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure you want to delete this deliverable?")) {
        $this.$emit("delete", $this.deliverable);
        if ($this.deliverable.id) {
          $.destroy(`/deliverables/${$this.deliverable.id}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

body.alt {
  .card:hover {
    cursor: zoom-in;
  }
}

video,
.ui.image,
.ui.image img {
  background: none !important;
  border-radius: 0.28571429rem !important;
}

.selected,
.selecting,
.added {
  .overlay.bottom {
    display: block;
  }
}
.added {
  .disabling.dimmer {
    display: block !important;
    background: rgba(255, 255, 255, 0.55) !important;
    opacity: 1 !important;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5em;
  &.bottom {
    display: none;
    &.editable {
      display: block;
    }
    bottom: 0;
  }
}

div.ui[class*="9:16"].embed {
  padding-bottom: 178%;
  background: none !important;
}

.link.icon {
  opacity: 1 !important;
  &.check {
    box-shadow: 0 0 0 2px #fff;
  }

  &.trash,
  &.pencil,
  &.exclamation {
    color: #fff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
}

.zoomer.dimmer {
  // z-index: 999999 !important;
  cursor: zoom-out;
  justify-content: flex-start !important;
}
</style>
