<template lang="html">
  <div class="ui form clearing margin-top-medium" @keypress.enter="save">
    <event-calendar-selection
      :can-update-calendar="canUpdateCalendar"
      @selectedCalendarId="setCalendarId"
      @selectedUserId="setUserId"
      @selectedCategoryId="setCategoryId"
    />

    <div class="ui divider margin-y-small" />

    <div class="fields">
      <div class="six wide field">
        <label> Sponsor</label>
        <select
          ref="selectSponsor"
          v-model="event.organization_id"
          data-additions="true"
          data-clearable="true"
          data-forceselection="true"
          data-placeholder="Type to add a sponsor"
          class="ui search compact dropdown selection"
        >
          <option value="">No sponsor</option>
          <option
            v-for="option in sponsors"
            :key="option.id"
            :value="option.id"
          >
            {{ option.name }}
          </option>
        </select>
      </div>

      <div v-if="calendar && calendar.use_rates" class="five wide field">
        <label>
          Rate
          <span
            :data-tooltip="`Only ${calendar.name} admins and ${event.username} can see this.`"
            data-inverted="true"
            data-variation="mini"
          >
            <i class="icon question mark circle grey small" />
          </span>
        </label>

        <div class="ui left icon input">
          <input v-model.number="event.rate" type="number" />
          <i class="icon dollar sign" />
        </div>
      </div>

      <div v-if="showStatusField" class="five wide field">
        <label>Status</label>
        <select
          v-model.lazy="event.aasm_state"
          class="ui dropdown selection padding-y-tiny"
        >
          <option
            v-for="option in eventStates"
            :key="option"
            :value="option"
            :selected="option === event.aasm_state"
          >
            <div :class="option" class="ui label small">
              {{ option | capitalize }}
            </div>
          </option>
        </select>
      </div>
    </div>

    <div class="fields datepair">
      <div class="six wide field">
        <label>
          <!-- <i class="icon calendar alternate"></i> -->
          Date
        </label>
        <div>
          <div id="start_date" class="ui calendar field">
            <div class="ui input">
              <input
                :value="calendarFormattedDateTime(event.starts_at)"
                class="date start"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-show="showEndDate" class="five wide field desktop-only">
        <label>End date</label>

        <div id="end_date" class="ui calendar field">
          <div class="ui input">
            <input
              :value="calendarFormattedDateTime(event.ends_at)"
              class="date end"
            />
          </div>
        </div>
      </div>

      <div class="field desktop-only">
        <label>&nbsp;</label>
        <div class="ui text small margin-top-small">
          <a href="#" @click.stop.prevent="toggleEndDate">
            <span v-if="showEndDate">- end date</span>
            <span v-else>+ end date (optional)</span>
          </a>
        </div>
      </div>
    </div>

    <div class="fields">
      <div class="field sixteen wide">
        <label>Tags</label>
        <div
          :key="availableTags.length"
          ref="tagList"
          class="ui multiple search selection dropdown"
          data-additions="true"
        >
          <input v-model.lazy="event.tag_list" type="hidden" />

          <i v-if="availableTags.length > 0" class="dropdown icon" />

          <div class="default text">Type and hit enter to add a tag</div>
          <div class="menu">
            <div
              v-for="option in availableTags"
              :data-value="option.name"
              class="item"
            >
              {{ option.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui field">
      <label class="label"> Description </label>

      <text-editor
        :key="event.id + 'editor'"
        v-model="event.description"
        class="editor"
        :autofocus="false"
      />

      <uploads-index-view
        :key="'uploader-event-' + event.id"
        :url="uploadsUrl"
        :allow-uploads="true"
        class="margin-top-small"
        @complete="addUpload"
      />
    </div>

    <div v-if="event.id && wordpressEnabled" class="field">
      <div class="ui checkbox">
        <input
          v-model="event.wordpress_sync_enabled"
          type="checkbox"
          name="sync"
        />
        <label>
          <span v-if="event.last_synced_at"> Synced to Wordpress </span>
          <span
            v-else
            data-tooltip="Not synced yet."
            data-inverted="true"
            data-variation="mini"
          >
            Sync to Wordpress
          </span>
        </label>
      </div>
      <br />
      <span
        v-if="event.wordpress_edit_url"
        data-tooltip="Open in Wordpress"
        data-inverted="true"
        data-variation="mini"
        class="ui text small"
      >
        Last synced:
        <a :href="event.wordpress_edit_url" target="_blank">
          <span v-if="event.last_synced_at">
            {{ event.last_synced_at }}
          </span>

          <i class="icon external alternate small" />
        </a>
      </span>
    </div>

    <div class="clearing">
      <a
        v-if="event.id"
        :href="url"
        class="ui labeled icon button red left floated"
        @click.stop.prevent="destroyAndClose"
      >
        <i class="icon trash alternate" />
        Delete
      </a>

      <div class="ui right floated">
        <div class="ui buttons">
          <a
            v-shortkey="['shift', 'c']"
            class="ui button"
            @shortkey="saveAndClose"
            @click.stop.prevent="saveAndClose"
          >
            Save & close
          </a>
          <a
            v-shortkey="['shift', 's']"
            class="ui button primary"
            :class="{ loading }"
            @shortkey="save"
            @click="save"
          >
            Save
          </a>
        </div>
      </div>
    </div>

    <div v-if="error" class="ui error message visible">
      <div class="ui relaxed list">
        <div v-for="err in error" class="item">
          {{ err }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import "idempotent-babel-polyfill";
// import "document-register-element/build/document-register-element";
import { mapState, mapActions } from "vuex";
import store from "store";

import UploadsIndexView from "views/uploads/index.vue";
import EventCalendarSelection from "views/events/form/calendar.vue";
import textEditor from "components/textEditor.vue";

import ResizeObserver from "resize-observer-polyfill";
import "functions/filters";

const { $ } = window;
const { moment } = window;

export default {
  store,
  components: {
    textEditor,
    UploadsIndexView,
    EventCalendarSelection,
  },
  filters: {},
  props: [
    "initialEvent",
    "calendar",
    // "calendars",
    "eventStates",
    "showStatusField",
    "canUpdateCalendar",
    "reloadAfterSave",
    "wordpressEnabled",
  ],
  data() {
    return {
      closeAfterSave: false,
      availableTags: [],
      loading: false,
      // event: $this.initialEvent,
      showEndDate: false,
      showDescription: false,
      selectedCalendar: null,
      eventCategories: [],
      users: [],
    };
  },
  computed: {
    ...mapState("event", {
      event: "current",
      error: "error",
    }),
    ...mapState("sponsor", {
      sponsors: "all",
    }),
    uploadsUrl() {
      return `/uploads?assetable_type=Event&assetable_id=${this.event.id}`;
    },
    endpoint() {
      return `/calendars/${this.calendar.id}/events`;
    },
    url() {
      let url = `${this.endpoint}`;
      if (this.event.id) {
        url += `/${this.event.id}`;
      }
      return url;
    },
    requestType() {
      let type = "post";
      if (this.event.id) {
        type = "patch";
      }
      return type;
    },
  },
  methods: {
    ...mapActions("event", {
      setEvent: "setCurrent",
      create: "create",
      update: "update",
      destroy: "destroy",
    }),
    reloadSuggestedTasks() {
      if (this.event.id && this.event.tasks_count == 0) {
        this.$store.dispatch("task/loadAll", { parentEvent: this.event });
      }
    },
    destroyAndClose() {
      if (confirm("Are you sure you want to delete this?")) {
        this.destroy(this.event).then(() => {
          this.closeModal();
        });
      }
    },
    save() {
      this.loading = true;

      if (this.event.id) {
        this.update(this.event).finally(() => {
          this.loading = false;
          if (this.closeAfterSave) {
            this.closeModal();
          }
        });
      } else {
        this.create(this.event).finally(() => {
          this.loading = false;
          this.reloadSuggestedTasks();
        });
      }
    },
    saveAndClose() {
      this.closeAfterSave = true;
      this.save();
    },
    calendarFormattedDateTime(dateTime) {
      if (!dateTime || dateTime === "") {
        return "";
      }
      return moment(dateTime).format("MMMM D, YYYY h:mm A");
    },
    setCalendarId(val) {
      this.event.calendar_id = val;
    },
    setUserId(id, login) {
      this.event.user_id = id;
      this.event.username = login;
    },
    setCategoryId(val) {
      this.event.event_category_id = val;
    },
    toggleEndDate() {
      this.showEndDate = !this.showEndDate;
      if (!this.showEndDate) {
        this.event.ends_at = null;
      }
    },

    addUpload(upload) {
      this.event.upload_ids = this.event.upload_ids || [];
      this.event.upload_ids.push(upload.id);
    },
    clearSponsor() {
      $(this.$refs.selectSponsor).dropdown("clear");
    },
    loadTags() {
      const $this = this;
      $.get("/tags.json").done((data) => {
        $this.availableTags = data;
      });
    },
    initDropdowns() {
      $(this.$el)
        .find(".dropdown")
        .each((i, element) => {
          if (!$(element).data("dropdown-loaded")) {
            $(element)
              .dropdown({
                clearable: $(element).data("clearable") || false,
                direction: $(element).data("direction") || "auto",
                allowAdditions: $(element).data("additions") || false,
                hideAdditions: false,
                placeholder: $(element).data("placeholder") || "auto",
                on: $(element).data("on") || "click",
                values: $(element).data("values"),
                fullTextSearch: true,
                forceSelection: $(element).data("forceSelection") || false,
                selectOnKeydown: false,
              })
              .closest(".dropdown")
              .data("dropdown-loaded", true);
          }
        });
    },
    initForm() {
      const $this = this;

      this.showEndDate = $this.event.ends_at != null;

      if (this.canUpdateCalendar) {
        this.loadTags();
        this.$store.dispatch("sponsor/loadAll");
      }
      this.initDropdowns();

      const thisEl = $(this.$el);
      thisEl.find("#event_name").focus();
      thisEl.find("#start_date").calendar({
        selectAdjacentDays: true,
        endCalendar: $("#end_date"),
        disableMinute: true,
        onChange(date, text) {
          thisEl.find("#start_date input").trigger("change");
          $this.event.starts_at = text;
        },
      });
      thisEl.find("#end_date").calendar({
        selectAdjacentDays: true,
        startCalendar: $("#start_date"),
        disableMinute: true,
        onChange(date, text) {
          thisEl.find("#end_date input").trigger("change");
          $this.event.ends_at = text;
        },
      });

      $(this.$el).find(".ui.checkbox").checkbox();
    },
    closeModal() {
      window.EventBus.$emit("closeEventModal");
    },
    loadEventModal() {
      window.EventBus.$emit("openEventModal", this.event.url);
    },
    debouncedUpdate: _.debounce(
      function () {
        if (!this.loading) {
          this.save();
        }
      },
      750,
      { maxWait: 5000 }
    ),
  },
  watch: {
    // "event.description": _.throttle(function(val, old) {
    //   if (!old) return;
    //   this.debouncedUpdate();
    // }, 1500),
    "event.description": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.name": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.starts_at": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.ends_at": function (val, old) {
      if (val != old) {
        this.debouncedUpdate();
      }
    },
    "event.rate": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.aasm_state": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.calendar_id": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.event_category_id": function (val, old) {
      // if (!old) return;
      if (val != old) {
        this.debouncedUpdate();
        this.reloadSuggestedTasks();
      }
    },
    "event.user_id": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.tag_list": function (val, old) {
      if (!old) return;
      this.debouncedUpdate();
    },
    "event.organization_id": function (val, old) {
      if (val != old) {
        this.debouncedUpdate();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initDropdowns();
      this.initForm();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.initDropdowns();
    });
  },
};
</script>

<style lang="scss" scoped>
@import "vue-resize/dist/vue-resize.css";

.editor /deep/ {
  div[style*="sticky"] {
    top: -1em !important;
  }
}

.dropdown.tags {
  padding-left: 2.67142857em !important;
  padding-right: 1em !important;
}

.multiple.selection.dropdown {
  > i.icon.left {
    cursor: default;
    pointer-events: none;
    position: absolute;
    width: auto;
    height: auto;
    line-height: 1.21428571em;
    top: 0.78571429em;
    left: 1em;
    z-index: 3;
    margin: -0.78571429em;
    padding: 0.91666667em;
    opacity: 0.5;
    transition: opacity 0.1s ease;
  }
}
</style>
