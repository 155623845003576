import "idempotent-babel-polyfill";
import Vue from "vue";

function pluralize(count, noun, suffix = "s") {
  return `${count} ${noun}${count !== 1 ? suffix : ""}`;
}

function capitalize(value) {
  if (!value) return "";
  return value.toString().charAt(0).toUpperCase() + value.slice(1);
}

function humanize(value) {
  var arr = value.split(/[_-]/);
  var newStr = "";
  for (var i = 1; i < arr.length; i++) {
    newStr += " " + arr[i].charAt(0) + arr[i].slice(1);
  }
  return capitalize(arr[0] + newStr);
}

function toCamelCase(value) {
  var arr = value.split(/[_-]/);
  var newStr = "";
  for (var i = 1; i < arr.length; i++) {
    newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr[0] + newStr;
}

function round(n, precision) {
  var prec = Math.pow(10, precision);
  return Math.round(n * prec) / prec;
}

function abbreviateNumber(n, min = 1000000, precision = 2) {
  if (n < min) {
    return n.toLocaleString();
  }
  var abbrev = "kmb"; // could be an array of strings: [' m', ' Mo', ' Md']
  var pow = Math.pow,
    floor = Math.floor,
    abs = Math.abs,
    log = Math.log;

  var base = floor(log(abs(n)) / log(1000));
  var suffix = abbrev[Math.min(2, base - 1)];
  base = abbrev.indexOf(suffix) + 1;
  return suffix ? round(n / pow(1000, base), precision) + suffix : "" + n;
}

Vue.filter("pluralize", pluralize);
Vue.filter("capitalize", capitalize);
Vue.filter("humanize", humanize);
Vue.filter("abbreviateNumber", abbreviateNumber);
Vue.filter("toCamelCase", toCamelCase);

Vue.filter("formatDateTime", (value) => {
  if (!value) return "";
  return window.moment(value).format("MM/DD/YY h:mma");
});

Vue.filter("formatDate", (value) => {
  if (!value) return "";
  // return window.moment(value).format("MM/DD/YY");
  return window.moment(value).calendar(window.moment(), {
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    lastWeek: "L",
    nextWeek: "MMM D",
    sameElse: "L",
  });
});

Vue.filter("truncate", function (value, limit) {
  let truncated = value || "";
  if (value.length > limit) {
    truncated = value.substring(0, limit - 3) + "...";
  }
  return truncated;
});
