// import Vue from "vue/dist/vue.esm";
// import _ from "lodash";
// import axios from "axios";

// const $ = window.jQuery;
// import Toasted from "vue-toasted";

const initialState = {
  config: {},
  sidebarIsShown: JSON.parse(localStorage.getItem("showSidebar") || true),
};

const getters = {
  tabsEnabled(state) {
    return state.config ? state.config.tabs_enabled : [];
  },
  enabled: (state, getters) => (string) => {
    return getters.tabsEnabled.includes(string);
  },
};

const actions = {
  setConfigFromMeta({ commit }) {
    const configElement = document.getElementById("config");
    if (configElement) {
      const metaConfig = JSON.parse(configElement.innerHTML);
      commit("SET_CONFIG", metaConfig);
    }
  },
  toggleSidebar({ commit, state }) {
    commit("SET_SIDEBAR", !state.sidebarIsShown);
  },
  showSidebar({ commit }) {
    commit("SET_SIDEBAR", true);
  },
  hideSidebar({ commit }) {
    commit("SET_SIDEBAR", false);
  },
};

const mutations = {
  SET_CONFIG(state, value) {
    state.config = value;
  },
  SET_SIDEBAR(state, value) {
    localStorage.setItem("showSidebar", value);
    state.sidebarIsShown = value;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
