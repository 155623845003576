var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasBeenLoaded)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
     callback: _vm.visibilityChanged,
     throttle: 300,
     intersection: {
       rootMargin: '1000px',
     },
   }),expression:"{\n     callback: visibilityChanged,\n     throttle: 300,\n     intersection: {\n       rootMargin: '1000px',\n     },\n   }"}],staticClass:"ui deliverable card fluid",class:[((_vm.deliverable.inferred_type) + "-card"), { fluid: _vm.fluid }],attrs:{"id":("deliverableCard" + (_vm.deliverable.id))}},[(!_vm.isVisible)?[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2)]:_vm._e(),_vm._v(" "),(_vm.isVisible)?[(!_vm.editable || _vm.showCardHeader)?_c('div',{staticClass:"content center aligned card-header"},[_c('span',{staticClass:"icon-head"},[(_vm.incomplete)?_c('i',{staticClass:"ui icon wait"}):_vm._e(),_vm._v(" "),(!_vm.incomplete && !_vm.favicon)?_c('i',{staticClass:"icon colored",class:_vm.inferredTypeAsIconClass(_vm.deliverable.inferred_type)}):_vm._e(),_vm._v(" "),(!_vm.incomplete && _vm.favicon)?_c('img',{attrs:{"src":_vm.favicon,"height":"55","width":"55"}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"header ui tiny statistics"},[(
             _vm.deliverable.statisticsValues &&
             _vm.deliverable.statisticsValues.length > 0
           )?_c('div',{staticClass:"statistic"},[(_vm.headerStat)?_c('div',{staticClass:"value"},[_c('span',[(_vm.headerStat.type == 'revenue')?_c('i',{staticClass:"ui icon dollar fitted"}):_vm._e(),_vm._v("\n               "+_vm._s(_vm._f("abbreviateNumber")(_vm.headerStat.total.value))+"\n             ")])]):_vm._e(),_vm._v(" "),(_vm.headerStat && _vm.headerStat.details.length > 0)?_c('div',{staticClass:"ui tiny popup desktop-only"},[_c('div',{staticClass:"ui list"},_vm._l((_vm.headerStat.details),function(detail){return _c('div',{staticClass:"item"},[_vm._v("\n                 "+_vm._s(detail)+"\n               ")])}),0)]):_vm._e(),_vm._v(" "),(_vm.headerStat.total && _vm.headerStat.total.value > 0)?_c('div',{staticClass:"label"},[_vm._v("\n             "+_vm._s(_vm.headerStat.total.label)+"\n           ")]):_vm._e()]):_c('div',{staticClass:"statistic"},[_c('div',{staticClass:"value"},[_vm._v(" ")]),_vm._v(" "),_c('div',{staticClass:"label"},[_vm._v(" ")])])])]):_vm._e(),_vm._v(" "),(_vm.shouldShowSecondaryStats)?_c('div',{staticClass:"ui content statistics-head"},[_c('div',{ref:"statisticsDetails",staticClass:"ui wide popup desktop-only"},[_vm._l((_vm.statisticsDetails),function(group){return (group.details.length > 0)?_c('div',{staticClass:"ui statistic margin-left-zero left aligned"},[_c('div',{staticClass:"label"},[_vm._v("\n             "+_vm._s(group.total.label)+"\n           ")]),_vm._v(" "),_c('div',{staticClass:"ui list margin-top-tiny margin-bottom-small"},_vm._l((group.details),function(detail){return _c('div',{staticClass:"ui item"},[_vm._v("\n               "+_vm._s(detail)+"\n             ")])}),0)]):_vm._e()}),_vm._v(" "),_vm._m(3)],2),_vm._v(" "),_c('div',{staticClass:"ui equal width grid"},_vm._l((_vm.secondaryStats.slice(0, 3)),function(statisticValue){return (
             statisticValue.total.value != 0 &&
             statisticValue.total.value != '00:00:00'
           )?_c('div',{staticClass:"ui column tiny statistic"},[_c('div',{staticClass:"value"},[_c('span',[_vm._v(_vm._s(_vm._f("abbreviateNumber")(statisticValue.total.value)))])]),_vm._v(" "),_c('div',{staticClass:"label"},[_c('span',[_vm._v(_vm._s(statisticValue.total.label))])])]):_vm._e()}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"content card-content"},[(_vm.searchStats && _vm.searchStats.total.count > 0)?_c('div',{staticStyle:{"position":"absolute","width":"75%","z-index":"20"}},[_vm._m(4),_vm._v(" "),_c('div',{staticClass:"ui fluid inverted popup search"},[_c('div',{staticClass:"ui list"},_vm._l((_vm.searchStats.details),function(detail){return _c('div',{staticClass:"item"},[_vm._v("\n               "+_vm._s(detail)+"\n             ")])}),0)])]):_vm._e(),_vm._v(" "),(!_vm.deliverable.url)?_c('h3',{staticClass:"margin-top-zero"},[_vm._v("\n         "+_vm._s(_vm.deliverable.name)+"\n       ")]):_vm._e(),_vm._v(" "),(_vm.incomplete)?_c('div',{staticClass:"center aligned content"},[_c('br'),_c('br'),_c('br'),_vm._v(" "),_c('i',{staticClass:"ui grey huge icon wait"}),_vm._v("\n         This deliverable is incomplete...\n       ")]):_vm._e(),_vm._v(" "),(_vm.deliverable.description)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.deliverable.description)}}):_vm._e(),_vm._v(" "),_c('carousel',{ref:"carousel",attrs:{"per-page":1,"pagination-enabled":false,"navigation-enabled":true,"mouse-drag":false,"touch-drag":false,"navigation-next-label":"<i class='icon large grey chevron right'></i>","navigation-prev-label":"<i class='icon large grey chevron left'></i>"}},[(_vm.showIframelyEmbed)?_c('slide',[_c('div',{staticClass:"ui carousel content"},[(!_vm.isPushNotificationType)?_c('div',{staticClass:"iframely-embed centered"},[_c('div',{staticClass:"iframely-responsive"},[(
                     ['mailchimp', 'email'].includes(
                       _vm.deliverable.inferred_type
                     ) ||
                     (!['shopify', 'pinterest'].includes(
                       _vm.deliverable.inferred_type
                     ) &&
                       !_vm.isEmailType)
                   )?_c('a',{ref:"iframelyLink",attrs:{"href":_vm.urlOrExpandedUrl,"data-iframely-url":_vm.iframelyUrl}}):_vm._e(),_vm._v(" "),(
                     _vm.deliverable.inferred_type === 'pinterest' && _vm.iframelyData
                   )?_c('img',{attrs:{"src":_vm.iframelyData.links.thumbnail[0].href}}):_vm._e(),_vm._v(" "),(
                     _vm.deliverable.inferred_type === 'activecampaign' ||
                     _vm.deliverable.inferred_type === 'drip' ||
                     _vm.deliverable.inferred_type === 'klaviyo' ||
                     _vm.deliverable.inferred_type === 'constantcontact' ||
                     _vm.deliverable.inferred_type === 'campaignmonitor' ||
                     _vm.deliverable.inferred_type === 'shopify'
                   )?_c('turbo-frame',{staticClass:"ui 9:16",staticStyle:{"background":"#fff"},attrs:{"id":("deliverable_" + (_vm.deliverable.id) + "_preview"),"src":("/deliverables/" + (_vm.deliverable.id) + "/preview"),"loading":"lazy"}}):_vm._e(),_vm._v(" "),(
                     _vm.isEmailType &&
                     _vm.deliverable.inferred_type != 'activecampaign' &&
                     _vm.deliverable.inferred_type != 'klaviyo' &&
                     _vm.deliverable.inferred_type != 'constantcontact' &&
                     _vm.deliverable.inferred_type != 'campaignmonitor'
                   )?_c('div',{staticClass:"center aligned"},[_c('br'),_c('br'),_c('br'),_c('br'),_vm._v(" "),_c('i',{staticClass:"icon envelope open outline massive disabled"})]):_vm._e()],1)]):_vm._e()]),_vm._v(" "),(
               _vm.isPushNotificationType && _vm.deliverable.provider_caption != null
             )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.deliverable.provider_caption)}}):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.isInstagramStoryType)?_c('slide',[_c('div',{staticClass:"ui carousel content center aligned"},[_c('instagram-story-embed',{staticStyle:{"width":"55%","margin":"0px auto"},attrs:{"deliverable":_vm.deliverable}})],1)]):_vm._e(),_vm._v(" "),_vm._l((_vm.deliverable.uploads),function(upload,i){return (_vm.deliverable.uploads.length > 0 && !_vm.isInstagramStoryType)?_c('slide',{key:((upload.id) + "-" + (upload.position))},[(
               ['mp4', 'mov'].some(function (ext) { return upload.expiring_url.includes(ext); })
             )?_c('div',{staticClass:"ui video embed active 9:16",staticStyle:{"width":"40%","margin":"0px auto","padding-bottom":"71%"},attrs:{"id":'embed' + _vm.deliverable.id,"data-url":upload.expiring_url}},[_c('video',{attrs:{"src":upload.expiring_url,"width":"100%","controls":"","allow":"autoplay"}})]):(upload.expiring_url.includes('pdf') && !_vm.isCardPage)?_c('div',{staticClass:"flex-wrap"},[_c('iframe',{attrs:{"src":((upload.expiring_url) + "#toolbar=0&view=Fit"),"width":"100%","height":"99%","frameborder":"0"}})]):(upload.expiring_url.includes('pdf') && _vm.isCardPage)?_c('div',{staticClass:"flex-wrap"},[_c('img',{attrs:{"src":'data:image/png;base64,' + upload.pdf_thumbnail_base64}})]):_c('div',{staticClass:"flex-wrap"},[_c('a',{staticClass:"slide-image",style:({
                 backgroundImage: ("url(" + (upload.expiring_url) + ")"),
                 backgroundPosition: 'center',
                 backgroundSize: 'contain',
                 backgroundRepeat: 'no-repeat',
               }),attrs:{"href":upload.expiring_url,"target":"_blank"}}),_vm._v(" "),(_vm.deliverable.uploads.length > 1)?_c('div',{staticClass:"center aligned content pagination-label"},[_c('span',[_vm._v(" "+_vm._s(i + 1)+"/"+_vm._s(_vm.deliverable.uploads.length)+" ")])]):_vm._e()])]):_vm._e()})],2),_vm._v(" "),(_vm.canShowMore && _vm.showMore)?_c('a',{staticClass:"opener",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openModal($event)}}},[_c('i',{staticClass:"icon expand"})]):_vm._e()],1),_vm._v(" "),(_vm.deliverable.url && !_vm.isInstagramStoryType)?_c('div',{staticClass:"extra content"},[(_vm.showExternalLink)?_c('a',{staticClass:"link",attrs:{"href":_vm.externalLink,"target":"_blank"}},[_c('i',{staticClass:"icon external alternate"}),_vm._v(" "),(_vm.deliverable.inferred_type === 'url')?_c('span',[_vm._v("View on Blog")]):_vm._e(),_vm._v(" "),(_vm.deliverable.inferred_type === 'bitly')?_c('span',[_vm._v("View link")]):_vm._e(),_vm._v(" "),(_vm.deliverable.inferred_type === 'short_io')?_c('span',[_vm._v("View link")]):_vm._e(),_vm._v(" "),(_vm.isEmailType)?_c('span',[_vm._v("View "),_c('span',{staticClass:"provider"},[_vm._v("email")])]):_vm._e(),_vm._v(" "),(_vm.isPodcastType)?_c('span',[_vm._v("View "),_c('span',{staticClass:"provider"},[_vm._v("podcast")])]):_vm._e(),_vm._v(" "),(_vm.deliverable.inferred_type.toLowerCase().includes('other'))?_c('span',[_vm._v("View "),_c('span',{staticClass:"provider"},[_vm._v("link")])]):(
             !['email', 'url', 'bitly', 'short_io', 'other'].includes(
               _vm.deliverable.inferred_type.toLowerCase()
             ) &&
             !_vm.isEmailType &&
             !_vm.isPodcastType
           )?_c('span',[_vm._v("View on\n           "),_c('span',{staticClass:"provider"},[_vm._v("\n             "+_vm._s(_vm._f("toCamelCase")(_vm.inferredTypeAsIconClass(_vm.deliverable.inferred_type)))+"\n           ")])]):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ui modal"}),_vm._v(" "),(_vm.editable && !_vm.showStatusLabel)?_c('span',{staticClass:"ui right corner label status",on:{"click":function($event){if(!$event.altKey){ return null; }return _vm.openReportingStatus($event)}}},[_c('span',{attrs:{"data-forcewrap":""}},[_c('a',{on:{"click":_vm.refreshStats}},[_c('i',{staticClass:"icon sync",class:{ loading: _vm.refreshingStats }})])])]):_vm._e(),_vm._v(" "),(_vm.showStatusLabel)?_c('span',{staticClass:"ui right corner label",on:{"click":[_vm.refreshStats,function($event){if(!$event.altKey){ return null; }return _vm.openReportingStatus($event)}],"~mouseover":function($event){return _vm.loadReportingStatus($event)}}},[(_vm.deliverable.reporting_status)?_c('span',{attrs:{"data-forcewrap":"","data-tooltip":_vm.deliverable.reporting_status.message,"data-position":"bottom center","data-variation":"mini"}},[_c('i',{staticClass:"icon",class:_vm.deliverable.reporting_status.icon})]):_c('span',[_c('i',{staticClass:"icon question mark"})])]):_vm._e()]:_vm._e()],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"ui placeholder"},[_c('div',{staticClass:"image header"},[_c('div',{staticClass:"line"}),_vm._v(" "),_c('div',{staticClass:"line"})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image"},[_c('div',{staticClass:"ui placeholder"},[_c('div',{staticClass:"square image"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extra content"},[_c('div',{staticClass:"ui placeholder"},[_c('div',{staticClass:"short line"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"ui small text"},[_c('a',{attrs:{"href":"https://help.influencekit.com/en/articles/2138024-understanding-report-metrics","target":"_blank"}},[_c('i',{staticClass:"icon question mark circle"}),_vm._v("\n             What do these mean?\n           ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ui label left status corner search stat"},[_c('i',{staticClass:"icon yellow circular seo-trophy inverted"})])}]

export { render, staticRenderFns }