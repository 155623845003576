// import "idempotent-babel-polyfill";
// import Vue from "vue/dist/vue.esm";
// import wrap from "@vue/web-component-wrapper";
// import Avatar from "components/avatar.vue";

// const AvatarComponent = {
//   components: { Avatar },
//   props: ["username", "src", "size"],
//   template:
//     "<avatar class='ui avatar image' :username='username' :src='src' :size='size'></avatar>"
// };
// const VueAvatar = wrap(Vue, AvatarComponent);
// window.customElements.define("vue-avatar", VueAvatar);
