<template>
  <div class="ui padding-y-small form tiny">
    <h5>
      <span v-if="quickFiltersActive" class="right floated clearing">
        <a class="ui mini button" @click="clearQuickFilters">Clear filters</a>
      </span>

      Quick Filters
    </h5>

    <div class="ui very compact grid padding-x-zero margin-top-small">
      <div v-if="calendars.length > 1 && showCalendarDropdown" class="ui row">
        <div class="three wide column field padding-top-tiny">
          <label>Calendars</label>
        </div>

        <div class="ui thirteen wide column padding-left-small field">
          <select
            ref="calendarsSelection"
            @change="calendarsChanged"
            class="ui search selection dropdown multiple"
            multiple
            data-clearable="true"
          >
            <option value="">Select Calendars</option>
            <option
              v-for="option in calendarsAsOptions"
              :value="option.value"
              :selected="selectedCalendars.includes(option.value)"
            >
              <div
                class="ui left floated red empty circular label"
                :style="{
                  'background-color': option.color + ' !important',
                }"
              />

              {{ option.label }}
            </option>
          </select>
        </div>
      </div>

      <div v-show="categories.length > 1" class="ui row">
        <div class="three wide column field padding-top-tiny">
          <label> Category</label>
        </div>
        <div class="ui thirteen wide column padding-left-small field">
          <select
            v-model="selectedCategory"
            class="ui search selection dropdown"
            data-clearable="true"
          >
            <option value="">All Categories</option>
            <option v-for="option in categoriesAsOptions" :value="option.value">
              <div
                class="ui left floated red empty circular label"
                :style="{ 'background-color': option.color + ' !important' }"
              />

              {{ option.label }}
            </option>
          </select>
        </div>
      </div>

      <div v-show="users.length > 1" class="ui row">
        <div class="three wide column field padding-top-tiny">
          <label>User</label>
        </div>
        <div class="ui thirteen wide column padding-left-small field">
          <select
            v-model="selectedUser"
            class="ui search selection dropdown"
            data-clearable="true"
          >
            <option value="">All Users</option>
            <option v-for="option in usersAsOptions" :value="option.value">
              <avatar
                :username="option.user_login"
                :src="option.user_gravatar"
                size="20"
                class="ui image avatar"
              />{{ option.label }}
            </option>
          </select>
        </div>
      </div>

      <div v-show="showDateRangeSelection" class="ui row">
        <div class="three wide column field padding-top-tiny">
          <label>Date Range</label>
        </div>
        <div
          ref="quickDateSelection"
          class="ui thirteen wide column padding-left-small field"
        >
          <select
            v-model="selectedQuickDate"
            class="ui selection dropdown"
            data-clearable="true"
          >
            <option value="">Select a range</option>
            <option v-for="option in quickDateSelections" :value="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="ui divider margin-top-medium" />

    <h5 class="margin-y-zero pointer" @click.stop="toggleAdvancedFilters">
      <i
        class="icon angle fitted"
        :class="{
          right: !advancedFiltersExpanded,
          down: advancedFiltersExpanded,
        }"
      />

      Advanced Filters
    </h5>

    <div
      v-if="Object.keys(fieldFilters).length > 0"
      v-show="advancedFiltersExpanded"
      class="ui very compact grid padding-x-zero margin-top-small"
    >
      <div v-for="field in filterableFields" :key="field.title" class="ui row">
        <div class="three wide column field">
          <label>{{ field.title }}</label>
        </div>

        <div class="ui thirteen wide column padding-left-small">
          <div class="ui two column very compact grid">
            <!-- text -->
            <template v-if="field.filter == 'text'">
              <div class="column field">
                <select
                  v-model="fieldFilters[field.filterField].operator"
                  class="ui selection dropdown"
                >
                  <option value="eq">equals</option>
                  <option value="cont">contains</option>
                  <option value="not_cont">does not contain</option>
                </select>
              </div>

              <div class="column field">
                <input
                  v-model.lazy="fieldFilters[field.filterField].value"
                  v-debounce="300"
                />
              </div>
            </template>

            <!-- dropdown -->
            <template v-if="Array.isArray(field.filter)">
              <div class="column field">
                <select
                  v-model="fieldFilters[field.filterField].operator"
                  class="ui selection dropdown"
                >
                  <option value="eq">is</option>
                  <option value="not_eq">is not</option>
                </select>
              </div>

              <div class="column field">
                <select
                  v-model="fieldFilters[field.filterField].value"
                  class="ui selection dropdown"
                  data-clearable="true"
                >
                  <option value="" />
                  <option v-for="option in field.filter" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </template>

            <!-- date range -->
            <template v-if="field.filter == 'date-range'">
              <date-range-rule
                v-model="fieldFilters[field.filterField].value"
                class="sixteen wide"
              />
            </template>

            <!-- date -->
            <template v-if="field.filter == 'date'">
              <div class="column field">
                <select
                  v-model="fieldFilters[field.filterField].operator"
                  class="ui selection dropdown"
                >
                  <option value="gt">greater than</option>
                  <option value="lt">less than</option>
                </select>
              </div>

              <div id="start_date" class="ui calendar column field">
                <input
                  v-model="fieldFilters[field.filterField].value"
                  v-debounce="300"
                  class="ui input"
                  placeholder="yyyy-mm-dd"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="advancedFiltersActive"
      class="right floated clearing margin-top-small"
    >
      <a class="ui button mini" @click="clearFieldFilters">Clear filters</a>
    </div>
  </div>
</template>

<script>
import store from "store";
import { mapState, mapGetters } from "vuex";
import EventBus from "components/eventbus";
import debounce from "v-debounce";
import DateRangeRule from "components/queryBuilder/DateRangeRule.vue";
import Dropdown from "components/dropdown.vue";
import Avatar from "components/avatar.vue";
import { compact, map } from "lodash";

const { $ } = window;

export default {
  store,
  components: { Dropdown, Avatar, DateRangeRule },
  directives: { debounce },
  props: {
    fieldDefinitions: {
      type: Array,
      default() {
        return [];
      },
    },
    admin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const $this = this;
    return {
      advancedFiltersExpanded: $this.advancedFiltersActive || false,
    };
  },

  computed: {
    ...mapState("calendar", {
      calendars: "calendars",
    }),
    ...mapState("route", {
      path: "path",
    }),
    ...mapGetters("calendar", {
      categories: "categories",
      users: "users",
      selectedCalendarObjects: "selectedCalendarObjects",
      filtersActive: "filtersActive",
      quickFiltersActive: "quickFiltersActive",
      advancedFiltersActive: "advancedFiltersActive",
      showCalendarDropdown: "showCalendarDropdown",
    }),
    showDateRangeSelection() {
      return this.path != "/";
    },
    quickDateSelections() {
      return [
        { label: "Anytime", value: "" },
        { label: "This month", value: "this_month" },
        { label: "Next month", value: "next_month" },
        { label: "Previous month", value: "prev_month" },
        { label: "This week", value: "this_week" },
        { label: "Next week", value: "next_week" },
        { label: "Previous week", value: "prev_week" },
      ];
    },

    // showCalendarDropdown() {
    //   return !window.location.pathname.includes("/calendars/");
    // },

    selectedCalendars: {
      get() {
        return this.$store.state.calendar.selectedCalendars;
      },
      set(value) {
        this.$store.commit("calendar/updateSelectedCalendars", value);
      },
    },

    selectedCategory: {
      get() {
        return this.$store.state.calendar.selectedCategory;
      },
      set(value) {
        this.$store.commit("calendar/updateSelectedCategory", value);
      },
    },

    selectedUser: {
      get() {
        return this.$store.state.calendar.selectedUser;
      },
      set(value) {
        this.$store.commit("calendar/updateSelectedUser", value);
      },
    },

    selectedQuickDate: {
      get() {
        return this.$store.state.calendar.selectedQuickDate;
      },
      set(value) {
        this.$store.commit("calendar/updateSelectedQuickDate", value);
        if (value) {
          //clear the advanced date range filter if selected
          this.$store.dispatch("calendar/clearFilter", "starts_at");
        } else {
          //reset the dropdown?
          $(this.$refs.quickDateSelection)
            .find(".dropdown")
            .dropdown("restore defaults");
        }
      },
    },

    usersAsOptions() {
      return this.users.map((u) => ({
        label: u.login,
        value: u.id,
        user_gravatar: u.gravatar,
        user_login: u.login,
      }));
    },
    categoriesAsOptions() {
      return this.categories.map((c) => ({
        label: c.name,
        value: c.id,
        color: c.color,
      }));
    },
    calendarsAsOptions() {
      return this.calendars.map((c) => ({
        label: c.name,
        value: c.id,
        color: c.color,
      }));
    },

    filterableFields() {
      let query = {};
      this.fieldDefinitions
        .filter((field) => {
          return field.visible && field.filter;
        })
        .forEach((field) => {
          let operator = "cont";
          if (Array.isArray(field.filter)) {
            operator = "eq";
          }
          if (field.filter === "date") {
            operator = "gt";
          }
          if (field.filter === "date-range") {
            operator = "date_between";
          }

          query[field.filterField] = {
            operator,
            filterField: field.filterField,
            title: field.title,
            filter: field.filter,
          };
        });
      return query;
    },
    fieldFilters: {
      get() {
        return this.$store.state.calendar.fieldFilters;
      },
      set(value) {
        this.$store.commit("calendar/updateFieldFilters", value);
      },
    },
  },
  watch: {
    fieldFilters: {
      handler(filters) {
        if (filters.starts_at.value) {
          // clear quick date selection if date range is chosen
          this.selectedQuickDate = null;
        }
      },
      deep: true,
    },
    // query: {
    //   handler(val) {
    //     this.fieldFilters = val;
    //   },
    //   deep: true
    // }
  },
  mounted() {
    const $this = this;
    this.fieldFilters = this.filterableFields;
    this.$nextTick(() => {
      $this.initDropdowns();
    });
  },
  updated() {
    this.$nextTick(() => {
      $(this.$el).find(".dropdown").dropdown("refresh");
    });
  },
  methods: {
    toggleAdvancedFilters() {
      this.advancedFiltersExpanded = !this.advancedFiltersExpanded;
    },
    clearFieldFilters() {
      this.$store.dispatch("calendar/clearFieldFilters");
      this.resetDropdowns();
    },
    clearQuickFilters() {
      this.$store.dispatch("calendar/clearQuickFilters");
      this.resetDropdowns();
    },
    resetDropdowns() {
      // $(this.$el).find(".dropdown").dropdown("restore defaults", true);
      $(this.$el).find(".dropdown").dropdown("clear", true);
    },
    initDropdowns() {
      $(this.$el)
        .find(".dropdown")
        .each((i, el) => {
          const elm = $(el);
          if (!elm.data("dropdown-loaded")) {
            elm
              .dropdown({
                fullTextSearch: true,
                clearable: elm.data("clearable") || false,
                direction: elm.data("direction") || "auto",
                allowAdditions: elm.data("additions") || false,
                placeholder: elm.data("placeholder") || "auto",
                forceSelection: false,
              })
              .data("dropdown-loaded", true);
          }
        });
    },
    calendarsChanged(event) {
      const value = $(this.$refs.calendarsSelection).dropdown("get value");
      const val = compact(value);

      this.selectedCalendars = map(val, Number);

      localStorage.setItem(
        "selectedCalendars",
        JSON.stringify(this.selectedCalendars)
      );
    },
    categoryChanged(value) {
      this.selectedCategory = value;
    },
    userChanged(value) {
      this.selectedUser = value;
    },
    quickDateChanged(value) {
      this.selectedQuickDate = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
