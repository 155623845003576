import { Carousel, Slide } from "vue-carousel";
import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";

window.initCarousels = function () {
  if (document.querySelector(".vue-carousel")) {
    document.querySelectorAll(".vue-carousel").forEach((el) => {
      new Vue({
        el: el,
        components: {
          Carousel,
          Slide,
        },
      });
    });
  }
};

document.addEventListener("turbo:load", function () {
  // window.initCarousels();
});
