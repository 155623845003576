<template lang="html">
  <div
    v-if="hasBeenLoaded"
    :id="`profileCard${deliverable.id}`"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
      intersection: {
        rootMargin: '1000px',
      },
    }"
    class="ui profile fluid card padding-bottom-medium"
    :class="[
      `${deliverable.inferred_type.replace('_profile', '')}-card`,
      { fluid },
    ]"
  >
    <div class="content card-header">
      <div class="ui one column centered grid padding-top-small">
        <div v-if="hasStats" class="column center aligned">
          <a :href="deliverable.url" target="_blank" class="icon-head">
            <i class="ui large icons">
              <img
                v-if="deliverable"
                :src="deliverable.provider_picture"
                class="ui circular rounded image bordered white background"
                height="55"
                width="55"
              />
              <i
                class="icon tiny colored bottom right circular corner"
                :class="
                  inferredTypeAsIconClass(
                    deliverable.inferred_type.replace('_profile', '')
                  )
                "
              />
            </i>
          </a>
          <br />
          <h3>
            <a :href="deliverable.url">
              <span class="ui black underlined text">
                {{ deliverable.provider_username | capitalize }}
              </span>
            </a>
          </h3>
        </div>

        <div class="column center aligned">
          <div v-if="hasStats" ref="statisticsDetails" class="ui wide popup">
            <h3 class="ui header margin-top-zero">
              {{
                inferredTypeAsIconClass(
                  deliverable.inferred_type.replace("_profile", "")
                ) | capitalize
              }}
            </h3>

            <div
              v-for="group in statisticsDetails"
              v-if="group.details && group.details.length > 0"
              class="ui statistic mini margin-left-zero left aligned"
            >
              <div class="label capitalize">
                {{ group.total.label | humanize }}
              </div>
              <div class="ui list margin-top-tiny margin-bottom-small">
                <div v-for="detail in group.details" class="ui item">
                  {{ detail }}
                </div>
              </div>
            </div>

            <span class="ui small text">
              <a
                href="https://help.influencekit.com/en/articles/2138024-understanding-report-metrics"
                target="_blank"
              >
                <i class="icon question mark circle" />
                What do these mean?
              </a>
            </span>
          </div>

          <div class="ui equal width column compact grid center aligned">
            <div class="column center aligned" v-if="!hasStats">
              <div class="ui info message">
                No stats are available for this profile ({{ deliverable.url }})
                <br />
                {{ deliverable.reporting_status.message }}
              </div>
            </div>

            <div
              v-for="statisticValue in profileStats"
              v-if="
                statisticValue.total.value != 0 &&
                statisticValue.total.value != '00:00:00'
              "
              class="ui column mini statistic"
            >
              <div class="value">
                <span>{{
                  statisticValue.total.value | abbreviateNumber(10000, 1)
                }}</span>
              </div>

              <div class="label" style="white-space: nowrap">
                <span>{{
                  statisticValue.total.label.replace("profile_", "") | humanize
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import store from "store";
import Vue from "vue/dist/vue.esm";
import VueObserveVisibility from "vue-observe-visibility";
import ResizeObserver from "resize-observer-polyfill";
import "functions/filters";

Vue.use(VueObserveVisibility);

const $ = window.jQuery;

export default {
  store,
  components: {},
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
    initialDeliverable: Object,
    loadDataRemotely: {
      type: Boolean,
      default: false,
      description: "Load data for this card remotely",
    },
    lazyLoad: {
      type: Boolean,
      default: true,
    },
    canShowMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const $this = this;
    return {
      modalCardComponent: null,
      modalCardData: null,
      isVisible: $this.lazyLoad ? false : true,
      favicon: false,
      showMore: false,
      hasBeenLoaded: !$this.loadDataRemotely,
      deliverable: { ...($this.initialDeliverable || {}) },
      iframelyData: null,
      refreshingStats: false,
    };
  },
  computed: {
    ...mapGetters("deliverable", ["inferredTypeAsIconClass"]),
    editable() {
      return this.$store.state.report.editable;
    },
    showStatusLabel() {
      return (
        this.deliverable.last_error_at ||
        (this.deliverable.reporting_status &&
          (this.deliverable.reporting_status.status === "error" ||
            this.deliverable.reporting_status.status === "unknown"))
      );
    },
    profileStats() {
      if (!this.deliverable.statisticsValues) {
        return false;
      }
      return _.reject(this.deliverable.statisticsValues, {
        type: "search_position",
      });
    },
    hasStats() {
      return (
        this.profileStats &&
        this.profileStats.reduce((sum, stat) => stat.total.value + sum, 0) > 0
      );
    },
    statisticsDetails() {
      return this.profileStats
        .map((s) => {
          return { name: s.type, details: s.details, total: s.total };
        })
        .flat();
    },
    incomplete() {
      return (
        !this.deliverable.url &&
        !this.deliverable.description &&
        this.deliverable.statisticsValues &&
        this.deliverable.statisticsValues.length < 1
      );
    },
    iframelyUrl() {
      let url = `https://cdn.iframe.ly/api/iframe?&url=${encodeURIComponent(
        this.urlOrExpandedUrl
      )}&key=691c90188e4da53cfe4eaf8b181bcb57`;
      if (!window.location.search.includes("eagerLoadEmbeds")) {
        url = `${url}&lazy=1`;
      }
      return url;
    },
    iframelyApi() {
      return `https://cdn.iframe.ly/api/iframely?url=${encodeURIComponent(
        this.urlOrExpandedUrl
      )}&key=691c90188e4da53cfe4eaf8b181bcb57`;
    },
    urlOrExpandedUrl() {
      return this.deliverable.url;
    },
    hostFromUrl() {
      const host = new URL(this.deliverable.url).host.replace("www.", "");
      return host;
    },
    isPodcastType() {
      return ["art19"].includes(this.deliverable.inferred_type);
    },
    isEmailType() {
      return [
        "mailchimp",
        "aweber",
        "convertkit",
        "constant_contact",
        "sendfox",
        "madmimi",
        "activecampaign",
        "klaviyo",
      ].includes(this.deliverable.inferred_type);
    },
    isPushNotificationType() {
      return ["one_signal", "push_engage"].includes(
        this.deliverable.inferred_type
      );
    },
    isInstagramStoryType() {
      return ["instagram_stories"].includes(this.deliverable.inferred_type);
    },
    hideEmailIcon() {
      return this.isEmailType && this.hasDescriptionOrCaptionOrUploads;
    },
    hasDescriptionOrCaptionOrUploads() {
      return (
        this.deliverable.provider_caption ||
        (this.deliverable.description && this.deliverable.description != "") ||
        (this.deliverable.uploads && this.deliverable.uploads.length > 0)
      );
    },
  },
  watch: {
    initialDeliverable: {
      deep: true,
      handler(val) {
        let clone = { ...val }; // val is a reference to the prop,
        delete clone.statisticsValues; // don't mutate it here, or else we have weird side effects
        this.deliverable = { ...this.deliverable, ...clone };
      },
    },
    isVisible(val, oldVal) {
      if (val && val != oldVal) {
        this.$nextTick(() => {
          this.initCard();
        });
      }
    },
  },
  mounted() {
    const $this = this;

    $this.$nextTick(() => {
      if ($this.loadDataRemotely) {
        $this.loadData();
      } else {
        $this.emitLoaded();
      }

      if (!this.lazyLoad) {
        $this.initCard();

        if (window.location.search.includes("eagerLoadEmbeds")) {
          $this.visibilityChanged(true);
        }
      }
    });
  },
  methods: {
    visibilityChanged(isVisible) {
      this.isVisible = this.isVisible || isVisible;
    },
    // getIframelyResponse() {
    //   if (!this.iframelyData) {
    //     $.get(this.iframelyApi).done((data) => {
    //       this.iframelyData = data;
    //       if (data.links && data.links.thumbnail) {
    //         this.favicon = data.links.thumbnail[0].href;
    //       }
    //       // if (this.deliverable.inferred_type === "pinterest") {
    //       //   $(this.$el)
    //       //     .find(".iframely-responsive")
    //       //     .removeClass("iframely-responsive");
    //       // }

    //       // this.$nextTick(() => {
    //       //   this.setShowMore();
    //       // });
    //     });
    //   }
    // },
    setShowMore() {
      if (this.$el) {
        const cardContent = $(this.$el).find(".card-content")[0];
        this.showMore =
          cardContent && cardContent.scrollHeight > cardContent.clientHeight;
      }
    },
    observeEmbedResize() {
      const ro = new ResizeObserver(this.setShowMore);

      const embedEl = $(this.$el).find(
        ".embed-wrapper, .iframely-responsive, .iframely-embed, .card-content img"
      )[0];
      if (embedEl) {
        ro.observe(embedEl);
      }
    },
    loadData() {
      $.read(`/deliverables/${this.deliverable.id}.json`).done((data) => {
        this.hasBeenLoaded = true;
        Object.assign(this.deliverable, data);
        // this.$set(this.deliverable, "statisticsValues", data.statisticsValues);
        this.$forceUpdate();
        this.emitLoaded();
      });
    },
    refreshStats() {
      $.read(`/deliverables/${this.deliverable.id}/refresh`);
      this.refreshingStats = true;
    },
    emitLoaded() {
      this.$emit("cardLoaded", this.deliverable);
    },
    initPopups() {
      $(this.$el)
        .find(".card-header,.statistics-head")
        .each(function () {
          console.log(this);
          $(this).popup({
            closable: false,
            observeChanges: true,
            popup: $(this).find(".popup"),
            hoverable: true,
            position: "right center",
            forcePosition: true,
            lastResort: "right center",
            movePopup: true,
            target: $(this),
            transition: "fade",
            delay: {
              show: 250,
              hide: 50,
            },
          });
        });

      $(this.$el)
        .find(".search.stat")
        .popup({
          observeChanges: false,
          inline: true,
          hoverable: true,
          position: "bottom left",
          boundary: $(this.$el).find(".card-content"),
          lastResort: true,
          offset: 8,
        });
    },
    initHeartbeat() {
      if (typeof deliverablesChannel !== "undefined") {
        const $this = this;
        window.deliverablesChannel(
          $this.deliverable.id,
          "deliverable-refresh-queued",
          function (data) {
            $this.deliverable.reporting_status = data.reporting_status;
          }
        );
        window.deliverablesChannel(
          $this.deliverable.id,
          "deliverable-updated",
          function () {
            $this.loadData();
            $this.refreshingStats = false;
          }
        );
      }

      // if (typeof hb !== "undefined") {
      //   const channel = hb.pusher.subscribe(
      //     `deliverable-${this.deliverable.id}`
      //   );

      //   channel.bind("deliverable-refresh-queued", (data) => {
      //     this.deliverable.reporting_status = data.reporting_status;
      //   });
      //   channel.bind("deliverable-updated", () => {
      //     this.loadData();
      //     this.refreshingStats = false;
      //   });
      // }
    },
    loadIframelyEmbed() {
      if (this.$refs.iframelyLink) {
        if (typeof window.iframely !== "undefined") {
          window.iframely.load(this.$refs.iframelyLink);
        }
      }
    },
    initCard() {
      this.initPopups();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "vue-resize/dist/vue-resize.css";

[data-tooltip][data-forcewrap]:after {
  white-space: normal;
  z-index: 22;
}

div.ui[class*="9:16"].embed {
  padding-bottom: 177%;
}

div.ui.embed.scrolling {
  overflow: auto !important;
}

.extra.content {
  word-wrap: break-word;

  a.link span span.provider {
    text-transform: capitalize;
  }
}

.ui.right.corner.label {
  z-index: 20;
}

.status.label {
  border-color: transparent;
  a {
    i.icon {
    }
    color: rgba(0, 0, 0, 0.4);
  }

  &.search.stat {
    top: -9px;
    left: 2px;
    i {
      top: 0;
      left: 0;
      font-size: 20px;
      box-shadow: 0 8px 16px -4px rgba(0, 0, 0, 0.32) !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.ui.profile.card {
  .column.six.wide {
    min-width: 70%;
  }
}

.ui.profile.card .card-content {
  display: flex;
  flex-direction: column;
}

.ui.profile.card.youtube-card .content.card-header {
  background: linear-gradient(180deg, #ff0000 8px, #fff 8px);
}
.ui.profile.card.facebook-card .content.card-header {
  background: linear-gradient(180deg, #476eb1 8px, #fff 8px);
}
.ui.profile.card.pinterest-card .content.card-header {
  background: linear-gradient(180deg, #c0001d 8px, #fff 8px);
}
.ui.profile.card.rss-card .content.card-header,
.ui.profile.card.url-card .content.card-header,
.ui.profile.card.google-card .content.card-header {
  background: linear-gradient(180deg, lighten(#f4806f, 5%) 8px, #fff 8px);
}
.ui.profile.card.twitter-card .content.card-header {
  background: linear-gradient(180deg, #009dea 8px, #fff 8px);
}

.ui.profile.card.mailchimp-card {
  .content.card-header {
    background: linear-gradient(#239ab9 8px, #fff 8px);
  }
}

.ui.profile.card.aweber-card {
  .content.card-header {
    background: linear-gradient(180deg, #2072b9 8px, #fff 8px);
  }
}

.ui.profile.card.bitly-card {
  .content.card-header {
    background: linear-gradient(180deg, rgba(97, 179, 222, 0.9) 8px, #fff 8px);
  }
}

.ui.profile.card.convertkit-card {
  .content.card-header {
    background: linear-gradient(#fb6970 8px, #fff 8px);
  }
}
.ui.profile.card.constant_contact-card {
  .content.card-header {
    background: linear-gradient(#1856ed 8px, #fff 8px);
  }
}

.ui.profile.card.klaviyo-card {
  .content.card-header {
    background: linear-gradient(#24ad69 8px, #fff 8px);
  }
}

.ui.profile.card.sendfox-card {
  .content.card-header {
    background: linear-gradient(#fd6655 8px, #fff 8px);
  }
}

.ui.profile.card.tik_tok-card {
  .content.card-header {
    background: linear-gradient(#ee1d52 8px, #fff 8px);
  }
}

.ui.profile.card.activecampaign-card {
  .content.card-header {
    background: linear-gradient(rgba(57, 110, 226, 1) 8px, #fff 8px);
  }
}

.ui.profile.card.linkedin-card {
  .content.card-header {
    background: linear-gradient(#0976b4 8px, #fff 8px);
  }
}

.ui.profile.card.madmimi-card {
  .content.card-header {
    background: linear-gradient(#86c3ea 8px, #fff 8px);
  }
}

.ui.profile.card.instagram_stories-card {
  .content.card-header {
    background: linear-gradient(#ac279f 8px, #fff 8px);
  }
}

.ui.profile.card.one_signal-card,
.ui.profile.card.push_engage-card {
  .content.card-header {
    background: linear-gradient(#e24b4d 8px, #fff 8px);
  }
}

.ui.profile.card.art19-card {
  .content.card-header {
    background: linear-gradient(#1373b6 8px, #fff 8px);
  }
}

.ui.profile.card.instagram-card {
  .content.card-header {
    background: linear-gradient(#ac279f 8px, #fff 8px);
  }
}

// @media (min-width: 1200px) {
//   .ui.profile.card > .content.card-header .ui.statistic > .value,
//   .ui.cards > .card > .content.card-header .ui.statistic > .value,
//   .ui.profile.card > .content.card-header .ui.statistics .statistic > .value,
//   .ui.cards > .card > .content.card-header .ui.statistics .statistic > .value {
//     font-size: 2.29rem !important;
//   }
// }

.ui.card.profile {
  box-shadow: 0px 24px 32px 8px rgba(0, 0, 0, 0.04);

  > .ui.right.corner.label {
    margin-top: 8px;
  }

  // .card-header .statistics {
  //   margin-top: 1.5rem;
  // }
  // .header .statistic {
  //   max-width: 56% !important;
  //   .value {
  //     color: #0d0d0d;
  //     font-weight: bold;
  //     line-height: 40px;
  //   }
  //   .label {
  //     color: #545454;
  //     font-size: 12px;
  //     letter-spacing: 0.6px;
  //     line-height: 12px;
  //   }
  // }

  .statistics-head {
    flex-grow: 0 !important;
  }

  .content {
    overflow: hidden;
  }
  > .content.card-header {
    position: relative;
    padding: 1em 25px;
    color: #0d0d0d;
    background: linear-gradient(#e5b586 8px, #fff 8px);
    flex-grow: 0;
    min-height: 100px;
    overflow: visible;

    h3 {
      display: inline-block;
    }
    .icon-head {
      display: inline-block;
      margin-right: 1em;
    }
  }

  > .content:after {
    display: none !important;
  }

  .ui.statistics {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .ui.wide.popup {
    width: 205px !important;
    top: 100px !important;
    border-radius: 0.42857143rem !important;
    &:before {
      top: 32px !important;
    }
    .statistic {
      width: 100%;
      display: block;
    }
  }

  &:hover {
    .ui.statistics-head .statistic {
      .value {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-style: dashed;
        text-underline-position: under;
        text-decoration-color: rgba(0, 0, 0, 0.4);
      }
    }
  }

  .ui.statistics-head .statistic {
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;

    > .value {
      color: #0d0d0d !important;
      font-weight: 600 !important;
      font-size: 18px !important;
      white-space: nowrap;
      line-height: 1.8em;
    }
    .label {
      color: #545454 !important;
      font-size: 10px;
      line-height: 1.2em;
      font-weight: 400;
    }
  }

  .card-content {
    position: relative;
    overflow: hidden;
    height: 186px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
  }

  .card-content > img {
    max-width: 100%;
    height: auto;
  }

  .extra {
    text-align: center;
    position: relative !important;

    .link {
      font-weight: 300;
      font-size: 1rem;
      color: #f4806f !important;
      letter-spacing: 0.05em;
      &:hover {
        opacity: 0.8;
      }
      .icon {
        margin-right: 15px;
      }
    }

    .link-icon {
      color: #f4806f !important;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      top: 50%;
      right: 40px;
      position: absolute;
      font-size: 1.8rem;
      line-height: 1em;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .opener {
    display: block;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4)
    );
    height: 48px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s linear !important;
    :hover {
      background: rgba(0, 0, 0, 0.4);
    }

    .icon {
      color: #ffffff;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      top: 50%;
      right: 0;
      position: absolute;
      font-size: 2rem;
      line-height: 1em;
    }
  }

  .content.center.aligned.pagination-label {
    color: #878787;
    font-weight: 600;
    flex-grow: 0;
    flex-shrink: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// .ui.profile.card .card-content {
//   height: 100%;
//   max-height: 600px;
// }

@media (min-width: 768px) {
  .ui.profile.card .card-content {
    height: 190px;
  }
}
@media (min-width: 1200px) {
  .ui.profile.card .card-content {
    height: 338px;
  }
}

@media (min-width: 768px) {
  .ui.profile.card .extra {
    padding: 1.5rem !important;
  }
}
@media (min-width: 768px) {
  .ui.profile.card .extra .link {
    font-size: 1.428rem;
  }
}
</style>
