import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  closeModals() {
    window.$("#suiModal").modal("hide");
  }

  connect() {
    window.initTroubleshooters(); //probably should be its own controller...
  }
}
