<template>
  <div>
    <div
      v-for="(taskGroup, groupIndex) of groupedTasks"
      :key="`${taskGroup.label}-${groupIndex}`"
      class="ui segment"
      :class="{ unassigned: !taskGroup.label }"
    >
      <div v-if="taskGroup.label" class="ui tiny header margin-bottom-small">
        <a
          v-if="taskGroup.key === 'event'"
          @click.prevent.stop="openEvent(taskGroup.items[0])"
        >
          {{ taskGroup.label }}
        </a>
        <span v-else>{{ taskGroup.label }}</span>

        <div
          class="ui simple dropdown right floated hovering"
          v-if="users.length > 1"
        >
          <i class="icon grey vertical ellipsis padding-left-small"></i>
          <div class="menu icon buttons left">
            <a class="item" @click.stop.prevent="removeTasks(taskGroup.items)">
              <i class="icon trash"></i>
              Delete {{ taskGroup.items.length | pluralize("task") }}
            </a>

            <div class="divider"></div>

            <div class="header">
              <i class="icon exchange"></i>
              Assign all to:
            </div>
            <div
              class="item"
              :key="`${user.id}-suggested-task-selection`"
              v-for="user in users.filter((u) => u.login != taskGroup.label)"
              @click.stop.prevent="assignTasksToUser(taskGroup.items, user)"
            >
              {{ user.login }}
            </div>
          </div>
        </div>
      </div>

      <div class="task-list ui list">
        <task-item
          v-for="(task, index) of taskGroup.items"
          :key="`${taskGroup.key || 'list'}-${groupIndex}-task-${
            task.id || index
          }`"
          :grouping="taskGroup.key"
          :parent-event="parentEvent"
          :task="task"
          :name="task.name"
          :users="users"
          @selectUser="selectUser"
          @openEvent="openEvent"
        />
      </div>
    </div>

    <task-input
      v-if="parentEvent && allowAdditions"
      ref="newTaskInput"
      placeholder="Add a task ..."
      :task="newTask"
      :users="users"
      :allow-cancel="false"
      :parent-event="parentEvent"
    />

    <paginate
      v-if="pagination.totalResults > 0"
      :key="`pagination-${pagination.currentPage}`"
      :initial-page="pagination.currentPage"
      :per-page="pagination.perPage"
      :total-results="pagination.totalResults"
      @click="loadPage"
    />
  </div>
</template>

<script>
import _ from "lodash";
import filters from "functions/FilterFunctions";
import TaskItem from "./TaskItem.vue";
import TaskInput from "./TaskInput.vue";
import { mapState } from "vuex";
import store from "store";
import Paginate from "components/pagination.vue";

export default {
  store,
  components: {
    TaskItem,
    Paginate,
    TaskInput,
  },
  props: {
    parentEvent: {
      type: Object,
      default: null,
    },
    newTask: Object,
    allowAdditions: Boolean,
    tasks: Array,
    group: Object,
    filter: String,
    users: Array,
  },
  data() {
    return {
      // isEditing: false
    };
  },
  methods: {
    assignTasksToUser(tasks, user) {
      if (
        window.confirm(
          `We'll send ${tasks.length} notification emails to ${user.login}. Sound good?`
        )
      ) {
        tasks.forEach((task) => {
          task.user = user;
          task.user_login = user.login;
          task.user_id = user.id;

          this.$store.dispatch("task/update", task);
        });
      }
    },
    removeTasks(tasks) {
      if (window.confirm("Are you sure? There's no undo.")) {
        tasks.forEach((task) => {
          this.$store.dispatch("task/destroy", task);
        });
      }
    },
    loadPage(page) {
      this.$emit("loadPage", page);
    },
    selectUser(user) {
      this.$emit("selectUser", user);
    },
    updateTask(task) {
      this.$emit("updateTask", task);
    },
    addTask(task) {
      this.$emit("addTask", task);
    },
    addTaskInPlace(task, addedTask) {
      this.$emit("addTaskInPlace", task, addedTask);
    },
    openEvent(task) {
      window.EventBus.$emit("openEventModal", this.eventUrl(task));
    },
    eventUrl(task) {
      return `/calendars/${task.calendar_id}/events/${task.event.id}?eventTab=tasks`;
    },
  },
  computed: {
    ...mapState("task", {}),
    pagination() {
      return this.$store.getters["task/pagination"](this.parentEvent);
    },
    isEditing() {
      return !!_.find(this.visibleSavedTasks, ["editing", true]);
    },
    visibleSavedTasks() {
      return this.visibleTasks.filter((t) => t.id);
    },
    groupedTasks() {
      if (this.isEditing && this.cachedGroupedTasks) {
        return this.cachedGroupedTasks;
      }

      const { key, label, sort } = this.group;
      let grouped = {};

      this.visibleTasks.forEach((task) => {
        if (task[key]) {
          grouped[task[key].id] = grouped[task[key].id] || {
            items: [],
            label: task[key][label],
            parentObject: task[key],
            key,
            sort,
          };
          grouped[task[key].id].items.push(task);
        } else {
          grouped.ungrouped = grouped.ungrouped || {
            items: [],
            label: "", //`Unassigned` // ${key}`
          };
          grouped.ungrouped.items.push(task);
        }
      });

      if (key && sort) {
        grouped = _.mapValues(grouped, (o) => {
          o.items = _.orderBy(
            o.items,
            (item) => {
              return item[sort] || "1900-01-01";
            },
            ["desc"]
          );
          return o;
        });
        grouped = _.orderBy(
          grouped,
          (o) => {
            return o.parentObject ? o.parentObject[sort] : "";
          },
          "desc"
        );
      } else if (sort) {
        // const sorted = _.orderBy(
        //   this.visibleTasks,
        //   item => {
        //     return item[sort] || "1900-01-01";
        //   },
        //   ["asc"]
        // );
        // grouped.ungrouped = {
        //   items: sorted
        // };
      }

      this.cachedGroupedTasks = grouped;
      return grouped;
    },
    visibleTasks() {
      return this.tasks;
      // return filters[this.filter](this.tasks);
    },
  },
  watch: {
    visibleTasks(newVal, oldVal) {
      if (newVal.length < oldVal.length) {
        this.cachedGroupedTasks = undefined;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.task-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.group.unassigned {
  border-top: 1px solid #ccc;
  margin-top: 1em;
  &:first-child {
    margin-top: 0;
    border-top: none;
  }
}
</style>
