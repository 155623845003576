import { Controller } from "@hotwired/stimulus";

const $ = window.jQuery;

// Connects to data-controller="media-kit"
export default class extends Controller {
  connect() {
    this.startEditor();
  }

  resizeFrame() {
    let wrapperLeft = $("#media_kit_form .frame_wrapper").offset().left;
    let sidebarLeft = $(".media_kit.sidebar").offset().left;

    let visibleWidth = sidebarLeft - wrapperLeft;
    let totalWidth = $("body").width() - wrapperLeft;

    let scalingFactor = visibleWidth / totalWidth;

    console.log("Scaling factor:", scalingFactor);

    $("#media_kit_form .frame_wrapper").css({
      transform: `scale(${scalingFactor})`,
      transformOrigin: "0 0",
    });
    $("#media_kit_form iframe")
      .contents()
      .find("html")
      .css({
        transform: `scale(${scalingFactor})`,
        transformOrigin: "center 0",
      });

    let bodyHeight = $("body").height();
    let wrapperTop = $("#media_kit_form .frame_wrapper").offset().top;
    let bottomBarHeight = $("#bottom_bar").outerHeight();

    $("#media_kit_form .frame_wrapper").css({
      height: `${
        (bodyHeight - wrapperTop - bottomBarHeight) / scalingFactor
      }px`,
    });
    $("#media_kit_form iframe")
      .contents()
      .find("html")
      .css({ height: `${(bodyHeight - wrapperTop) / scalingFactor}px` });
  }

  observeIframe() {
    const wrapper = $("#media_kit_form .frame_wrapper")[0];
    const config = { childList: true };

    const that = this;
    const callback = function (mutationList) {
      for (const mutation of mutationList) {
        if (mutation.type === "childList") {
          $("#media_kit_form iframe")[0].contentWindow.addEventListener(
            "DOMContentLoaded",
            function () {
              that.resizeFrame();
            }
          );
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(wrapper, config);
  }

  observeSidebar() {
    const sidebar = $(".media_kit.sidebar")[0];
    const config = { childList: true };

    const that = this;
    const callback = function (mutationList) {
      for (const mutation of mutationList) {
        // console.log("mutation: ", mutation)
        if (mutation.type === "childList") {
          that.initSidebarForm();
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(sidebar, config);
  }

  showSidebar() {
    $(".media_kit.sidebar").sidebar("show");
    $("#bottom_bar .edit.button").hide();
    $("#bottom_bar .save.button").show();
  }

  hideSidebar() {
    $(".media_kit.sidebar").sidebar("hide");
    $("#bottom_bar .edit.button").show();
    $("#bottom_bar .save.button").hide();
  }

  initSidebarForm() {
    $(".media_kit.sidebar").attr(
      "style",
      "height: calc(100vh - 65px) !important"
    );
    $(".media_kit.sidebar").sidebar({
      dimPage: false,
      exclusive: true,
      transition: "overlay",
      onShow: this.resizeFrame,
      onHidden: this.resizeFrame,
      closable: false,
    });

    const that = this;
    $("#bottom_bar .edit.button").click(() => {
      that.showSidebar();
    });
    $(".cancel").click(() => {
      that.hideSidebar();
    });

    this.showSidebar();

    $(".ui.accordion").accordion();

    $(".ui.checkbox").checkbox();

    $(".ui.dropdown").dropdown();
  }

  submitForm() {
    $(".media_kit.sidebar form")[0].requestSubmit();
  }

  preview() {
    console.log("preview");
  }

  observeForm() {
    document.addEventListener("turbo:submit-start", function (event) {
      $("#bottom_bar .save.button").addClass("loading");
      // $(".frame_wrapper").addClass("ui search form loading");
      console.log("loading: ", event.detail.formSubmission);
    });
    document.addEventListener("turbo:submit-end", function (event) {
      $("#bottom_bar .save.button").removeClass("loading");
      // $(".frame_wrapper").removeClass("ui search form loading");
      console.log("finished: ", event.detail.formSubmission);
    });
  }

  startEditor() {
    // this.observeIframe();
    this.observeSidebar();

    this.initSidebarForm();
    // this.resizeFrame();

    this.observeForm();

    // $("#bottom_bar .save.button").click(() => {
    //   that.submitForm();
    // });

    const that = this;

    $(document).on("click", "#bottom_bar .save.button", function () {
      that.submitForm();
    });
  }
}
