<template>
  <div>
    <div class="ui error message" v-if="errors">
      <div class="ui list">
        <div v-for="(value, key) in errors" :key="key" class="item">
          <span class="capitalize">{{ key }}</span> {{ value.join(", ") }}
        </div>
      </div>
    </div>

    <div class="form ui">
      <div class="ui fields">
        <div class="field eight wide">
          <input
            type="text"
            v-model="newCategory.name"
            placeholder="New Category"
          />
        </div>
        <div class="field">
          <swatches
            v-model="newCategory.color"
            show-fallback
            class="ui input"
            :trigger-style="{ width: '35px', height: '35px' }"
          >
          </swatches>
        </div>
        <div class="field">
          <button
            class="ui button primary"
            @click="create(newCategory)"
            :class="{ disabled: newCategory.name == '' }"
          >
            Add
          </button>
        </div>
      </div>
    </div>

    <draggable :list="categories" @change="draggableChanged">
      <div :id="category.id" :key="category.id" v-for="category in categories">
        <template v-if="category.isEditing">
          <div class="form ui">
            <div class="ui fields">
              <div class="field eight wide">
                <input type="text" v-model="category.name" />
              </div>
              <div class="field">
                <swatches
                  v-model="category.color"
                  show-fallback
                  class="ui input"
                  :trigger-style="{ width: '35px', height: '35px' }"
                >
                </swatches>
              </div>
              <div class="field">
                <button
                  class="ui button primary"
                  @click="save(category)"
                  :class="{ disabled: category.name === '' }"
                >
                  Save
                </button>
                <span
                  v-if="category.name != ''"
                  class="ui text pointer"
                  @click="cancelEditing(category)"
                  >cancel</span
                >
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="margin-bottom-small" style="width: 65%">
            <div :style="styleObject(category)" class="ui fluid large label">
              {{ category.name }}

              <div class="ui right floated">
                <i
                  class="icon trash alternate pointer"
                  @click="deleteCategory(category)"
                ></i>
                <i class="icon edit pointer" @click="edit(category)"></i>
              </div>
            </div>
          </div>
        </template>
      </div>
    </draggable>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Swatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import Toasted from "vue-toasted";
import draggable from "vuedraggable";
import fontColorContrast from "font-color-contrast";

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
  singleton: true,
});

const $ = window.jQuery;

export default {
  components: {
    Swatches,
    draggable,
  },
  props: {
    endpoint: {
      type: String,
    },
    initialCategories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    const $this = this;
    return {
      loading: false,
      errors: null,
      categories: $this.initialCategories,
      newCategory: {
        name: "",
      },
      revertToCategory: null,
    };
  },
  mounted() {
    if (this.initialCategories.length < 1) {
      this.loadCategories();
    }
  },
  methods: {
    emitUpdated() {
      this.$emit("updated", this.categories);
    },
    draggableChanged(event) {
      if (event.moved) {
        $.ajax({
          url: `${this.endpoint}/reorder`,
          method: "patch",
          data: {
            order: this.categories.map((c) => c.id),
          },
          dataType: "json",
        });
      }
    },
    deleteCategory(category) {
      if (confirm("Are you sure you want to delete this?")) {
        this.categories = this.categories.filter((c) => c.id !== category.id);
        this.destroy(category);
      }
    },
    stopEditing(category) {
      this.$set(category, "isEditing", false);
    },
    cancelEditing(category) {
      this.stopEditing(category);
      this.$set(category, "name", this.revertToCategory.name);
      this.$set(category, "color", this.revertToCategory.color);
    },
    edit(category) {
      this.revertToCategory = { ...category };
      this.$set(category, "isEditing", true);
    },
    save(category) {
      if (category.name == "") {
        return;
      }
      this.errors = null;

      let url = `${this.endpoint}`;
      let method = "post";
      if (category.id) {
        url = url + `/${category.id}`;
        method = "patch";
      }
      return $.ajax({
        url: url,
        method: method,
        dataType: "json",
        data: {
          event_category: category,
        },
        success: () => {
          Vue.toasted.success("Saved");
          this.stopEditing(category);
          this.emitUpdated();
        },
        error: (data) => {
          this.errors = data.responseJSON.errors;
        },
      });
    },
    create(category) {
      this.save(category).then((data) => {
        this.categories.push(data);
        this.newCategory = {};
        this.emitUpdated();
      });
    },
    destroy(category) {
      $.ajax({
        url: `${this.endpoint}/${category.id}`,
        method: "delete",
        dataType: "json",
      }).then(() => {
        this.emitUpdated();
      });
    },
    loadCategories() {
      $.ajax({
        url: this.endpoint,
        dataType: "json",
        contentType: "application/json",
      })
        .done(this.loadSuccess)
        .fail(this.loadError)
        .always(this.loadComplete);
    },
    loadSuccess(data) {
      this.categories = data;
    },
    loadError(data) {
      this.errors = data.errors;
    },
    loadComplete() {
      this.loading = false;
    },
    styleObject(category) {
      return {
        color: fontColorContrast(category.color),
        backgroundColor: category.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
//@import url(https://unpkg.com/vue-swatches/dist/vue-swatches.css);

.label.large {
  cursor: move;
}
</style>
