<template>
  <div
    :key="event.id"
    class="ui card pointer"
    @click.exact.meta.shift.stop.prevent="destroyEvent"
    @click.exact.stop.prevent="openEvent"
    @contextmenu.exact.stop.prevent="openContextMenu"
  >
    <div class="content">
      <div
        class="ui vertical border label"
        :style="event.styleObject"
      />

      <avatar
        v-if="event.username"
        class="avatar"
        :username="event.username"
        :src="event.user_gravatar"
        :data-tooltip="event.username"
        size="24px"
        data-variation="mini"
        data-inverted=""
        data-position="left center"
      />
      <div class="title">
        {{ event.title }}
      </div>

      <div class="meta">
        <span
          v-if="event.organization_name"
          class="ui grey text"
        >
          {{ event.organization_name }}
        </span>
      </div>
      <div class="meta">
        <span
          v-if="event.tasks_count > 0"
          class="margin-right-tiny"
        >
          <i class="icon circle check fitted" />
          {{ event.tasks_complete }}/{{ event.tasks_count }}
        </span>
        <span v-if="event.comments_count > 0">
          <i class="icon comment fitted" />
          {{ event.comments_count }}
        </span>
        <div class="right floated">
          {{ event.start | formatDate }}
        </div>
      </div>
    </div>

    <div ref="contextMenuPopup">
      <context-menu
        :event="event"
        class="ui mini popup vertical menu padding-xy-zero inverted"
        @destroy="destroyEvent"
      />
    </div>
  </div>
</template>

<script>
import Avatar from "components/avatar.vue";
import store from "store";
import contextMenu from "views/events/components/_contextMenu.vue";

const $ = window.jQuery;

export default {
  store,
  components: {
    Avatar,
    contextMenu,
  },
  props: ["event"],
  data() {
    return {
      dragEnabled: true,
    };
  },
  mounted() {},
  methods: {
    openContextMenu() {
      $(this.$el).popup({
        popup: $(this.$refs.contextMenuPopup).find(".popup"),
        on: "manual",
        position: "bottom left",
        exclusive: true,
        movePopup: false,
        hideOnScroll: true,
        delay: false,
        duration: 0,
      });

      $(this.$el).popup("show");
    },
    destroyEvent() {
      if (confirm("Are you sure you want to delete this campaign?")) {
        this.$store.dispatch("event/destroy", this.event);
      }
    },
    openEvent() {
      window.EventBus.$emit("openEventModal", this.event.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.ui.vertical.menu.popup {
  display: none;
}

.card {
  font-size: 0.85em;
  color: rgba(0, 0, 0, 0.87);
  padding: 1.2em;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  min-height: 75px;
  &:hover {
    box-shadow: 0 4px 6px 0 rgba(34, 36, 38, 0.12),
      0 4px 12px 0 rgba(34, 36, 38, 0.15);
  }

  i.handle {
    display: none;
    cursor: move;
  }
  &:hover i.handle {
    display: block;
  }

  .content {
    padding: 0;
    z-index: 0 !important;

    .avatar {
      float: right;
      margin-top: -0.3em;
      margin-bottom: 0.3em;
    }

    .title {
    }
    .meta {
      clear: both;
      display: block;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }

  .vertical.border.label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
  }
}
</style>
