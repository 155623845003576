/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "../controllers";

import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
// import wrap from "@vue/web-component-wrapper";
import vueCustomElement from "vue-custom-element";

import Avatar from "components/avatar.vue";
// import "document-register-element/build/document-register-element";
import Swatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import EventBus from "components/eventbus";

import textEditor from "components/textEditor.vue";
import "functions/filters";
import "views/calendars/show.js";
import "views/calendars/index.js";

import "packs/deliverables/card.js";
import "views/deliverables/form.js";
import "views/deliverables/troubleshooter.js";

import "views/partner_campaigns/index.js";

import "views/tasks/index.js";

import "views/events/eventModal.js";
import "views/events/cards.js";
import "views/events/table.js";

import "views/media_kits/edit.js";
import "views/media_kits/show.js";

import "views/uploads/dropzone.js";
import "packs/uploads/index";
import "views/comments/index.js";
import "views/users/index";
import "views/users/multipleUserSelect.js";

import "views/notifications/index.js";
import "components/shortcuts.js";
import "views/organizations/show.js";
import "views/categories/index.js";

import "views/carousel/index.js";
// import "packs/reports/pdf.js";

import "channels/index.js";

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

window.EventBus = EventBus;

Vue.config.ignoredElements = ["v-avatar", "v-compact-picker", "v-editor"];

Vue.use(vueCustomElement);

const AvatarComponent = {
  components: { Avatar },
  // props: ["username", "src", "size", "id"],
  props: {
    id: {
      type: String,
      default: "",
      required: false,
    },
    username: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "50",
    },
    src: {
      type: String,
      default: null,
    },
  },
  template:
    "<avatar class='' :id='id' :username='username' :src='src' :size='size'></avatar>",
};
// const VueAvatar = wrap(Vue, AvatarComponent);
Vue.customElement("v-avatar", AvatarComponent);

const CompactPicker = {
  components: { Swatches },
  props: ["defaultValue", "name", "colors", "bindElement"],
  data() {
    const $this = this;
    return {
      color: $this.defaultValue,
    };
  },
  methods: {
    input(val) {
      this.$emit("input", val);
      if (this.bindElement) {
        window.$(this.bindElement).val(val);
      }
    },
  },
  template: `<div>
        <input v-model="color" :name="name" type="hidden" />
        <swatches @close="input" v-model="color" :colors="colors" show-fallback :trigger-style="{ width: '34px', height: '34px' }">
        </swatches>
      </div>`,
};
Vue.customElement("v-compact-picker", CompactPicker);

const TextEditorComponent = {
  components: { textEditor },
  props: {
    name: String,
    initialContent: String,
    toolbar: String,
    submitOnEnter: {
      type: String,
      default: "false",
    },
    placeholder: {
      type: String,
      default: "",
    },
    onSubmit: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: true,
    },
    mentionsUsers: {
      type: String,
      default: () => {
        return "[]";
      },
    },
  },
  data() {
    const $this = this;
    return {
      content: $this.initialContent,
      submitOnEnterBoolean: JSON.parse($this.submitOnEnter || "false"),
      initialUsers: JSON.parse($this.mentionsUsers || "[]"),
    };
  },
  methods: {
    focus() {
      this.$refs.editor.focus();
    },
    submit() {
      if (typeof window[this.onSubmit] != "undefined") {
        window[this.onSubmit].call();
        this.$refs.editor.clear();
      }
    },
  },
  template: `<div>
      <textarea class="hidden" :name="name" v-html="content"></textarea>
      <text-editor 
        ref="editor" 
        @submit="submit" 
        v-model='content' 
        class='editor' 
        :toolbar="toolbar" 
        :submit-on-enter="submitOnEnterBoolean" 
        :placeholder="placeholder" 
        :autofocus="autofocus"
        :initial-users="initialUsers"
      >
      </text-editor>
    </div>`,
};
Vue.customElement("v-editor", TextEditorComponent);
