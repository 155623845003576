<template lang="html">
  <div>
    <vue-2-dropzone
      id="dropzone"
      v-if="!src"
      class="dropzone"
      :options="dropzoneOptions"
      @vdropzone-success="uploadComplete"
    />
    <img :src="src" v-if="src" class="ui image small" />
    <span
      v-if="src"
      class="ui small circular floating icon label"
      @click="reset"
    >
      <i class="icon times"></i>
    </span>

    <!--     <span v-if="src" class="ui small text center aligned">
      <a href="#" @click="reset">Upload a different file</a>
    </span>
 -->
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";

export default {
  components: {
    vue2Dropzone,
  },
  props: {
    asset: {
      type: Object,
      default: null,
    },
    onCompleteCallback: String,
    initialSrc: String,
    param: String,
    endpoint: {
      type: String,
      default: "/uploads",
    },
  },
  data() {
    const $this = this;
    return {
      src: this.initialSrc,
      dropzoneOptions: {
        maxFilesize: 10,
        thumbnailWidth: 100,
        previewTemplate: `
              <div class="dz-preview dz-file-preview">
                <div class="dz-progress">
                  <span class="dz-upload" data-dz-uploadprogress></span>
                </div>
              </div>
              <div class="dz-error-message"><span data-dz-errormessage></span></div>`,
        maxFiles: 1,
        url: $this.endpoint,
        paramName: $this.param,
        method: "patch",
        acceptedFiles: "image/*",
        headers: {
          "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content"),
        },
        dictDefaultMessage: "",
      },
    };
  },
  methods: {
    reset() {
      this.src = "";
      if (this.asset) {
        this.destroy();
      }
    },
    destroy() {
      const url = `/${
        this.asset.type == "Upload" ? "uploads" : "ckeditor/pictures"
      }/${this.asset.id}`;
      window.$.ajax({
        url: url,
        type: "delete",
        dataType: "json",
      });
    },
    uploadComplete(file, response) {
      this.src = file.dataURL;
      console.log(file);
      console.log(response);

      if (this.onCompleteCallback) {
        window[this.onCompleteCallback].call();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "dropzone/dist/dropzone.css";

.v-dropzone .dropzone {
  width: 150px;
  height: 80px;
  margin-right: 0.5em;
  border: 2px dashed rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  transition: border 200ms ease-out;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:before {
    content: "\f382";
    font-family: Icons;
    color: rgba(0, 0, 0, 0.22);
    display: flex;
    height: 90%;
    justify-content: center;
    align-items: center;
    font-size: 26px !important;
  }

  &.dz-started {
    &:before,
    &:after {
      content: "";
      height: 0;
    }
  }
  &:hover {
    border-color: #ccc;
    &:before,
    &:after {
      color: #ccc;
    }
  }
}
</style>
