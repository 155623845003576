var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasBeenLoaded)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
    intersection: {
      rootMargin: '1000px',
    },
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n    intersection: {\n      rootMargin: '1000px',\n    },\n  }"}],staticClass:"ui profile fluid card padding-bottom-medium",class:[
    ((_vm.deliverable.inferred_type.replace('_profile', '')) + "-card"),
    { fluid: _vm.fluid } ],attrs:{"id":("profileCard" + (_vm.deliverable.id))}},[_c('div',{staticClass:"content card-header"},[_c('div',{staticClass:"ui one column centered grid padding-top-small"},[(_vm.hasStats)?_c('div',{staticClass:"column center aligned"},[_c('a',{staticClass:"icon-head",attrs:{"href":_vm.deliverable.url,"target":"_blank"}},[_c('i',{staticClass:"ui large icons"},[(_vm.deliverable)?_c('img',{staticClass:"ui circular rounded image bordered white background",attrs:{"src":_vm.deliverable.provider_picture,"height":"55","width":"55"}}):_vm._e(),_vm._v(" "),_c('i',{staticClass:"icon tiny colored bottom right circular corner",class:_vm.inferredTypeAsIconClass(
                  _vm.deliverable.inferred_type.replace('_profile', '')
                )})])]),_vm._v(" "),_c('br'),_vm._v(" "),_c('h3',[_c('a',{attrs:{"href":_vm.deliverable.url}},[_c('span',{staticClass:"ui black underlined text"},[_vm._v("\n              "+_vm._s(_vm._f("capitalize")(_vm.deliverable.provider_username))+"\n            ")])])])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column center aligned"},[(_vm.hasStats)?_c('div',{ref:"statisticsDetails",staticClass:"ui wide popup"},[_c('h3',{staticClass:"ui header margin-top-zero"},[_vm._v("\n            "+_vm._s(_vm._f("capitalize")(_vm.inferredTypeAsIconClass(
                _vm.deliverable.inferred_type.replace("_profile", "")
              )))+"\n          ")]),_vm._v(" "),_vm._l((_vm.statisticsDetails),function(group){return (group.details && group.details.length > 0)?_c('div',{staticClass:"ui statistic mini margin-left-zero left aligned"},[_c('div',{staticClass:"label capitalize"},[_vm._v("\n              "+_vm._s(_vm._f("humanize")(group.total.label))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"ui list margin-top-tiny margin-bottom-small"},_vm._l((group.details),function(detail){return _c('div',{staticClass:"ui item"},[_vm._v("\n                "+_vm._s(detail)+"\n              ")])}),0)]):_vm._e()}),_vm._v(" "),_vm._m(0)],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ui equal width column compact grid center aligned"},[(!_vm.hasStats)?_c('div',{staticClass:"column center aligned"},[_c('div',{staticClass:"ui info message"},[_vm._v("\n              No stats are available for this profile ("+_vm._s(_vm.deliverable.url)+")\n              "),_c('br'),_vm._v("\n              "+_vm._s(_vm.deliverable.reporting_status.message)+"\n            ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.profileStats),function(statisticValue){return (
              statisticValue.total.value != 0 &&
              statisticValue.total.value != '00:00:00'
            )?_c('div',{staticClass:"ui column mini statistic"},[_c('div',{staticClass:"value"},[_c('span',[_vm._v(_vm._s(_vm._f("abbreviateNumber")(statisticValue.total.value,10000, 1)))])]),_vm._v(" "),_c('div',{staticClass:"label",staticStyle:{"white-space":"nowrap"}},[_c('span',[_vm._v(_vm._s(_vm._f("humanize")(statisticValue.total.label.replace("profile_", ""))))])])]):_vm._e()})],2)])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"ui small text"},[_c('a',{attrs:{"href":"https://help.influencekit.com/en/articles/2138024-understanding-report-metrics","target":"_blank"}},[_c('i',{staticClass:"icon question mark circle"}),_vm._v("\n              What do these mean?\n            ")])])}]

export { render, staticRenderFns }