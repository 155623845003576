<template lang="html">
  <div
    ref="index"
    class="clearing"
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        rootMargin: '200px',
      },
    }"
  >
    <!-- <div
      v-if="enabled('campaigns')"
      class="ui progress small"
      :class="{ red: percentDeliverablesQuotaUsed > 89 }"
      :data-percent="percentDeliverablesQuotaUsed"
    >
      <div class="bar">
        <div class="progress" />
      </div>
      <div class="label">
        {{ percentDeliverablesQuotaUsed }}% of deliverable quota used
      </div>
    </div> -->

    <div>
      <div class="ui mini top attached compact tabular menu">
        <a href="#" class="active item" data-tab="multiple">
          <i class="icon list" />
          Links
        </a>
        <a href="#" class="item" data-tab="search">
          <i class="icon instagram" />
          Stories
          {{ `(${igStoriesDeliverables.length})` }}
        </a>

        <a href="#" class="item" data-tab="search-pinterest">
          <i class="icon search" />
          Quick Search
        </a>

        <a
          href="#"
          data-tab="saved_search"
          class="item"
          v-if="enabled('saved_search')"
        >
          <i class="icon search plus"></i>
          Saved Search
        </a>

        <a v-show="showTemplate" href="#" class="item" data-tab="template">
          <i class="icon magic" />
          Use template
        </a>

        <!-- <a class="right item" v-show="erroredDeliverables.length > 0">
          <troubleshooter :only-errored="true" />
        </a> -->
      </div>

      <div class="ui bottom attached active tab segment" data-tab="multiple">
        <multiple-deliverable-form
          ref="formMultiple"
          :endpoint="endpoint"
          @create="addMultipleDeliverables"
        />
      </div>

      <div
        v-if="!igStoriesEnabled"
        class="ui bottom attached tab padded segment"
        data-tab="search"
      >
        <div class="ui placeholder segment">
          <div class="ui header">
            Your plan doesn't include access to this feature.
          </div>
          <p>
            Please
            <a href="/billing">click here to upgrade</a>
          </p>
        </div>
      </div>
      <div
        v-else
        class="ui bottom attached tab padded segment"
        data-tab="search"
      >
        <h2 class="ui header fluid">
          Stories
          <div class="sub header">
            Add Instagram Stories to your report
            <small v-if="shouldShowSearchForm">
              <em>(drag or click to select)</em>
            </small>
            <small v-else class="right floated">
              <i
                v-if="igStoriesDeliverables.length > 0"
                class="icon th link"
                :class="{ pink: cardGridCount === 8 }"
                @click.stop="toggleGridCount"
              />
              <i
                v-if="igStoriesDeliverables.length > 0"
                class="icon th large link"
                :class="{ pink: cardGridCount === 4 }"
                @click.stop="toggleGridCount"
              />
            </small>
          </div>
        </h2>
        <div
          class="ui message info"
          v-if="!shouldShowSearchForm && sortedInstagramStories.length > 0"
        >
          <i class="icon info circle"></i>
          Link clicks (swipe ups), and sticker taps must be added manually.
          <a
            href="https://help.influencekit.com/en/articles/4278599-how-do-i-add-swipe-ups-and-click-stats-to-my-instagram-stories"
            target="_blank"
            >Here's how.</a
          >
        </div>

        <deliverable-search-form
          v-if="shouldShowSearchForm"
          ref="searchForm"
          class="searchForm"
          :event="event"
          :endpoint="endpoint"
          provider="instagram_stories"
          :initial-selected-deliverables="igStoriesDeliverables"
          @save="addDeliverable"
          @hide="hideSearchForm"
        />

        <div
          v-show="!shouldShowSearchForm"
          class="deliverables ui cards doubling clearboth"
          :class="cardGridClassObject"
        >
          <div class="ui card addStory link" @click="showSearchForm">
            <div class="ui embed 9:16">
              <div>
                <i class="icon plus circle big inverted" />
                <p>Add Story</p>
              </div>
            </div>
          </div>

          <instagram-story
            v-if="isVisible"
            v-for="deliverable in sortedInstagramStories"
            :key="deliverable.id"
            :selectable="false"
            :deletable="true"
            :editable="true"
            :endpoint="endpoint"
            :metric-types="metricTypes"
            :deliverable="deliverable"
            @delete="deleteDeliverable"
          />
        </div>
      </div>

      <div
        class="ui bottom attached tab padded segment"
        data-tab="search-pinterest"
      >
        <h2 class="ui header fluid">
          Find Deliverables
          <div class="sub header">Add deliverables to your report</div>
        </h2>

        <deliverable-search-form
          ref="searchForm"
          class="searchForm"
          :event="event"
          :endpoint="endpoint"
          :initial-selected-deliverables="deliverables"
          @saveMultiple="saveMultiple"
        />
      </div>

      <div
        data-tab="saved_search"
        class="ui bottom attached padded tab segment"
      >
        <turbo-frame
          :id="`saved_search_event_${event.id}`"
          :src="`/events/${event.id}/saved_search`"
          loading="lazy"
        ></turbo-frame>
      </div>

      <div
        v-show="showTemplate"
        class="ui bottom attached tab segment"
        data-tab="template"
      >
        <strong>Load these deliverables?</strong>

        <ul class="ui sixteen wide list">
          <li v-for="deliverable in suggestedDeliverables">
            {{ deliverable.name }}
          </li>
        </ul>
        <a
          class="ui button"
          @click.stop.prevent="addSuggestedDeliverables(suggestedDeliverables)"
        >
          Yes!
        </a>
      </div>
    </div>

    <div class="ui clearing">
      <div v-if="urlDeliverables.length > 10" class="ui secondary right menu">
        <div class="ui right aligned item fitted">
          <div class="ui left icon input">
            <i class="search link icon" />
            <input
              v-model="searchString"
              class="prompt"
              type="text"
              placeholder="Find deliverables"
            />
          </div>
        </div>
      </div>

      <deliverable-form
        v-for="(deliverable, index) in urlDeliverables"
        v-show="deliverableIsVisible(deliverable)"
        :key="deliverable.id || index"
        :initial-deliverable="deliverable"
        :metric-types="metricTypes"
        :endpoint="endpoint"
        class="ui segment margin-bottom-medium"
        @delete="deleteDeliverable"
      />
    </div>
    <a
      href="https://help.influencekit.com/en/articles/3030998-how-do-i-add-deliverables-to-a-campaign-or-assignment"
      target="_blank"
    >
      <i class="ui icon question mark circle"></i>
      Learn more about how to add deliverables.
    </a>
    <br /><br />
    <button
      v-if="deliverables.length > 0"
      class="ui button right floated"
      @click.stop.prevent="saveAll"
    >
      Save Deliverables
    </button>
  </div>
</template>

<script>
import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";
import { mapState, mapGetters } from "vuex";
import store from "store";
import EventBus from "components/eventbus";
import DeliverableForm from "views/deliverables/form.vue";
import DeliverableSearchForm from "views/deliverables/search.vue";
import MultipleDeliverableForm from "views/deliverables/formMultiple.vue";
import InstagramStory from "./components/instagramStory.vue";
import Troubleshooter from "./troubleshooter.vue";
import _ from "lodash";
Vue.use(VueObserveVisibility);

const $ = window.jQuery;

export default {
  store,
  components: {
    DeliverableForm,
    MultipleDeliverableForm,
    DeliverableSearchForm,
    EventBus,
    InstagramStory,
    Troubleshooter,
  },
  props: {
    lazyLoad: {
      type: Boolean,
      default: true,
    },
    igStoriesEnabled: Boolean,
    endpoint: String,
    initialDeliverables: Array,
    initialEvent: Object,
    metricTypes: Array,
    suggestedDeliverables: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    const $this = this;
    return {
      isVisible: $this.lazyLoad ? false : true,
      searchString: "",
      shouldShowSearchForm: false,
      showMultipleForm: false,
      showSuggested: false,
      // deliverables: $this.initialDeliverables,
      newDeliverable: {
        manual_update_statistics: [],
      },
      cardGridCount: 8,
    };
  },
  computed: {
    deliverables: {
      get() {
        return this.$store.state.deliverable.all;
      },
      set(val) {
        this.$store.dispatch("deliverable/setAll", {
          items: val,
        });
      },
    },
    igStoriesDeliverables() {
      return _.filter(
        this.deliverables,
        (d) => d.inferred_type === "instagram_stories"
      );
    },
    sortedInstagramStories() {
      return _.sortBy(this.igStoriesDeliverables, (d) =>
        window.moment(d.provider_published_at)
      );
    },
    urlDeliverables() {
      return _.orderBy(
        _.filter(
          this.deliverables,
          (d) => d.inferred_type !== "instagram_stories"
        ),
        ["created_at"],
        ["desc"]
      );
    },
    erroredDeliverables() {
      return this.urlDeliverables.filter((d) => !d.last_fetched);
    },
    ...mapState("event", {
      event: "current",
      currentDeliverables: "currentDeliverables",
    }),
    ...mapGetters("app", ["enabled"]),
    percentDeliverablesQuotaUsed() {
      return this.$store.state.app.config.percentage_deliverables_quota_used;
    },
    showTemplate() {
      return (
        this.deliverables.length < 1 && this.suggestedDeliverables.length > 0
      );
    },
    cardGridClassObject() {
      return {
        eight: this.cardGridCount === 8,
        four: this.cardGridCount === 4,
      };
    },
  },

  watch: {
    currentDeliverables(val) {
      this.deliverables = val;
    },
  },
  mounted() {
    if (this.initialDeliverables) this.deliverables = this.initialDeliverables;

    if (this.initialEvent) {
      this.$store.dispatch("event/setCurrent", { item: this.initialEvent });
    }
    $(this.$el).find(".tabular.menu .item").tab();
  },
  methods: {
    visibilityChanged(value) {
      this.isVisible = this.isVisible || value;
    },
    toggleGridCount(e) {
      if (this.cardGridCount === 8) {
        this.cardGridCount = 4;
      } else {
        this.cardGridCount = 8;
      }
      e.stopPropagation();
      return false;
    },
    deliverableIsVisible(del) {
      return [del.name, del.url, del.inferred_type]
        .join()
        .match(new RegExp(this.searchString, "i"));
    },
    hideSearchForm() {
      this.shouldShowSearchForm = false;
    },
    showSearchForm() {
      this.shouldShowSearchForm = true;
    },
    addMultipleDeliverables(deliverables) {
      this.addDeliverables(deliverables);
    },
    addSuggestedDeliverables(deliverables) {
      this.addDeliverables(deliverables);
      this.saveAll();
    },
    addDeliverables(deliverables) {
      deliverables.forEach((deliverable) => {
        this.addDeliverable(JSON.parse(JSON.stringify(deliverable)));
      });
    },
    addDeliverable(deliverable) {
      this.deliverables.unshift(deliverable);
      this.$emit("addDeliverable", deliverable);
      // EventBus.$emit("deliverableCreated", deliverable);
    },
    saveMultiple(urls) {
      // oh this is bad here
      this.$refs.formMultiple.urls = urls;
      this.$refs.formMultiple.addUrls();
    },
    saveAll() {
      EventBus.$emit("saveAllDeliverables");
    },
    deleteDeliverable(deliverable) {
      this.deliverables = this.deliverables.filter(
        (x) => x.id !== deliverable.id
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 400px) {
  .tabular.menu {
    .item {
      i {
        display: none;
      }
      // border: none !important;

      // &:before {
      //   display: initial !important;
      // }
    }
  }
}

.card.addStory {
  box-shadow: none !important;
  background: radial-gradient(
      circle at center,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.65)
    ),
    url("./images/ig_story_placeholder.jpg") center/cover no-repeat !important;

  .ui.embed > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    p {
      margin-top: 1em;
    }
  }
}

div.ui[class*="9:16"].embed {
  padding-bottom: 178%;
  background: none !important;
}

.sub.header small {
  color: #999999;
}

i.th,
i.sync {
  font-size: 1.5em !important;
  vertical-align: baseline !important;
}
</style>
