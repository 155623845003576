<template lang="html">
  <div>
    <paginate
      v-model="page"
      v-show="pageCount > 1"
      :page-count="pageCount"
      :clickHandler="clickHandler"
      :page-class="'item'"
      :container-class="'ui menu pagination compact tiny'"
      :no-li-surround="true"
      active-class="active"
      disabled-class="disabled"
      page-link-class="item"
      prev-link-class="item"
      next-link-class="item"
    ></paginate>

    <div v-if="showJumpTo" class="ui input labeled right floated">
      <div class="ui basic label">Jump to:</div>
      <input
        type="number"
        style="width: 5em"
        v-model.number="page"
        v-debounce.cancelOnEmpty="300"
        @change="jumpTo(page)"
        @focus="$event.target.select()"
      />
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import debounce from "v-debounce";

export default {
  components: {
    Paginate,
  },
  directives: { debounce },

  props: ["initialPage", "totalResults", "perPage", "showJumpTo"],
  data() {
    const $this = this;
    return {
      page: $this.initialPage || 1,
      total: $this.totalResults || 0,
      per: $this.perPage || 10,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.per);
    },
  },
  methods: {
    jumpTo(page) {
      if (page == "") return;
      this.page = page;
      this.clickHandler(page);
    },
    clickHandler(val) {
      this.$emit("click", val);
    },
  },
  watch: {
    totalResults(val) {
      if (val) this.total = val;
    },
  },
};
</script>

<style lang="css"></style>
