<template lang="html">
  <div class="">
    <div
      v-if="Object.keys(groupings).length > 1"
      class="ui form"
    >
      <div class="inline fields">
        <label>Group by:</label>
        <div
          v-for="(group, name) of groupings"
          v-if="groupCollection(group.collectionName).length > 1"
          :key="group.name + '-' + group.key"
          class="field"
        >
          <div class="ui radio checkbox">
            <input
              v-model="selectedGrouping"
              type="radio"
              :value="name"
            >
            <label
              class="capitalize"
              :for="name"
            >{{ name }}</label>
          </div>
        </div>
      </div>
    </div>

    <filter-labels />

    <vueCustomScrollbar class="scroller">
      <draggable
        :list="columns"
        group="columns"
        draggable=".category"
        :disabled="!columnSortEnabled"
        class="ui grid board"
        @change="columnDropped"
      >
        <column
          v-for="column in columns"
          :key="column.id"
          :column-key="groupByKey"
          :column="column.object"
          :events="groupedEvents[column.id] || []"
          :height="columnHeight"
          :drag-enabled="columnSortEnabled"
          class="category"
        />

        <column
          v-if="groupBy.allowUnassigned"
          slot="header"
          :column="{ name: 'Unassigned', id: '', object: {} }"
          :column-key="groupByKey"
          :events="groupedEvents[null] || []"
          :height="columnHeight"
          :drag-enabled="false"
        />
      </draggable>
    </vueCustomScrollbar>
  </div>
</template>

<script>
import store from "store";
import { mapState, mapGetters, mapActions } from "vuex";
import debounce from "v-debounce";
import { groupBy } from "lodash";
import draggable from "vuedraggable";
import Dropdown from "components/dropdown.vue";

import Column from "./_column.vue";
import filterLabels from "./_filterLabels.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import EventBus from "components/eventbus";
import { uniqBy, findIndex } from "lodash";

const { moment, $ } = window;

export default {
  store,
  components: {
    draggable,
    Column,
    vueCustomScrollbar,
    filterLabels,
    Dropdown,
  },
  directives: { debounce },
  props: {
    eventsEndpoint: {
      type: String,
      default: null,
    },
    initialSelectedCalendars: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    const $this = this;
    return {
      recomputeHeight: 0,
      updatedEvent: "",
      columns: [],
      groupings: {
        status: {
          key: "aasm_state",
          collectionName: "statusesAsColumns",
          allowUnassigned: false,
          sortable: false,
        },
        // category: {
        //   key: "event_category_id",
        //   collectionName: "categories",
        //   allowUnassigned: true,
        //   sortable(vm) {
        //     return vm.calendars.length === 1;
        //   },
        //   reorderEndpoint(vm) {
        //     return `/calendars/${
        //       vm.categories[0].calendar_id
        //     }/event_categories/reorder`;
        //   }
        // }
        // calendar: {
        //   key: "calendar_id",
        //   collectionName: "calendars",
        //   allowUnassigned: false,
        //   sortable() {
        //     return true;
        //   },
        //   reorderEndpoint() {
        //     return `/calendars/reorder`;
        //   }
        // }
        // user: {
        //   key: "user_id",
        //   collectionName: "usersAsColumns",
        //   allowUnassigned: false,
        //   sortable: false
        // }
      },
      // selectedGrouping: $this.getLocalStorage("selectedGrouping", "status")
      selectedGrouping: "status",
    };
  },
  computed: {
    ...mapState("event", {
      events: "all",
    }),

    ...mapState("calendar", [
      "calendars",
      "selectedCalendars",
      "selectedCategory",
      "selectedUser",
      "searchQuery",
      "fieldFilters",
      "statuses",
    ]),
    ...mapGetters("calendar", {
      categories: "categories",
      users: "users",
      selectedStartDate: "selectedStartDate",
      selectedEndDate: "selectedEndDate",
    }),

    groupBy() {
      return this.groupings[this.selectedGrouping];
    },
    groupByKey() {
      return this.groupBy.key;
    },
    columnSortEnabled() {
      if (this.groupBy.sortable) {
        return this.groupBy.sortable(this);
      }
      return false;
    },
    endpointParams() {
      const $this = this;
      let params = {
        calendar_ids: $this.selectedCalendars,
        event_category_id: $this.selectedCategory,
        user_id: $this.selectedUser,
        "q[aasm_state_in]": $this.statuses,
        start: $this.selectedStartDate,
        end: $this.selectedEndDate,
      };

      if ($this.searchQuery && $this.searchQuery.indexOf("#") != -1) {
        params["q[tags_name_cont]"] = $this.searchQuery.replace("#", "");
      } else {
        params[
          "q[user_login_or_organization_name_or_email_or_description_or_name_or_tags_name_cont]"
        ] = $this.searchQuery;
      }

      for (let [key, obj] of Object.entries($this.fieldFilters)) {
        let { operator, value } = obj;
        if (operator && value) {
          params[`q[${key}_${operator}]`] = value;
        }
      }

      return {
        url: $this.eventsEndpoint,
        data: params,
      };
    },
    statusesAsColumns() {
      return this.statuses.map((s) => {
        return {
          name: s,
          id: s,
        };
      });
    },
    usersAsColumns() {
      return uniqBy(this.users, "id").map((u) => {
        return {
          name: u.login,
          id: u.id,
        };
      });
    },

    collectionAsColumns() {
      let arr = [];

      this[this.groupBy.collectionName].forEach((c) => {
        arr.push({
          name: c.name,
          id: c.id,
          object: c,
        });
      });
      return arr;
    },
    groupedEvents() {
      return groupBy(this.events, this.groupByKey);
    },
    columnHeight() {
      let recompute = this.recomputeHeight;

      let val = "auto";
      const thisOffset = $(this.$el).offset();
      if (thisOffset) {
        val = `${$(window).height() - thisOffset.top - 55}px`;
      }
      return val;
    },
  },
  watch: {
    collectionAsColumns(val) {
      this.columns = val;
    },
    updatedEvent(val) {
      const eventIndex = this.events.findIndex((event) => event.id === val.id);
      this.$set(this.events, eventIndex, val);
    },
    endpointParams(newVal, oldVal) {
      if (newVal && newVal != oldVal) {
        this.refetchEvents();
      }
    },
    selectedCalendars() {
      this.columns = this.collectionAsColumns;
    },
    selectedGrouping(val) {
      this.setLocalStorage("selectedGrouping", val);
    },
  },
  mounted() {
    this.columns = this.collectionAsColumns;

    this.refetchEvents();

    this.$nextTick(() => {
      window.$(window).on("resize", () => {
        this.updateHeight();
      });

      this.updateHeight();
    });

    EventBus.$on("fixedCalendar", () => {
      this.updateHeight();
    });
  },
  methods: {
    localStorageKey(key) {
      let string = "";
      if (this.initialSelectedCalendars.length > 1) {
        string = "calendar_dashboard";
      } else {
        string = `calendar_${this.initialSelectedCalendars[0].id}`;
      }

      return `${string}:kanban:${key}`;
    },
    setLocalStorage(key, val) {
      return localStorage.setItem(this.localStorageKey(key), val);
    },
    getLocalStorage(key, fallback) {
      return localStorage.getItem(this.localStorageKey(key)) || fallback;
    },
    groupCollection(name) {
      return this[name];
    },
    updateHeight() {
      this.$nextTick(() => {
        this.recomputeHeight++;
      });
    },

    refetchEvents() {
      if (!this.eventsEndpoint) {
        return;
      }
      $.ajax(this.endpointParams).done((data) => {
        this.$store.dispatch("event/setAll", {
          items: data,
        });
      });
    },
    ...mapActions("event", ["updateEvent"]),
    removeEvent() {
      //noop
    },
    refreshEvent(val) {
      // this.updateEvent(event);
      const eventIndex = this.events.findIndex((event) => event.id === val.id);
      this.$set(this.events, eventIndex, val);
    },
    columnDropped(event) {
      // this[`${this.selectedGrouping}Dropped`].call(this, event);
      if (event.moved) {
        $.ajax({
          url: this.groupBy.reorderEndpoint(this),
          method: "patch",
          data: {
            order: this.columns.map((c) => c && c.id),
          },
          dataType: "json",
        });
      }
    },
    // calendarDropped(event) {
    //   if (event.moved) {
    //     $.ajax({
    //       url: `/calendars/reorder`,
    //       method: "patch",
    //       data: {
    //         order: this.columns.map(c => c && c.id)
    //       },
    //       dataType: "json"
    //     });
    //   }
    // },
    // categoryDropped(event) {
    //   if (event.moved) {
    //     $.ajax({
    //       url: `/calendars/${
    //         this.categories[0].calendar_id
    //       }/event_categories/reorder`,
    //       method: "patch",
    //       data: {
    //         order: this.columns.map(c => c && c.id)
    //       },
    //       dataType: "json"
    //     });
    //   }
    // },

    reloadEventFromApi(event) {
      const $this = this;
      const $event = event;
      $.read($event.url).then((data) => {
        $this.$set($this.events, $this.events.indexOf($event), data);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroller {
  padding-bottom: 1em;
}

.board {
  flex-wrap: nowrap !important;
  // overflow-x: auto;
}
</style>
