<template lang="html">
  <div :class="deliverable.provider" class="ui card" @mousedown.alt.stop="zoom">
    <div class="content header">
      <i
        :class="deliverable.provider"
        class="icon colored large left floated margin-top-tiny margin-right-tiny"
      />
      <span class="ui small text">
        {{ deliverable.username }}
        <div class="meta pointer" @mousedown.stop="openUrl">
          <timeago :since="deliverable.created_at" />
        </div>
      </span>
    </div>

    <div v-if="hasImage" :style="pictureStyleObject" class="image" />

    <div v-if="hasVideo" class="image">
      <video
        ref="video"
        :src="deliverable.picture"
        width="100%"
        loop
        allow="autoplay"
        controls
        @mousedown.stop.prevent="noOp"
      />
    </div>

    <div class="content description">
      {{ deliverable.text }}
    </div>

    <div v-if="selectable" class="bottom overlay">
      <i
        class="ui right floated link icon check circular pink inverted fitted"
      />
    </div>

    <div
      ref="zoomer"
      class="ui inverted blurring page dimmer zoomer"
      @mousedown.stop.prevent="zoom"
    >
      <div class="content">
        <div class="ui grid container left aligned segment">
          <div class="ui four wide column">
            <div v-if="deliverable.picture" class="image">
              <img :src="deliverable.picture" />
            </div>
          </div>
          <div class="ui twelve wide column">
            <div class="header margin-top-zero">
              <i
                :class="deliverable.provider"
                class="icon colored large left floated margin-right-small"
              />
              <h3>
                {{ deliverable.username }}
              </h3>

              <div class="meta pointer" @mousedown.stop="openUrl">
                <timeago :since="deliverable.created_at" />
              </div>
            </div>
            <div class="content">
              {{ deliverable.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import VueTimeago from "vue-timeago";
import enLocale from "vue-timeago/locales/en-US.json";

Vue.use(VueTimeago, {
  name: "timeago", // component name, `timeago` by default
  locale: "en-US",
  locales: { "en-US": enLocale },
});

const $ = window.jQuery;

export default {
  components: {},
  props: ["deliverable", "selectable"],
  data() {
    return {};
  },
  computed: {
    pictureStyleObject() {
      return {
        backgroundImage: `url(${this.deliverable.picture})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      };
    },
    hasImage() {
      return (
        this.deliverable.picture && !this.deliverable.picture.includes(".mp4")
      );
    },
    hasVideo() {
      return (
        this.deliverable.picture && this.deliverable.picture.includes(".mp4")
      );
    },
  },
  mounted() {},
  methods: {
    noOp() {},
    zoom() {
      $(this.$refs.zoomer).dimmer("toggle");
    },
    openUrl() {
      window.open(this.deliverable.url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .content.header {
    flex-grow: 0;
    max-height: 60px;
  }

  .image {
    height: 190px;
    overflow: hidden;
  }

  &.facebook {
    background: linear-gradient(180deg, #476eb1 8px, #fff 8px);
  }
  &.instagram {
    background: linear-gradient(#ac279f 8px, #fff 8px);
  }
  &.tik_tok {
    background: linear-gradient(#ee1d52 8px, #fff 8px);
  }
  &.pinterest {
    background: linear-gradient(180deg, #c0001d 8px, #fff 8px);
  }
  &.twitter {
    background: linear-gradient(180deg, #009dea 8px, #fff 8px);
  }

  .description.content {
    height: 100px;
    overflow: auto;
    &:before {
      content: "";
      position: absolute;
      height: 2em;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(180deg, transparent -2em, #fff);
    }

    scrollbar-width: none; /* Firefox 64 */
    -ms-overflow-style: none; /* Internet Explorer 11 */
    &::-webkit-scrollbar {
      /** WebKit */
      display: none;
      width: 0;
      height: 0;
    }
  }
}

body.alt {
  .card:hover {
    cursor: zoom-in;
  }
}

.selected,
.selecting,
.added {
  .overlay.bottom {
    display: block;
  }
}
.added {
  .disabling.dimmer {
    display: block !important;
    background: rgba(255, 255, 255, 0.55) !important;
    opacity: 1 !important;
  }
}

.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5em;
  &.bottom {
    display: none;
    &.editable {
      display: block;
    }
    bottom: 0;
  }
}

.zoomer.dimmer {
  cursor: zoom-out;
}
</style>
