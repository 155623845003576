<template>
  <div class="media_kit" :style="cssVars">
    <!-- loader  -->
    <div v-show="savingPdf" class="ui active page dimmer">
      <div class="ui text loader">Saving PDF</div>
    </div>

    <!-- renderer -->
    <div class="frame_wrapper" style="padding-bottom: 4em">
      <template-loader
        ref="template"
        :data="{ editable, mediaKit, templateOptions }"
        :type="mediaKit.template"
        :key="mediaKit.template"
        @add-annotation="addAnnotation"
        @delete-annotation="deleteAnnotation"
        @save="save"
      />
    </div>

    <!-- editor -->
    <div
      ref="sidebar"
      class="ui right wide sidebar overlay visible"
      style="height: calc(100vh - 65px) !important"
    >
      <h3>
        Edit Profile
        <i class="icon times cancel link right floated grey"></i>
      </h3>

      <div ref="accordion" class="ui styled accordion">
        <!-- <div
          :class="`title ${templates.length > 1 && 'active'}`"
          v-if="templates.length > 1"
        >
          Options
          <i class="icon dropdown" />
        </div> -->

        <!-- <div
          :class="`content ${templates.length > 1 && 'active'}`"
          v-if="templates.length > 1"
        >
          <div class="ui form">
            <div class="field">
              <label>Select a Template</label>
              <select v-model="mediaKit.template">
                <option v-for="(label, key) in templates" :value="key">
                  {{ label["name"] }}
                </option>
              </select>
            </div>

            <div v-if="selectedTemplate.pdfBackground" class="field">
              <label>PDF Background</label>

              <vue-dropzone
                v-if="!mediaKit.pdf_background"
                id="pdfDropzone"
                ref="pdfDropzone"
                :include-styling="true"
                :options="pdfBackgroundDropzoneOptions"
                @vdropzone-success="pdfBackgroundUploadComplete"
              />

              <div v-if="mediaKit.pdf_background">
                {{ mediaKit.pdf_background.data_file_name }}
                <i class="icon times link" @click="removePdfBackground"></i>
              </div>
            </div>

            <template v-if="selectedTemplate.primary_color">
              <div class="field">
                <label>Primary Color</label>
                <swatches
                  v-model="mediaKit.primary_color"
                  show-fallback
                  class="ui input"
                  :trigger-style="{ width: '35px', height: '35px' }"
                />
              </div>
            </template>

            <template v-if="selectedTemplate.background_color">
              <div class="field">
                <label>Background Color</label>
                <swatches
                  v-model="mediaKit.background_color"
                  show-fallback
                  class="ui input"
                  :trigger-style="{ width: '35px', height: '35px' }"
                />
              </div>
            </template>
          </div>
        </div> -->

        <!-- <div v-show="!selectedTemplate.pdfBackground"> -->

        <div class="title">
          Cover Image
          <i class="icon dropdown"></i>
        </div>
        <div class="content ui form">
          <vue-dropzone
            v-if="!mediaKit.header_image_id"
            id="headerImageDropzone"
            ref="headerImageDropzone"
            :include-styling="false"
            :options="headerImageDropzoneOptions"
            @vdropzone-success="headerImageUploadComplete"
          />
          <div v-if="mediaKit.header_image_id">
            <img
              class="ui image fluid centered rounded"
              :src="mediaKit.header_image.expiring_url"
            />
            <a
              @click="mediaKit.header_image_id = null"
              class="ui label margin-top-small"
            >
              <i class="icon fitted delete" />
              Remove
            </a>
          </div>
        </div>

        <div class="title">
          Name & Profile Picture
          <i class="icon dropdown" />
        </div>
        <div class="content ui form">
          <div class="ui field">
            <label>Name</label>
            <div class="ui input">
              <input
                v-model="mediaKit.title"
                type="text"
                placeholder="Enter the title"
              />
            </div>
          </div>
          <div class="ui field">
            <label>Profile Pic</label>

            <vue-dropzone
              v-if="!mediaKit.avatar_id"
              id="avatarDropzone"
              ref="avatarDropzone"
              :include-styling="false"
              :options="avatarDropzoneOptions"
              @vdropzone-success="avatarUploadComplete"
            />

            <div v-if="mediaKit.avatar_id">
              <img
                id="avatar"
                class="ui circular image small centered"
                :src="mediaKit.avatar.expiring_url"
              />
              <a
                @click="mediaKit.avatar_id = null"
                class="ui label margin-top-small"
              >
                <i class="icon fitted delete" />
                Remove
              </a>
            </div>
          </div>
        </div>
        <!-- </div> -->

        <div class="title">
          About
          <i class="icon dropdown" />
        </div>
        <div class="content ui form">
          <div class="ui field">
            <text-editor
              :key="mediaKit.id + 'about-editor'"
              v-model="mediaKit.about"
              class="editor"
              :autofocus="false"
            />
          </div>
        </div>

        <div v-show="!selectedTemplate.pdfBackground">
          <div class="title">
            Contact
            <i class="icon dropdown" />
          </div>
          <div class="content ui form">
            <div class="ui field">
              <text-editor
                :key="mediaKit.id + '-contact-info-editor'"
                v-model="mediaKit.contact_info"
                class="editor"
                :autofocus="false"
              />
            </div>
            <!-- <div class="field">
              <label>Logo</label>

              <div class="ui image small">
                <vue-dropzone
                  v-if="!mediaKit.logo_id"
                  id="logoDropzone"
                  ref="logoDropzone"
                  :include-styling="false"
                  :options="logoDropzoneOptions"
                  @vdropzone-success="logoUploadComplete"
                />

                <div class="logo">
                  <div class="ui top left floating circular label mini">
                    <i class="icon fitted delete" @click="removeLogo" />
                  </div>
                  <img
                    v-if="mediaKit.logo_id"
                    class="ui"
                    :src="mediaKit.logo.expiring_url"
                  />
                  <div class="ui dimmer inverted">
                    <i class="ui icon trash small grey" />
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <!-- <div v-show="!selectedTemplate.pdfBackground">
          <div class="title">Notes<i class="icon dropdown" /></div>
          <div class="content">
            <div class="ui field">
              <text-editor
                :key="mediaKit.id + 'notes-editor'"
                v-model="mediaKit.notes"
                :autofocus="false"
                class="editor"
                placeholder="Click here to add notes (optional)"
              />
            </div>
          </div>
        </div> -->

        <div v-show="!selectedTemplate.pdfBackground">
          <div class="title">
            Highlights
            <i class="icon dropdown"></i>
          </div>
          <div class="content ui form">
            <div class="ui two column grid">
              <div
                v-for="(highlight, i) of mediaKit.highlights"
                class="column"
                :key="i"
              >
                <div v-if="!highlight._destroy">
                  <vue-dropzone
                    v-if="!highlight.upload_url"
                    :id="`highlight-dropzone-${i}`"
                    ref="highlightDropzone"
                    class="field highlightDropzone"
                    :include-styling="true"
                    :options="highlightDropzoneOptions"
                    @vdropzone-success="
                      highlightUploadComplete(i, ...arguments)
                    "
                  />

                  <div class="field" v-if="highlight.upload_url">
                    <div class="ui image small rounded">
                      <img :src="highlight.upload_url" />
                      <div class="ui top right floating circular label mini">
                        <i
                          class="icon fitted delete"
                          @click="removeHighlight(i)"
                        />
                      </div>
                    </div>

                    <!-- <div
                      class="field"
                      v-if="selectedTemplate.highlight_captions"
                    >
                      <input
                        v-model="highlight.label"
                        type="text"
                        placeholder="Title"
                      />
                    </div>

                    <div
                      class="field"
                      v-if="selectedTemplate.highlight_captions"
                    >
                      <textarea
                        v-model="highlight.description"
                        cols="30"
                        rows="3"
                        placeholder="Description"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <a
              href="#"
              class="ui button small margin-top-small"
              @click.stop="addHighlight"
              >Add Highlight</a
            >
          </div>
        </div>

        <div v-show="!selectedTemplate.pdfBackground">
          <div class="title">Profile Stats<i class="icon dropdown" /></div>
          <div class="content">
            <div class="ui field" v-if="availableProfiles.length > 0">
              <label>Choose profiles:</label>

              <div class="ui fluid multiple search selection dropdown">
                <input
                  type="hidden"
                  name="profile"
                  v-model.lazy="mediaKit.profile_ids"
                />

                <div class="text">Select a profile</div>
                <i class="dropdown icon"></i>
                <div class="menu">
                  <div
                    class="item"
                    v-for="profile in availableProfiles"
                    :key="profile.id"
                    :data-value="profile.id"
                  >
                    <i class="ui icon colored" :class="profile.provider"></i>
                    {{ profile.name }}
                    <span
                      v-if="
                        profile.provider == 'google' &&
                        !profile.url.includes(profile.name)
                      "
                      >({{ profile.url }})</span
                    >
                    <span v-if="!profile.primary_statistic">
                      <i class="icon yellow exclamation triangle"></i>
                      No stats</span
                    >
                    <div class="hidden">{{ profile.provider }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="selectedTemplate.pdfBackground">
          <div class="title">Annotations <i class="icon dropdown"></i></div>
          <div class="content">
            <div class="ui two cards">
              <div class="ui card grey link" draggable data-type="text">
                <div class="content center aligned">
                  <i class="icon align left"></i>
                  Text
                </div>
              </div>

              <div
                class="ui card grey link"
                draggable
                data-type="shape"
                date-shape-type="rect"
              >
                <div class="content center aligned">
                  <i class="icon square outline"></i>
                  Shape
                </div>
              </div>

              <!-- <div class="ui card grey link" draggable data-type="deliverable">
                <div class="content center aligned">
                  <i class="icon linkify"></i>
                  Deliverable
                </div>
              </div> -->
            </div>
          </div>

          <div class="title">Metrics<i class="icon dropdown" /></div>
          <div class="content">
            <div
              v-if="mediaKit.profiles.length > 0"
              class="ui card grey link"
              draggable
              data-type="text"
              data-metric-type="total_followers"
            >
              <div class="content center aligned">
                {{ mediaKit.total_followers | abbreviateNumber }}
                Total Followers
              </div>
            </div>

            <div class="ui field" v-if="availableProfiles.length > 0">
              <label>Add a metric:</label>
              <div class="ui fluid search selection dropdown">
                <input
                  type="hidden"
                  name="profile"
                  v-model.lazy="selectedProfileId"
                />

                <div class="text">Select a profile</div>
                <i class="dropdown icon"></i>
                <div class="menu">
                  <div
                    class="item"
                    v-for="profile in availableProfiles"
                    :key="profile.id"
                    :data-value="profile.id"
                  >
                    <i class="ui icon colored" :class="profile.provider"></i>
                    {{ profile.name }}
                    <div class="hidden">{{ profile.provider }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="selectedProfile" class="ui fluid card margin-top-small">
              <div class="content">
                <div class="header">
                  <i
                    class="ui icon colored"
                    :class="selectedProfile.provider"
                  ></i>
                  {{ selectedProfile.name }}
                  <div class="hidden">{{ selectedProfile.provider }}</div>
                </div>

                <div class="ui two cards margin-top-small">
                  <div
                    class="ui card link"
                    draggable
                    :data-profile-id="selectedProfile.id"
                    :data-metric-type="stat['metric_type']"
                    :data-metric-label="stat['label']"
                    data-type="text"
                    v-for="stat in selectedProfile.stats"
                    :key="stat.metric_type"
                  >
                    <div class="content">
                      {{ stat["value"] }} {{ stat["label"] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- bottom bar -->
    <div id="actionButtons" class="ui right aligned">
      <template v-if="mediaKit.id">
        <a class="ui button tertiary margin-right-small" href="/media_kits">
          <i class="icon left arrow"></i>
          Cancel</a
        >
      </template>

      <div class="right floated">
        <!-- <div
          id="savePdf"
          @click="savePdf"
          class="ui labeled button icon"
          :class="{ loading: savingPdf }"
        >
          <i class="ui icon download"></i>
          Download PDF
        </div> -->

        <template v-if="mediaKit.id">
          <div v-show="!sidebarOpen" class="ui edit button margin-right-small">
            <i class="icon edit" /> Edit
          </div>
          <div
            v-show="sidebarOpen"
            class="ui labeled icon button primary"
            :class="{ loading: loading }"
            @click="triggerSave"
          >
            <i class="icon save"></i>
            Save
          </div>

          <span
            class="ui pink pointer text margin-right-small clipboard"
            :data-clipboard-text="shareUrl"
          >
            <i class="icon share" />
            Copy Share Link
          </span>
          <a
            :href="`/media_kits/${mediaKit.id}`"
            target="_blank"
            class="margin-right-small"
          >
            <i class="icon eye" />
            Preview
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import VueSticky from "vue-sticky";
import Swatches from "vue-swatches";
import textEditor from "components/textEditor.vue";

import templateLoader from "./templates/template_loader.vue";

import VueDropzone from "vue2-dropzone";
import Toasted from "vue-toasted";
import Profiles from "views/reports/sections/socialProfiles.vue";

import _ from "lodash";

import jspdf from "jspdf";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
  singleton: true,
});

const { $ } = window;

export default {
  directives: {
    sticky: VueSticky,
  },
  components: {
    Swatches,
    textEditor,
    VueDropzone,
    Profiles,
    templateLoader,
  },
  props: {
    initialMediaKit: {
      type: Object,
      required: true,
      default: {},
    },
  },
  data() {
    const $this = this;
    return {
      savingPdf: false,
      editable: true,
      templates: {
        // anna: {
        //   background_color: false,
        //   name: "Anna",
        //   primary_color: true,
        // },
        // bennett: {
        //   background_color: true,
        //   name: "Bennett",
        //   primary_color: true,
        // },
        // custom: {
        //   name: "Custom PDF",
        //   pdfBackground: true,
        // },
        default: {
          background_color: false,
          name: "Basic",
          primary_color: false,
        },
        // kathryn: {
        //   background_color: true,
        //   name: "Kathryn",
        //   primary_color: true,
        // },
        // madison: {
        //   background_color: false,
        //   name: "Madison",
        //   primary_color: true,
        // },
      },
      selectedProfileId: null,
      availableProfiles: [],
      loading: false,
      sidebarOpen: false,
      templateOptions: {},
      textEditorConfig: {
        stylesSet: "fomantic-ui",
        autoParagraph: false,
        extraPlugins: "image2,tableresize,emoji,uploadimage",
        removePlugins: "showborders",
        uploadUrl: "/uploads.json",
        resize_maxWidth: "100",
        toolbar: [
          { name: "links", items: ["Link", "Unlink", "Image"] },
          {
            name: "basicstyles",
            items: ["Bold", "Italic", "Underline", "Styles", "RemoveFormat"],
          },
          {
            name: "paragraph",
            items: [
              "NumberedList",
              "BulletedList",
              "-",
              "Outdent",
              "Indent",
              "-",
              "Blockquote",
              "-",
              "JustifyLeft",
              "JustifyCenter",
              "JustifyRight",
              "JustifyBlock",
              "-",
            ],
          },

          { name: "colors", items: ["TextColor", "BGColor"] },
          {
            name: "insert",
            items: ["Table", "HorizontalRule"],
          },
        ],
        filebrowserBrowseUrl: "/uploads",
        filebrowserImageBrowseLinkUrl: "/uploads",
        filebrowserImageBrowseUrl: "/uploads",
        height: 100,
      },
      mediaKit: $this.initialMediaKit,
    };
  },
  computed: {
    selectedProfile() {
      return this.availableProfiles.find(
        (profile) => profile.id === parseInt(this.selectedProfileId)
      );
    },
    selectedTemplate() {
      return this.templates[this.mediaKit.template] || "default";
    },
    cssVars() {
      return {
        "--primary-color": this.mediaKit.primary_color,
        "--background-color": this.mediaKit.background_color,
      };
    },
    shareUrl() {
      return `${document.location.origin}/media_kits/${this.mediaKit.slug}`;
    },
    highlightDropzoneOptions() {
      return this.logoDropzoneOptions;
    },
    avatarDropzoneOptions() {
      return this.logoDropzoneOptions;
    },
    headerImageDropzoneOptions() {
      return this.logoDropzoneOptions;
    },
    logoDropzoneOptions() {
      return {
        maxFilesize: 10,
        thumbnailWidth: 100,
        previewTemplate: `
            <div class="dz-preview dz-file-preview">
              <div class="dz-progress ui progress">
                <div class="dz-upload bar" data-dz-uploadprogress></div>
              </div>
            </div>
            <div class="dz-error-message"><span data-dz-errormessage></span></div>
        `,
        maxFiles: 1,
        // url: `/tenants/${this.report.tenant_id}/settings`,
        url: "/uploads",
        paramName: "data",
        // method: "patch",
        acceptedFiles: "image/*",
        headers: {
          "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content"),
        },
        dictDefaultMessage: "<i class='ui icon cloud upload'></i>",
      };
    },
    pdfBackgroundDropzoneOptions() {
      return {
        maxFilesize: 10,
        thumbnailWidth: 100,
        maxFiles: 1,
        url: `/media_kits/${this.mediaKit.id}`,
        paramName: "media_kit[pdf_background_attributes][data]",
        method: "patch",
        acceptedFiles: "application/*",
        headers: {
          "X-CSRF-Token": window.$('meta[name="csrf-token"]').attr("content"),
        },
        dictDefaultMessage: "<i class='ui icon cloud upload'></i>",
      };
    },
  },
  methods: {
    removeLogo() {
      this.mediaKit.logo_id = null;
      this.mediaKit.logo_url = null;
    },
    savePdf() {
      const $this = this;
      var template = this.$refs.template;

      if (this.selectedTemplate.pdfBackground) {
        $this.$nextTick(() => {
          var stage = template.$refs.component.$refs.stage.getNode();
          var pdf = new jspdf({
            orientation: "p",
            unit: "px",
            format: [stage.width(), stage.height()],
          });

          pdf.addImage(
            stage.toDataURL({ pixelRatio: 2 }),
            0,
            0,
            stage.width(),
            stage.height(),
            "image",
            "MEDIUM"
          );

          pdf.save(`${this.mediaKit.title}.pdf`);
        });
      } else {
        $this.savingPdf = true;
        var node = document.querySelectorAll(
          "#media_kit_form .frame_wrapper > div"
        )[0];
        node.style.width = "850px";

        htmlToImage
          .toCanvas(node, {
            canvasWidth: 850,
            canvasHeight: 1100,
          })
          .then(function (canvas) {
            // console.log(canvas);
            // console.log(canvas.width);
            // console.log(canvas.height);

            var pdf = new jspdf({
              orientation: "p",
              unit: "px",
              format: [850, 1100],
            });

            pdf.addImage(
              canvas.toDataURL({ pixelRatio: 2 }),
              0,
              0,
              850,
              1100,
              "image",
              "NONE"
            );

            pdf.save(`${$this.mediaKit.title}.pdf`);
            window.alert("Done.");
          })
          .catch(function (error) {
            window.alert("Oops, something went wrong!", error);
          })
          .finally(function () {
            // node.style.removeProperty("width");
            $this.savingPdf = false;
          });
      }
    },
    htmlDecode(input) {
      const doc = new DOMParser().parseFromString(input, "text/html");
      return doc.documentElement.textContent;
    },
    deleteAnnotation(ann) {
      console.log("Delete annotation", ann);
      const $this = this;

      var annotationIndex = this.mediaKit.media_kit_annotations.findIndex(
        (annotation) => annotation.id === parseInt(ann.id)
      );
      $this.$delete(this.mediaKit.media_kit_annotations, annotationIndex);

      this.mediaKit.media_kit_annotations_attributes = [
        {
          id: ann.id,
          _destroy: 1,
        },
      ];

      this.save().then(() => {
        var attrIndex = this.mediaKit.media_kit_annotations_attributes.findIndex(
          (annotation) => annotation.id === parseInt(ann.id)
        );
        $this.$delete(
          this.mediaKit.media_kit_annotations_attributes,
          annotationIndex
        );
      });
    },
    addAnnotation(dataset, position) {
      console.log("Add annotation data: ", dataset, position);

      var lastConfig = {};
      if (this.mediaKit.media_kit_annotations.length > 0) {
        const lastAnnotation = this.mediaKit.media_kit_annotations.at(-1);
        lastConfig = lastAnnotation.config;
      }

      var config = Object.assign(
        {
          type: dataset.type,
          dimensions: { x: position.x, y: position.y },
          fontFamily: "Arial",
          fontSize: 16,
          fontWeight: "normal",
          color: "#000000",
          text_color: "#000000",
        },
        _.pick(lastConfig, [
          "fontFamily",
          "fontSize",
          "fontWeight",
          "color",
          "text_color",
          "background_color",
        ])
      );

      var annotation = {
        config,
      };

      if (dataset.profileId) {
        annotation.profile_id = dataset.profileId;
        annotation.config.metric_type = dataset.metricType;
        annotation.config.metric_label = dataset.metricLabel;
        annotation.profile = this.availableProfiles.find(
          (profile) => profile.id === parseInt(dataset.profileId)
        );
      } else if (dataset.metricType) {
        annotation.config.metric_type = dataset.metricType;
      } else {
        if (dataset.type === "shape") {
          annotation.config.dimensions.width = "200";
          annotation.config.dimensions.height = "200";
          annotation.config.shape_type = dataset.shape_type;
        }
        if (dataset.type === "text") {
          // annotation.config.text = dataset.text;
          annotation.label = "Click to update";
        }
      }

      this.mediaKit.media_kit_annotations.push(annotation);

      this.mediaKit.media_kit_annotations_attributes = [annotation];

      const $this = this;
      this.save().then(function (data) {
        $this.mediaKit.media_kit_annotations = data.media_kit_annotations;
      });
    },
    addHighlight() {
      this.mediaKit.highlights.push({
        label: "",
        description: "",
        upload: null,
      });
    },
    removeHighlight(key) {
      const $this = this;
      this.$set(this.mediaKit.highlights[key], "_destroy", true);
      this.save().then(() => {
        $this.$delete(this.mediaKit.highlights, key);
      });
    },
    removePdfBackground() {
      const $this = this;
      this.mediaKit.pdf_background_attributes = {
        id: this.mediaKit.pdf_background.id,
        _destroy: 1,
      };

      this.save().then(() => {
        $this.$set(this.mediaKit, "pdf_background", null);
      });
    },
    pdfBackgroundUploadComplete(_file, response) {
      this.$set(this.mediaKit, "pdf_background", response.pdf_background);
    },
    highlightUploadComplete(i, file, upload) {
      var val = {
        upload: {
          id: upload.asset.id,
        },
        upload_url: upload.asset.url_content,
        label: "",
        description: "",
      };

      this.$set(this.mediaKit.highlights, i, val);
      this.save();
    },
    avatarUploadComplete(file, avatar) {
      this.$refs.avatarDropzone.removeFile(file);
      this.$set(this.mediaKit, "avatar", avatar.asset);
      this.$set(this.mediaKit, "avatar_id", avatar.asset.id);
      this.$set(this.mediaKit, "avatar_url", avatar.asset.url_content);
    },
    logoUploadComplete(file, logo) {
      this.$refs.logoDropzone.removeFile(file);
      this.$set(this.mediaKit, "logo", logo.asset);
      this.$set(this.mediaKit, "logo_id", logo.asset.id);
      this.$set(this.mediaKit, "logo_url", logo.asset.url_content);
    },
    headerImageUploadComplete(file, headerImage) {
      this.$refs.headerImageDropzone.removeFile(file);
      this.$set(this.mediaKit, "header_image", headerImage.asset);
      this.$set(this.mediaKit, "header_image_id", headerImage.asset.id);
      this.$set(
        this.mediaKit,
        "header_image_url",
        headerImage.asset.url_content
      );
    },
    save: _.debounce(
      async function () {
        const $this = this;
        return new Promise((resolve, reject) => {
          $.ajax({
            url: `/media_kits${
              $this.mediaKit.id ? `/${$this.mediaKit.id}` : ""
            }`,
            type: $this.mediaKit.id ? "patch" : "post",
            dataType: "json",
            data: {
              media_kit: $this.mediaKit,
            },
            success(data) {
              $this.mediaKit.id = data.id;
              $this.mediaKit.slug = data.slug;
              $this.mediaKit.total_followers = data.total_followers;
              $this.mediaKit.profiles = data.profiles;
              $this.mediaKit.logo_url = data.logo_url;
              $this.mediaKit.avatar_url = data.avatar_url;
              $this.mediaKit.header_image_url = data.header_image_url;

              data.highlights.forEach((highlight, index) => {
                // $this.mediaKit.highlights[index].id = highlight.id;
                $this.$set(
                  $this.mediaKit.highlights[index],
                  "id",
                  highlight.id
                );
              }); //update the highlight ids

              // $this.mediaKit.media_kit_annotations = data.media_kit_annotations;

              window.history.pushState(
                {},
                "",
                `/media_kits/${$this.mediaKit.id}/edit`
              );
              Vue.toasted.success("Saved");
              $this.loading = false;
              resolve(data);
            },
            error(data) {
              $this.loading = false;
              Vue.toasted.error(`Error! ${data.responseJSON.errors}`);
              reject(data);
            },
          });
        });
      },
      500,
      { leading: true }
    ),
    triggerSave(val, oldVal) {
      if (!this.mediaKit.id && !this.mediaKit.title) {
        return;
      }

      if (val != oldVal) {
        this.loading = true;
        this.save();
      }
    },

    // handlePdfUpload(e) {
    //   let fileList = e.target.files;
    //   this.pdfToImage(fileList[0]);
    // },
    resizeFrame() {
      let wrapperLeft = $("#media_kit_form .frame_wrapper").offset().left;
      let sidebarLeft = $(this.$refs.sidebar).offset().left;

      let visibleWidth = sidebarLeft - wrapperLeft;
      let totalWidth = $("body").width() - wrapperLeft;

      let scalingFactor = (visibleWidth / totalWidth) * 1.3;

      console.log("Scaling factor:", scalingFactor);

      $("#media_kit_form .frame_wrapper").css({
        transform: `scale(${scalingFactor})`,
        transformOrigin: "0 0",
      });
      $("#media_kit_form iframe")
        .contents()
        .find("html")
        .css({
          transform: `scale(${scalingFactor})`,
          transformOrigin: "center 0",
        });

      let bodyHeight = $("body").height();
      let wrapperTop = $("#media_kit_form .frame_wrapper").offset().top;
      let bottomBarHeight = $("#bottom_bar").outerHeight();

      $("#media_kit_form .frame_wrapper").css({
        height: `${
          (bodyHeight - wrapperTop - bottomBarHeight) / scalingFactor
        }px`,
      });
      $("#media_kit_form iframe")
        .contents()
        .find("html")
        .css({ height: `${(bodyHeight - wrapperTop) / scalingFactor}px` });
    },
    loadAvailableProfiles() {
      const $this = this;
      const url = "/profiles.json";

      $.read(url)
        .done((data) => {
          data.forEach((profile) => {
            $this.availableProfiles.push(profile);
          });
        })
        .then(() => {
          $this.initDropowns();
        });
    },
    initDropowns() {
      $(this.$refs.sidebar)
        .find(".dropdown")
        .each((i, element) => {
          if (!$(element).data("dropdown-loaded")) {
            $(element)
              .dropdown({
                clearable: $(element).data("clearable") || false,
                direction: $(element).data("direction") || "auto",
                allowAdditions: $(element).data("additions") || false,
                placeholder: $(element).data("placeholder") || "auto",
                on: $(element).data("on") || "click",
                values: $(element).data("values"),
                fullTextSearch: true,
                forceselection: $(element).data("forceSelection") || false,
              })
              .closest(".dropdown")
              .data("dropdown-loaded", true);
          }
        });
    },
  },
  watch: {
    savingPdf(val) {
      if (val) {
        this.editable = false;
        if (this.sidebarOpen) {
          $(this.$refs.sidebar).sidebar("hide");
        }
      } else {
        this.editable = true;
      }
    },
    "mediaKit.title": {
      handler: "triggerSave",
    },
    "mediaKit.template": {
      handler: "triggerSave",
    },
    "mediaKit.primary_color": {
      handler: "triggerSave",
    },
    "mediaKit.background_color": {
      handler: "triggerSave",
    },
    "mediaKit.about": {
      handler: "triggerSave",
    },
    "mediaKit.contact_info": {
      handler: "triggerSave",
    },
    "mediaKit.notes": {
      handler: "triggerSave",
    },
    // "mediaKit.highlights": {
    //   handler: "triggerSave",
    //   deep: true,
    // },
    "mediaKit.avatar_id": {
      handler: "triggerSave",
    },
    "mediaKit.logo_id": {
      handler: "triggerSave",
    },
    "mediaKit.header_image_id": {
      handler: "triggerSave",
    },
    "mediaKit.profile_ids": {
      handler: "triggerSave",
    },
  },
  mounted() {
    const $this = this;

    this.$nextTick(() => {
      $this.loadAvailableProfiles();

      $($this.$refs.sidebar)
        .sidebar({
          dimPage: false,
          transition: "overlay",
          onShow: this.resizeFrame,
          onVisible() {
            $this.sidebarOpen = true;
          },
          onHide() {
            $this.sidebarOpen = false;
          },
          onHidden: this.resizeFrame,
          closable: false,
        })
        .sidebar("attach events", ".edit.button, .cancel")
        .sidebar("show");
      $this.sidebarOpen = true;
      $this.resizeFrame();

      $($this.$refs.accordion).accordion({
        exclusive: true,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.ui.accordion {
  border: none !important;
  box-shadow: none !important;

  .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .title {
    padding-left: 0 !important;
    padding-top: 22px !important;
    padding-bottom: 22px !important;
    color: rgba(0, 0, 0, 0.87);
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;

    .dropdown.icon {
      float: right;
      rotate: -90deg;
    }

    &.active {
      .dropdown.icon {
        rotate: 0deg;
      }
    }
  }
}

#logoDropzone,
#avatarDropzone,
#headerImageDropzone {
  width: 150px;
  height: 80px;
  margin-right: 0.5em;
  border: 2px dashed rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  transition: border 200ms ease-out;

  &:before {
    content: "\f382";
    font-family: Icons;
    color: rgba(0, 0, 0, 0.22);
    display: flex;
    height: 60%;
    justify-content: center;
    align-items: center;
    font-size: 26px !important;
  }
  &:after {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.22);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.dz-started {
    &:before,
    &:after {
      content: "";
    }
  }
  &:hover {
    border-color: #ccc;
    &:before,
    &:after {
      color: #ccc;
    }
  }
}

#logoDropzone:not(.dz-started):after {
  content: "Your logo";
}
#avatarDropzone:not(.dz-started):after {
  content: "Your profile pic";
}

#headerImageDropzone:not(.dz-started):after {
  content: "Cover Image";
}

.highlightDropzone:not(.dz-started):after {
  content: "Highlighted image";
  font-size: 14px;
  color: rgba(0, 0, 0, 0.22);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#actionButtons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  height: 65px;
  padding: 1em;
  padding-left: 100px;
  z-index: 10;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.dropzone {
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.02);

  font-size: 1.5em;
}

.ui.image {
  .floating.label {
    display: none;
    z-index: 1 !important;
  }
  &:hover {
    .floating.label {
      display: block;
    }
  }
}

.sidebar {
  background: #fff;
  padding: 1em;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
</style>

<style lang="scss">
.media_kit .frame_wrapper {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary-color) !important;
  }

  a,
  a:hover,
  a:visited {
    color: var(--primary-color) !important;
  }

  .icon.large {
    color: var(--primary-color) !important;
  }
}
</style>
