<template>
	<th v-if="isHeader" class="vuetable-th-component-checkbox">
		<div class="ui checkbox margin-left-tiny">
			<input
				type="checkbox"
				@change="toggleAllCheckbox($event)"
				:checked="isAllItemsInCurrentPageSelected()"
			/>
		</div>
	</th>
	<td v-else class="vuetable-td-component-checkbox">
		<div class="ui checkbox margin-left-tiny">
			<input
				type="checkbox"
				@change="toggleCheckbox(rowData, $event)"
				:checked="isSelected(rowData)"
			/>
		</div>
	</td>
</template>
<script>
import VuetableFieldCheckboxMixin from "vuetable-2/src/components/VuetableFieldCheckboxMixin.vue";

const $ = window.jQuery;

export default {
	name: "checkbox-field",
	mixins: [VuetableFieldCheckboxMixin],
	mounted() {
		$(this.$el)
			.find(".ui.checkbox")
			.checkbox();
	}
};
</script>
