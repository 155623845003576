import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import DeliverablesIndexView from "views/deliverables/index.vue";
import DeliverableForm from "views/deliverables/form.vue";

document.addEventListener("turbo:load", function () {
  if (document.getElementById("deliverables-index-view")) {
    new Vue({
      el: "#deliverables-index-view",
      directives: {},
      components: {
        DeliverablesIndexView,
      },
    });
  }
});

document.addEventListener("turbo:load", function () {
  document.querySelectorAll("deliverable-form").forEach((el) => {
    new Vue({
      el: el,
      components: {
        DeliverableForm,
      },
    });
  });
});
