// import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import CategoryIndex from "views/categories/index.vue";

document.addEventListener("turbo:load", function () {
  if (document.getElementById("category-index")) {
    window.calendarHeader = new Vue({
      el: "#category-index",
      components: {
        CategoryIndex,
      },
    });
  }
});
