<template lang="html">
  <div
    class="ui form clearing"
    @keypress.enter="save"
  >
    <div class="inline fields margin-bottom-zero">
      <div class="ui sixteen wide field input">
        <input
          ref="nameInput"
          v-model="event.name"
          autofocus
          placeholder="Name"
        >
      </div>

      <a
        class="ui button primary"
        :class="{ loading }"
        @click="save"
      >
        Save
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "store";
import ClickOutside from "vue-click-outside";

export default {
  store,
  components: {},
  filters: {},
  directives: {
    ClickOutside,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("event", {
      event: "newEvent",
      error: "error",
      loading: "loading",
    }),
  },
  methods: {
    ...mapActions("event", {
      create: "create",
    }),
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (!this.event.name || this.event.name === "") {
        return;
      }
      this.create(this.event).finally(() => {
        this.$emit("save");
      });
    },
    loadEventModal() {
      window.EventBus.$emit("openEventModal", this.event.url);
    },
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.$refs.nameInput.focus();
    });
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.nameInput.focus();
    });
  },
};
</script>

<style lang="scss" scoped></style>
