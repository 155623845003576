import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import DeliverableCard from "views/deliverables/components/deliverableCard.vue";
import InstagramStoriesCard from "views/deliverables/components/instagramStoriesCard.vue";
import ProfileCard from "views/deliverables/components/profileCard.vue";

window.initDeliverableCard = function () {
  if (document.querySelector(".deliverable-report-card")) {
    document.querySelectorAll(".deliverable-report-card").forEach((el) => {
      new Vue({
        el: el,
        components: {
          "deliverable-card": DeliverableCard,
        },
      });
    });
  }
  if (document.querySelector(".instagram-story-card")) {
    document.querySelectorAll(".instagram-story-card").forEach((el) => {
      new Vue({
        el: el,
        components: {
          "instagram-stories-card": InstagramStoriesCard,
        },
      });
    });
  }
  if (document.querySelector(".profile-report-card")) {
    document.querySelectorAll(".profile-report-card").forEach((el) => {
      new Vue({
        el: el,
        components: {
          "profile-card": ProfileCard,
        },
      });
    });
  }
};
// document.addEventListener("turbo:frame-load", function () {
//   window.initDeliverableCard();
// });
document.addEventListener("turbo:load", function () {
  window.initDeliverableCard();
});
