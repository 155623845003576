import consumer from "./consumer";

window.deliverablesChannel = function (deliverableId, action, callback) {
  return consumer.subscriptions.create(
    { channel: "DeliverablesChannel", id: deliverableId },
    {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // console.log(data)
        if (data.action == action) {
          callback(data);
        }
      },
    }
  );
};
