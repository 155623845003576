import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import ShowMediaKitView from "views/media_kits/show.vue";

document.addEventListener("turbo:load", function () {
  if (document.getElementById("show-media-kit-view")) {
    new Vue({
      el: "#show-media-kit-view",
      components: {
        ShowMediaKitView,
      },
    });
  }
});
