<template>
  <div class="padding-left-medium padding-right-mini padding-y-small">
    <div class="ui six cards">
      <div
        v-for="(stat, type) in stats"
        :class="`${type}-card`"
        class="ui card"
      >
        <div class="content content-header flex-grow-0">
          <div class="header">
            <i
              class="icon colored"
              :class="`${inferredTypeAsIconClass(type)}`"
            />
            {{ cardHeader(type) }}
          </div>
          <div class="meta">
            {{ stat.count }} Deliverable{{ stat.count > 1 ? "s" : "" }}
          </div>
        </div>
        <div class="content padding-top-zero">
          <div class="ui two statistics mini left aligned">
            <div v-for="(v, k) in stat.metrics" class="statistic">
              <div class="value">
                {{ v | abbreviateNumber }}
              </div>
              <div class="label">
                {{ k }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import "functions/filters";

const { $ } = window;

export default {
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
    },
    options: {
      type: Object,
    },
  },
  data() {
    return {
      event: this.rowData,
      stats: [],
    };
  },
  computed: {
    ...mapGetters("deliverable", ["inferredTypeAsIconClass"]),
  },
  mounted() {
    console.log("shown: " + this.event.id);
    this.loadData();
  },
  methods: {
    cardHeader(type) {
      switch (type) {
        case "url":
          return "Articles";
        case "instagram_stories":
          return "IG Stories";
        case "one_signal":
          return "Push Notifications";
        case "push_engage":
          return "Push Notifications";
        case "tik_tok":
          return "TikTok";
        default:
          return type[0].toUpperCase() + type.slice(1);
      }
    },

    loadData() {
      $.read(`/events/${this.event.id}/stats`).then((res) => {
        this.stats = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ui.card {
  .content {
    border: none;
  }
  .content-header {
    .header {
      font-size: 1.142857143em !important;
    }
    .meta {
      font-size: 0.857142857em !important;
    }
  }
}

.ui.statistics {
  .statistic {
    .value {
      color: #0d0d0d;
      font-size: 1rem;
      font-weight: bold;
    }
    .label {
      color: #545454;
      font-size: 10px;
      letter-spacing: 0.5px;
      line-height: 12px;
    }
  }
}

.ui.card.youtube-card .content-header {
  background: linear-gradient(180deg, #ff0000 8px, #fff 8px);
}
.ui.card.facebook-card .content-header {
  background: linear-gradient(180deg, #476eb1 8px, #fff 8px);
}
.ui.card.pinterest-card .content-header {
  background: linear-gradient(180deg, #c0001d 8px, #fff 8px);
}
.ui.card.rss-card .content-header,
.ui.card.url-card .content-header,
.ui.card.google-card .content-header {
  background: linear-gradient(180deg, lighten(#f4806f, 5%) 8px, #fff 8px);
}
.ui.card.twitter-card .content-header {
  background: linear-gradient(180deg, #009dea 8px, #fff 8px);
}

.ui.card.mailchimp-card {
  .content-header {
    background: linear-gradient(#239ab9 8px, #fff 8px);
  }
}

.ui.card.aweber-card {
  .content-header {
    background: linear-gradient(180deg, #2072b9 8px, #fff 8px);
  }
}

.ui.card.convertkit-card {
  .content-header {
    background: linear-gradient(#fb6970 8px, #fff 8px);
  }
}

.ui.card.drip-card {
  .content-header {
    background: linear-gradient(#f224f2 8px, #fff 8px);
  }
}

.ui.card.sendfox-card {
  .content-header {
    background: linear-gradient(#fd6655 8px, #fff 8px);
  }
}

.ui.card.activecampaign-card {
  .content-header {
    background: linear-gradient(rgba(57, 110, 226, 1) 8px, #fff 8px);
  }
}

.ui.card.madmimi-card {
  .content-header {
    background: linear-gradient(#86c3ea 8px, #fff 8px);
  }
}

.ui.card.instagram_stories-card {
  .content-header {
    background: linear-gradient(#ac279f 8px, #fff 8px);
  }
}

.ui.card.one_signal-card,
.ui.card.push_engage-card {
  .content-header {
    background: linear-gradient(#e24b4d 8px, #fff 8px);
  }
}

.ui.card.art19-card {
  .content-header {
    background: linear-gradient(#1373b6 8px, #fff 8px);
  }
}

.ui.card.instagram-card {
  .content-header {
    background: linear-gradient(#ac279f 8px, #fff 8px);
  }
}

.ui.card.other-card {
  .content-header {
    background: linear-gradient(#cccccc 8px, #fff 8px);
  }
}
</style>
