import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import Troubleshooter from "views/deliverables/troubleshooter.vue";

window.initTroubleshooters = function () {
  window.jQuery("troubleshooter").each((i, el) => {
    new Vue({
      el: el,
      directives: {},
      components: {
        Troubleshooter,
      },
    });
  });
};

// document.addEventListener("turbo:load", function () {
//   window.initTroubleshooters()
// })
