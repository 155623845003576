import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import eventsTable from "views/events/table.vue";
import store from "store";

document.addEventListener("turbo:load", function () {
  if (document.getElementById("eventsTable")) {
    window.eventsTableView = new Vue({
      el: "#eventsTable",
      store,
      components: {
        eventsTable,
      },
      data() {
        return {};
      },
    });
  }
});
