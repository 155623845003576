<template lang="html">
  <div>
    <div ref="categoryPopup" class="ui very wide popup">
      <category-list
        v-if="typeof event.calendar_id != 'undefined'"
        :endpoint="categoriesEndpoint"
        @updated="updateCategories"
      />
    </div>

    <div class="fields">
      <div
        v-show="canUpdateCalendar && calendars.length > 1"
        class="six wide field"
      >
        <label> Calendar</label>
        <select
          v-model.lazy="event.calendar_id"
          class="ui dropdown selection nowrap"
        >
          <option v-for="option in calendarsAsOptions" :value="option.value">
            <div
              class="ui left floated red empty circular label"
              :style="{ 'background-color': option.color + ' !important' }"
            />
            {{ option.label }}
          </option>
        </select>
      </div>

      <div v-show="showUserField" class="five wide field users">
        <label> Assigned to</label>
        <select
          v-model.lazy="event.user_id"
          class="ui dropdown compact selection nowrap"
        >
          <option v-for="option in usersAsOptions" :value="option.value">
            <avatar
              class="ui image avatar margin-right-mini"
              :username="option.label"
              :src="option.gravatar"
              size="22"
            />
            {{ option.label }}
          </option>
        </select>
      </div>
      <div v-if="showEmailField" class="five wide field">
        <label>User E-Mail</label>
        <input v-model="event.email" type="text" />
      </div>

      <div
        v-show="categories.length > 0"
        ref="categorySelection"
        class="five wide field categories"
      >
        <label>
          Category

          <i
            v-show="event.id"
            ref="categorySelectionTrigger"
            class="icon link grey horizontal ellipsis small"
          />
        </label>
        <select
          v-model.lazy="event.event_category_id"
          class="ui dropdown selection nowrap"
          data-placeholder="Select one"
        >
          <option value="">No category</option>

          <option
            v-for="option in categoriesAsOptions"
            :key="`${option.label}${option.color}`"
            :value="option.value"
          >
            <div
              class="ui left floated red empty circular label"
              :style="{ 'background-color': option.color + ' !important' }"
            />
            {{ option.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import store from "store";
import Avatar from "components/avatar.vue";
import categoryList from "views/categories/index";

const $ = window.jQuery;

export default {
  store,
  components: {
    Avatar,
    categoryList,
  },
  props: {
    calendarId: String,
    userId: String,
    userEmail: String,
    categoryId: Number,
    canUpdateCalendar: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("event", {
      event: "current",
    }),
    ...mapState("calendar", ["calendars"]),
    categories() {
      return this.selectedCalendar
        ? this.selectedCalendar.event_categories
        : [];
    },
    users() {
      return this.selectedCalendar ? this.selectedCalendar.users : [];
    },
    categoriesEndpoint() {
      return `/calendars/${this.event.calendar_id}/event_categories`;
    },
    showUserField() {
      return (
        this.canUpdateCalendar &&
        this.users.length > 0 &&
        (this.event.user_id || !this.event.email)
      );
    },
    showEmailField() {
      return this.event.email && !this.event.user_id;
    },
    selectedCalendar() {
      return this.calendars.find((obj) => obj.id === this.event.calendar_id);
    },
    calendarsAsOptions() {
      return this.calendars.map((c) => ({
        text: c.name,
        label: c.name,
        value: c.id,
        color: c.color,
      }));
    },
    categoriesAsOptions() {
      return this.categories.map((c) => ({
        label: c.name,
        name: c.name,
        value: c.id,
        color: c.color,
      }));
    },
    usersAsOptions() {
      return this.users.map((u) => ({
        label: u.login,
        value: u.id,
        gravatar: u.gravatar,
      }));
    },
  },

  watch: {
    "event.user_id": function (newVal) {
      // this feels very wrong
      const user = _.find(this.users, { id: newVal });
      if (user) {
        this.event.username = user.login;
      }
    },
    "event.calendar_id": function (newVal, oldVal) {
      if (!oldVal) return;
      if (newVal !== oldVal) {
        $(this.$el).find(".categories .dropdown").dropdown("clear");

        if (!this.users.map((u) => u.id).includes(this.event.user_id)) {
          $(this.$el).find(".users .dropdown").dropdown("clear");
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initCategoryPopup();
    });
  },
  methods: {
    updateCategories() {
      this.$store.dispatch("calendar/reloadCalendars");
    },
    initCategoryPopup() {
      const $this = this;
      $(this.$refs.categorySelectionTrigger).popup({
        popup: $($this.$refs.categoryPopup),
        exclusive: false,
        position: "bottom center",
        transition: "drop",
        // movePopup: false,
        forcePosition: true,
        lastResort: "bottom center",
        on: "click",
      });
      // .popup("show");
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  label {
    i.ellipsis {
      visibility: hidden;
    }
  }
  &:hover label {
    i.ellipsis {
      visibility: visible;
    }
  }
}
</style>
