<template>
  <div>
    <input
      type="hidden"
      :name="name"
      value=""
    >

    <select
      v-model="selectedUserIds"
      multiple
      :name="name"
      style="display:none"
    >
      <option
        v-for="user in selectedUsers"
        :key="`${name}-${user.id}`"
        selected
        :value="user.id"
      >
        {{ user.name }}
      </option>
    </select>
    <div
      ref="dropdown"
      class="ui dropdown search selection"
    >
      <div class="default text">
        Select users
      </div>
      <i class="icon dropdown" />
      <div class="menu">
        <div
          v-for="user in selectableUsers"
          :key="`${name}-${user.id}-item`"
          :data-value="user.id"
          class="item"
        >
          <v-avatar
            class="ui image avatar margin-right-small"
            :username="user.login"
            :src="user.gravatar"
            size="24"
          />{{ user.login }}
        </div>
      </div>
    </div>

    <div class="ui relaxed divided middle aligned list">
      <div
        v-for="user in selectedUsers"
        :key="`${name}-${user.id}-user`"
        class="item"
      >
        <div class="right floated content">
          <i
            class="icon minus link circle grey large fitted"
            @click.stop.prevent="removeUser(user.id)"
          />
        </div>

        <v-avatar
          class="ui image avatar"
          :username="user.login"
          :src="user.gravatar"
          size="24"
        />

        <div class="content">
          {{ user.login }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const { $ } = window;

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      allUsers: [],
      selectedUsers: this.selected,
    };
  },

  computed: {
    selectedUserIds() {
      return this.selectedUsers.map((u) => u.id);
    },
    selectableUsers() {
      return this.allUsers.filter((u) => !this.selectedUserIds.includes(u.id));
    },
  },

  mounted() {
    this.loadUsers();
    this.initDropdown();
  },

  methods: {
    loadUsers() {
      $.get("/users.json").then((data) => {
        this.allUsers = data;
      });
    },

    addUser(name, id) {
      let intId = parseInt(id);

      const index = this.allUsers.findIndex((u) => u.id === intId);

      if (index != -1) {
        const user = this.allUsers[index];
        this.selectedUsers.push(user);
        // this.allUsers.splice(index, 1);
      }
    },

    removeUser(id) {
      let intId = parseInt(id);

      const index = this.selectedUsers.findIndex((u) => u.id === intId);

      if (index != -1) {
        const user = this.selectedUsers[index];
        // this.allUsers.push(user);
        this.selectedUsers.splice(index, 1);
      }
    },
    initDropdown() {
      $(this.$refs.dropdown)
        .dropdown({
          forceSelection: false,
          action: this.addUser,
        })
        .data("dropdown-loaded", true);
    },
  },
};
</script>

<style lang="scss" scoped>
v-avatar {
  height: 24px !important;
}

.ui.list .item {
  .content.right.floated {
    margin-top: 0.25em;
  }
}
</style>
