import stimulusRailsNestedForm from "stimulus-rails-nested-form";

export default class extends stimulusRailsNestedForm {
  connect() {
    super.connect();
  }

  // remove(e) {
  //   super.remove(e);
  // }
}
