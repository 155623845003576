<template>
  <div ref="index">
    <section class="taskapp">
      <div class="clearing margin-bottom-small">
        <div
          v-if="showSearch || showFilters || currentUserId"
          class="ui right floated secondary menu tiny stackable"
          :class="{ secondary: !showSearch }"
        >
          <div v-if="showSearch" class="ui search item fitted">
            <div :class="{ loading }" class="ui icon input">
              <input
                v-model="searchString"
                v-debounce.cancelOnEmpty="300"
                type="text"
                placeholder="Search ..."
                @keypress.enter="loadPage(1)"
                @change="loadPage(1)"
              />

              <i
                v-if="searchString.length < 1"
                class="search link icon"
                @click="loadPage(1)"
              />
              <i v-else class="delete link icon" @click="clearSearch" />
            </div>
          </div>

          <div
            v-if="tasks.length > 1 && Object.keys(availableSorts).length > 1"
            class="ui dropdown white item sorts"
          >
            <span class="capitalize">
              Sort: <span class="nobreak">{{ selectedSortName }}</span>
            </span>
            <div class="menu">
              <div
                v-for="(sort, key) in availableSorts"
                :key="key"
                class="item"
                :class="{ active: selectedSort == key }"
                @click="selectedSort = key"
              >
                {{ sort.name }}
              </div>
            </div>
          </div>

          <div
            v-if="tasks.length > 1 && showFilters"
            class="ui dropdown white item filters"
          >
            <span class="capitalize"
              >Filter: <span class="nobreak">{{ filter }}</span></span
            >
            <filters :selected="filter" @change="changeFilter" />
          </div>
        </div>
        <h4
          v-if="tasks.length > 1"
          class="ui left floated header padding-top-small"
        >
          Tasks
        </h4>
      </div>

      <header v-if="allowAdditions" class="header">
        <div class="ui form segment">
          <task-input
            ref="newTaskInput"
            placeholder="Enter a task here"
            :task="newTask"
            :users="users"
            :allow-cancel="false"
            :parent-event="parentEvent"
          />

          <a
            v-if="suggestedEventTasks.length > 0"
            href="#"
            @click.stop.prevent="showSuggested = !showSuggested"
          >
            <i
              class="icon caret fitted"
              :class="{
                right: !showSuggested,
                down: showSuggested,
              }"
            />
            Load Suggested Tasks
          </a>

          <div
            v-show="suggestedEventTasks.length > 0 && showSuggested"
            class="ui message"
          >
            <div class="ui list">
              <div v-for="task in suggestedEventTasks" class="task item">
                <div class="content">
                  <div class="ui checkbox left floated checked">
                    <input class="hidden" type="checkbox" checked />
                    <label>
                      <span>{{ task.name }}</span>
                    </label>
                  </div>
                </div>

                <div class="ui right floated">
                  <avatar
                    v-if="task.user_login"
                    class="ui image avatar"
                    :title="task.user_login"
                    :username="task.user_login"
                    :src="task.user_gravatar"
                    size="26"
                  />
                </div>
              </div>
            </div>
            <a
              class="ui button"
              @click.stop.prevent="addSuggestedTasks(suggestedEventTasks)"
            >
              <i class="ui icon list" />
              Add these tasks
            </a>

            <div class="ui simple dropdown" v-if="users.length > 1">
              <i class="icon grey dropdown"></i>
              <div class="menu icon buttons left">
                <div class="header">
                  <i class="icon exchange"></i>
                  Assign all to:
                </div>
                <div
                  class="item"
                  :key="`${user.id}-suggested-task-selection`"
                  v-for="user in this.users"
                  @click.stop.prevent="assignSuggestedTasksToUser(user)"
                >
                  {{ user.login }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div v-if="tasks.length < 1" class="ui message">
        You have no tasks. Rock on.
        <i class="hand rock outline icon large" />
      </div>

      <section v-if="tasks.length > 0" :class="{ loading }" class="ui form">
        <small v-if="selectedUser">
          Showing {{ selectedUser.login }}'s tasks
          <a href="#" @click.stop.prevent="showAll()"> (show all) </a>
        </small>

        <task-list
          class="clearboth"
          :users="users"
          :tasks="filteredTasks"
          :group="group"
          :parent-event="parentEvent"
          :filter="filter"
          @selectUser="showUsersTasks"
          @loadPage="loadPage"
        />
      </section>
    </section>

    <div v-if="showSaveAll">
      <a class="ui button primary" @click.stop.prevent="saveAll">
        Save All Tasks
      </a>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState, mapGetters } from "vuex";
import EventBus from "components/eventbus";
import _ from "lodash";
import store from "store";
import debounce from "v-debounce";
import Avatar from "components/avatar.vue";

// import VueRouter from "vue-router";
// import filters from "functions/FilterFunctions";
import TaskInput from "./TaskInput.vue";
import TaskList from "./TaskList.vue";
import Filters from "./Filters.vue";

// Vue.use(VueRouter);

const $ = window.jQuery;

// const routes = [
//   { path: "/all", component: TaskList, props: { filter: "all" } },
//   { path: "/completed", component: TaskList, props: { filter: "completed" } },
//   { path: "/active", component: TaskList, props: { filter: "active" } },
//   { path: "*", redirect: "/all" }
// ];
// const router = new VueRouter({
//   routes,
//   base: window.location.href,
//   linkActiveClass: "active"
// });

export default {
  store,
  directives: { debounce },
  components: {
    TaskInput,
    TaskList,
    Filters,
    Avatar,
  },

  props: {
    currentUserId: null,
    showSearch: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: false,
    },
    allowAdditions: {
      type: Boolean,
      default: true,
    },
    parentEvent: {
      type: Object,
      default: null,
    },
    initialTasks: Array,
    initialSorts: {
      type: Array,
      default() {
        return ["event", "user", "date", "name"];
      },
    },
    initialSort: {
      type: String,
      default: "event",
    },
    endpoint: String,
    // users: Array
  },
  data() {
    const $this = this;
    return {
      // users: [],
      showSuggested: false,
      filter: $this.getLocalStorage("filter", "all"),
      selectedSort: $this.getLocalStorage("selectedSort", $this.initialSort),
      sorts: {
        event: {
          object: { key: "event", label: "name", sort: "starts_at" },
          name: "Campaign Date",
        },
        user: {
          object: { key: "user", label: "login", sort: "user_login" },
          name: "User",
        },
        date: { object: { sort: "due_on" }, name: "Due Date" },
      },
      msg: "A new task",
      searchString: "",
      selectedUser: $this.getLocalStorage("selectedUser", null),
    };
  },
  computed: {
    ...mapState("task", {
      loading: "loading",
      error: (state) => state.error,
    }),
    ...mapState("calendar", ["calendars"]),
    ...mapGetters("calendar", {
      categories: "categories",
      users: "users",
    }),
    queryParams() {
      const $this = this;
      return {
        user_id: $this.selectedUserId,
        q: {
          user_login_or_event_name_or_name_cont: $this.searchString,
          task_status_eq: $this.filter,
        },
        sort: this.sorts[this.selectedSort].object.sort,
      };
    },

    selectedUserId() {
      return this.selectedUser && this.selectedUser.id;
    },
    newTask() {
      return this.$store.getters["task/new"](this.parentEvent);
    },
    tasks() {
      return this.$store.getters["task/all"](this.parentEvent);
    },
    suggestedEventTasks() {
      return this.$store.getters["task/suggestedEventTasks"](this.parentEvent);
    },
    availableSorts() {
      const $this = this;
      return _.pickBy($this.sorts, (value, key) =>
        $this.initialSorts.includes(key)
      );
    },
    selectedSortName() {
      return this.availableSorts[this.selectedSort].name;
    },
    group() {
      return (
        this.sorts[this.selectedSort] && this.sorts[this.selectedSort].object
      );
    },
    filteredTasks() {
      return this.tasks;
    },
    hiddenTasks() {
      return this.tasks.length - this.filteredTasks.length;
    },
    remaining() {
      return this.tasks.filter((t) => !t.completed).length;
    },
    showSaveAll() {
      return this.hasUnsavedTasks;
    },
    hasUnsavedTasks() {
      return _.some(this.tasks, (t) => !t.id);
    },
    areAllCompleted: {
      get() {
        return this.remaining === 0;
      },
      set(value) {
        this.tasks.map((task) => {
          this.toggleComplete(value, task);
          return task;
        });
      },
    },
  },
  watch: {
    selectedUser(val) {
      this.setLocalStorage("selectedUser", JSON.stringify(val));
    },
    selectedUserId() {
      this.loadPage(1);
    },
    selectedSort(val) {
      this.loadTasks();
      this.setLocalStorage("selectedSort", JSON.stringify(val));
    },
    filter(val) {
      this.setLocalStorage("filter", JSON.stringify(val));
      this.loadTasks();
    },
    hasUnsavedTasks(val) {
      if (val) {
        EventBus.$emit("eventModalDirty", "tasks");
      } else {
        EventBus.$emit("eventModalClean", "tasks");
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("calendar/loadCalendars");

      if (this.initialTasks) {
        this.setTasks(this.initialTasks);
      } else {
        this.loadTasks();
      }

      this.initDropdowns();
    });
  },
  updated() {
    // this.initDropdowns();
    $(this.$el)
      .find(".right.floated.secondary.menu .dropdown")
      .dropdown("refresh");
  },
  methods: {
    clearSearch() {
      this.searchString = "";
      this.loadPage(1);
    },
    changeFilter(val) {
      this.filter = val;
    },
    setTasks(tasks) {
      this.$store.dispatch("task/setAll", {
        items: tasks,
        parent: this.parentEvent,
      });
    },
    assignSuggestedTasksToUser(user) {
      this.$store.dispatch("task/assignSuggestedTo", user);
    },
    addSuggestedTasks(tasks) {
      this.showSuggested = false;
      this.$store.dispatch("task/setAllFromSuggested", {
        items: tasks,
        parent: this.parentEvent,
      });
    },
    showAll() {
      this.selectedUser = null;
    },
    showUsersTasks(user) {
      this.selectedUser = user;
    },
    saveAll() {
      this.$store.dispatch("task/saveAll");
    },
    initDropdowns() {
      $(this.$el)
        .find(".right.floated.secondary.menu .dropdown, .dropdown.icon.button")
        .dropdown()
        .data("dropdown-loaded", true);
    },

    loadTasks(page = 1) {
      this.$store.dispatch("task/loadAll", {
        page,
        parentEvent: this.parentEvent,
        queryParams: this.queryParams,
      });
    },
    loadPage(page = 1) {
      this.loadTasks(page);
    },
    localStorageKey(key) {
      let string = "";
      string = `tasks_${this.parentEvent ? this.parentEvent.id : "index"}`;

      return `${string}:${key}`;
    },
    setLocalStorage(key, val) {
      return localStorage.setItem(this.localStorageKey(key), val);
    },
    getLocalStorage(key, fallback) {
      const val = localStorage.getItem(this.localStorageKey(key));
      return val ? JSON.parse(val) : fallback;
    },
  },
  // router
};
</script>

<style lang="scss" scoped>
header {
  margin-bottom: 2em;
}

.ui.message {
  border: none !important;
  box-shadow: none !important;
}

.secondary.menu {
  span.nobreak {
    white-space: nowrap;
  }

  .dropdown.item.sorts {
    width: 140px;
  }
  .dropdown.item.filters {
    width: 120px;
  }
  .item.search {
    input {
      border-color: #fff;
    }
  }
}

@media (max-width: 768px) {
  h4 {
    font-size: 1.42857143rem !important;
    padding: 0 !important;
    padding-left: 50px !important;
    margin-top: 0px !important;
  }

  .taskapp > div {
    display: flex;
    flex-direction: column-reverse;
  }

  .right.floated.menu {
    float: none;
    margin: 2em 1em 0 1em;
    .item {
      margin-bottom: 0.5em;
    }
    .item.search {
      margin-bottom: 1em;
    }
  }

  /deep/ .ui.task.form .fields {
    .field {
      margin-bottom: 0.5em;
      .buttons,
      .buttons .button {
        display: block !important;
        width: 100% !important;
      }
    }
  }
}
</style>
