const ALL = "ALL";

// initial state
const initialState = {
  newDeliverable: {},
  all: [],
  current: {},
  error: null,
  colors: {
    Facebook: "rgba(59, 89, 152, .75)",
    facebook: "rgba(59, 89, 152, .75)",
    facebook_profile: "rgba(59, 89, 152, .75)",

    Twitter: "rgba(0, 172, 237, .75)",
    twitter: "rgba(0, 172, 237, .75)",
    twitter_profile: "rgba(0, 172, 237, .75)",

    Google: "rgba(221, 75, 57, .75)",
    google: "rgba(221, 75, 57, .75)",
    linkify: "rgba(221, 75, 57, .75)",

    Youtube: "rgba(205,32,31, .75)",
    YouTube: "rgba(205,32,31, .75)",
    youtube: "rgba(205,32,31, .75)",
    youtube_profile: "rgba(205,32,31, .75)",
    "you-tube": "rgba(205,32,31, .75)",

    Instagram: "rgba(172, 39, 159, .75)",
    "Instagram Stories": "rgba(192, 69, 129, 1)",
    instagram: "rgba(172, 39, 159, .75)",
    instagram_stories: "rgba(192, 69, 129, 1)",
    instagram_profile: "rgba(172, 39, 159, .75)",

    Threads: "#3f3f3f",
    threads: "#3f3f3f",

    Pinterest: "rgba(203, 32, 39, .90)",
    pinterest: "rgba(203, 32, 39, .90)",
    pinterest_profile: "rgba(203, 32, 39, .90)",

    Bitly: "rgba(97,179,222, .90)",
    bitly: "rgba(97,179,222, .90)",

    Mailchimp: "rgba(35,154,185, .90)",
    mailchimp: "rgba(35,154,185, .90)",

    Mailerlite: "#3eaf7c",
    mailerlite: "#3eaf7c",

    Aweber: "#2072b9",
    aweber: "#2072b9",

    campaignmonitor: "#7856FF",
    "Campaign Monitor": "#7856FF",

    "Constant Contact": "#1856ed",
    constantcontact: "#1856ed",

    Convertkit: "#FB6970",
    convertkit: "#FB6970",

    Drip: "#f224f2",
    drip: "#f224f2",

    Expertsender: "#552D7E",
    expertsender: "#552D7E",

    Klaviyo: "#24ad69",
    klaviyo: "#24ad69",

    Sendfox: "#FD6655",
    sendfox: "#FD6655",

    Linkedin: "#0976b4",
    linkedin: "#0976b4",

    Madmimi: "#86c3ea",
    madmimi: "#86c3ea",

    activecampaign: "rgba(57, 110, 226, 1)",
    Activecampaign: "rgba(57, 110, 226, 1)",

    "Podcast (Omny Studio)": "#6313bc",
    omny_studio: "#6313bc",

    "Push Notification (OneSignal)": "#e24b4d",
    one_signal: "#e24b4d",

    "Push Notification (PushEngage)": "#e24b4d",
    push_engage: "#e24b4d",

    Art19: "#1373b6",
    art19: "#1373b6",

    Content: "#F4806F",
    Articles: "#F4806F",

    TikTok: "#EE1D52",
    tik_tok: "#EE1D52",
    Tik_tok: "#EE1D52",

    transistor: "#0c1824",
    Transistor: "#0c1824",

    Twitch: "#9146ff",
    twitch: "#9146ff",

    Other: "#e5b586",
    other: "#e5b586",
    Email: "#e5b586",
    email: "#e5b586",

    url: "#F4806F",

    short_io: "#55af86",
    "Short.io": "#55af86",

    shopify: "#008060",
    Shopify: "#008060",
  },
};

// getters
const storeGetters = {
  count(state) {
    return state.all.length;
  },
  inferredTypeAsLabel: () => (type) => {
    switch (type) {
      case "url":
        return "Article";
      case "youtube":
        return "YouTube";
      case "constant_contact":
        return "Constant Contact";
      case "campaignmonitor":
        return "Campaign Monitor";
      case "short_io":
        return "Short.io";
      case "tik_tok":
        return "TikTok";
      case "omny_studio":
      default:
        return type;
    }
  },
  inferredTypeAsIconClass: () => (type) => {
    let deliverableType = type;
    if (type.includes("Other")) {
      deliverableType = "other";
    }
    if (type.includes("_profile")) {
      deliverableType = type.replace("_profile", "");
    }

    switch (deliverableType) {
      case "url":
        return "linkify";
      case "youtube":
        return "you-tube";
      case "bitly":
        return "bitly";
      case "mailchimp":
      case "convertkit":
      case "drip":
      case "constant_contact":
      case "aweber":
      case "activecampaign":
      case "campaignmonitor":
      case "mailerlite":
      case "email":
        return "envelope";
      case "other":
        return "sticky note outline";
      case "instagram_stories":
        return "instagram";
      case "push_engage":
        return "bullhorn";
      case "art19":
        return "podcast";
      default:
        return deliverableType;
    }
  },
};

// actions
const actions = {
  setAll({ commit }, { items }) {
    commit(ALL, { items });
  },
};

// mutations
const mutations = {
  [ALL](state, { items }) {
    state.error = null;
    state.all = items;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters: storeGetters,
  actions,
  mutations,
};
