<template>
  <div
    :data-html="storyTooltip"
    data-variation="mini inverted"
    data-position="bottom center"
  >
    <div class="ui image">
      <img
        v-if="!videoFrame && staticFrame"
        :src="staticFrame.expiring_url"
        class="visible content"
      />

      <div
        v-if="videoFrame"
        :id="'embed' + deliverable.id"
        class="ui embed active 9:16"
        :data-url="videoFrame.expiring_url"
        :data-placeholder="staticFrame.expiring_url"
      >
        <video
          :src="videoFrame.expiring_url"
          width="100%"
          :poster="staticFrame.expiring_url"
          loop
          allow="autoplay"
        />
      </div>
    </div>
    <div v-if="videoFrame" class="top overlay padding-xy-tiny">
      <i
        class="link icon video inverted large"
        @mouseover="playVideo"
        @mouseout="pauseVideo"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import _ from "lodash";

const { $ } = window;

export default {
  components: {},
  props: {
    deliverable: {
      type: Object,
    },
    videoControlSelector: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {};
  },
  computed: {
    storyTooltip() {
      return `@${this.deliverable.provider_username} <br /> ${Vue.filter(
        "formatDateTime"
      )(this.deliverable.provider_published_at)}`;
    },
    videoFrame() {
      return _.find(
        this.deliverable.uploads,
        (u) => u.data_content_type.indexOf("video") !== -1
      );
    },
    staticFrame() {
      return _.find(this.deliverable.uploads, (u) => {
        return u.data_content_type.indexOf("video") === -1 && !u.has_stats;
      });
    },
    videoEl() {
      return this.$el.querySelector(`#embed${this.deliverable.id} video`);
    },
  },
  mounted() {
    const $this = this;

    this.$nextTick(() => {
      $($this.$el).popup({
        hideOnScroll: true,
        delay: {
          show: 50,
          hide: 0,
        },
      });
    });
  },
  methods: {
    playVideo() {
      if (this.videoEl) {
        this.videoEl.playPromise = this.videoEl.play();
        this.videoEl.playPromise.then(() => {}).catch(() => {});
      }
    },
    pauseVideo() {
      if (this.videoEl) {
        if (this.videoEl.playPromise !== undefined) {
          this.videoEl.playPromise
            .then(() => {
              this.videoEl.pause();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    // initHoverPlay() {
    //  const $this = this;
    //  const videoControlEl = $this.$el.querySelector(
    //    $this.videoControlSelector || ".link.icon.video"
    //  );

    //  if (videoControlEl) {
    //    videoControlEl.addEventListener("mouseover", () => {
    //      $this.playVideo();
    //    });
    //    videoControlEl.addEventListener("mouseout", () => {
    //      $this.pauseVideo();
    //    });
    //  }
    // }
  },
};
</script>

<style scoped lang="scss">
.overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5em;
  &.top {
    top: 0;
  }
}

div.ui[class*="9:16"].embed {
  padding-bottom: 178%;
  background: none !important;
  border-radius: 0.28571429rem;
}
.ui.image img {
  border-radius: 0.28571429rem;
}

.link.icon {
  opacity: 1 !important;
  &.video {
    color: #fff;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  }
}
</style>
