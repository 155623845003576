import Sortable from "sortablejs";

document.addEventListener("turbo:load", function () {
  var calendarsList = document.getElementById("calendars");

  if (calendarsList) {
    const $ = window.jQuery;

    const calendarDropped = function () {
      // const order = $(calendarsList)
      //   .find(".ui.card")
      //   .map(function() {
      //     return $(this).data("id");
      //   })
      //   .toArray();

      $.ajax({
        url: `/calendars/reorder`,
        method: "patch",
        data: {
          order: sortable.toArray(),
        },
        dataType: "json",
      });
    };

    const sortable = Sortable.create(calendarsList, {
      removeCloneOnHide: false,
      draggable: ".card",
      handle: ".handle",
      onEnd: calendarDropped,
      swapThreshold: 0.5,
    });
  }
});
