<template>
  <div v-if="event" class="event">
    <div v-if="event.is_template" class="ui top banner message">
      <i class="icon window restore outline" />
      This campaign is a template
    </div>

    <div v-if="event.tenant_assignment_id" class="ui top banner message">
      This campaign is for an assignment.
      <a :href="`/assignments/${event.tenant_assignment_id}`" target="_blank">
        View the assignment
      </a>
    </div>

    <div v-if="editable" class="ui field big title input fluid hovering">
      <input
        v-model="event.name"
        name="name"
        autofocus
        placeholder="Name"
        class="padding-left-small"
      />
    </div>
    <h3 v-else class="ui large header">
      {{ event.name }}
    </h3>

    <div class="ui secondary pointing pink tabular menu margin-top-zero">
      <a
        v-if="editable"
        v-shortkey="['shift', '!']"
        data-tab="edit"
        class="item active"
        @shortkey="switchTab('edit')"
      >
        Overview
      </a>

      <a
        v-show="persisted"
        v-shortkey="['shift', '@']"
        data-tab="comments"
        class="item"
        @shortkey="switchTab('comments')"
      >
        <template v-if="commentable">
          Comments
          <div v-if="commentsCount > 0" class="ui mini label">
            {{ commentsCount }}
          </div>
        </template>
        <template v-else> Details </template>
      </a>

      <a
        v-show="persisted"
        v-if="taskable && commentable"
        v-shortkey="['shift', '#']"
        data-tab="tasks"
        class="item"
        @shortkey="switchTab('tasks')"
      >
        Tasks&nbsp;
        <div v-if="tasksCount > 0" class="ui mini label">
          {{ completeTasksCount }}/{{ tasksCount }}
        </div>
      </a>

      <a
        v-show="persisted"
        v-if="editable"
        v-shortkey="['shift', '$']"
        data-tab="deliverables"
        class="item"
        @shortkey="switchTab('deliverables')"
      >
        Deliverables&nbsp;
        <div v-if="deliverablesCount > 0" class="ui mini label">
          {{ deliverablesCount }}
        </div>
      </a>

      <div v-show="persisted" v-if="editable" class="right menu">
        <div class="item">
          <a v-if="persisted" :href="eventReportPath" target="_blank">
            <i class="pie chart icon" />
            View Report
            <sup>
              <i class="small external icon" />
            </sup>
          </a>
        </div>
        <div v-if="templatesEnabled" class="item margin-right-zero">
          <div class="ui simple dropdown">
            <i class="icon window restore outline" />
            <div class="menu">
              <a
                v-if="persisted && event.is_template"
                :href="`/events/${event.id}/clone?from_template=1`"
                class="item"
              >
                New campaign from this template
              </a>
              <div v-if="event.is_template" class="ui divider" />

              <div class="item" @click.stop.prevent="toggleTemplate">
                <template v-if="event.is_template"> Unmake </template>
                <template v-else> Make </template>
                template
              </div>
              <a class="item" href="/events/templates">Manage Templates</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="editable" class="ui tab active" data-tab="edit">
      <event-form
        :initial-event="event"
        :calendar="calendar"
        zcalendars="calendars"
        :event-states="eventStates"
        :show-status-field="showStatusField"
        :can-update-calendar="canUpdateCalendar"
        :wordpress-enabled="wordpressEnabled"
      />
    </div>

    <div class="ui tab" data-tab="comments">
      <div
        v-if="event.description"
        class="text margin-bottom-large"
        v-html="autolinkedDescription"
      />

      <div>
        <uploads-index-view
          v-if="event.id"
          :key="'uploads-event-' + event.id"
          :url="'/uploads?assetable_type=Event&assetable_id=' + event.id"
          :allow-uploads="false"
        />
      </div>

      <div v-if="commentable">
        <comments-index-view
          v-if="commentsPath && persisted"
          :key="`event-${event.id}-comments`"
          class="ui form"
          :event-editable="editable"
          :user-id="userId"
          :comments-path="commentsPath"
          :event="event"
        />
      </div>
    </div>

    <div v-if="taskable && commentable" data-tab="tasks" class="ui tab">
      <tasks-index-view
        v-if="persisted"
        id="event_tasks"
        :endpoint="tasksPath"
        :parent-event="event"
        :suggested-tasks="suggestedTasks"
        :current-user-id="userId"
        :initial-sorts="['user', 'date']"
        initial-sort="user"
      />
    </div>

    <div v-if="editable" data-tab="deliverables" class="ui tab">
      <deliverables-index-view
        :endpoint="deliverablesPath"
        :initial-deliverables="initialDeliverables"
        :metric-types="metricTypes"
        :suggested-deliverables="suggestedDeliverables"
        :ig-stories-enabled="igStories"
        :deliverable-search-enabled="deliverableSearchEnabled"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import { mapState, mapActions } from "vuex";
import store from "store";
import linkifyHtml from "linkify-html";
import CommentsIndexView from "views/comments/index.vue";
import TasksIndexView from "views/tasks/index.vue";
import DeliverablesIndexView from "views/deliverables/index.vue";
import UploadsIndexView from "views/uploads/index.vue";
import EventBus from "components/eventbus";
import EventForm from "./form.vue";
import shortkey from "vue-shortkey";
Vue.use(shortkey, { prevent: ["input", "textarea", "div[contenteditable]"] });

const $ = window.jQuery;

export default {
  // router,
  store,
  components: {
    EventForm,
    CommentsIndexView,
    TasksIndexView,
    DeliverablesIndexView,
    UploadsIndexView,
  },
  props: [
    "initialEvent",
    // "users",
    "calendar",
    // "calendars",
    "editable",
    "selectedTab",
    "commentable",
    "taskable",
    "wordpressEnabled",
    "igStories",
    "deliverableSearchEnabled",
    "templatesEnabled",
    "deliverable",
    "eventStates",
    "userId",
    "initialComments",
    "commentsPath",
    "initialTasks",
    "suggestedTasks",
    "initialDeliverables",
    "metricTypes",
    "suggestedDeliverables",
    "initialSelected",
    "showStatusField",
    "canUpdateCalendar",
  ],
  data() {
    const $this = this;
    return {
      currentTab: $this.initialSelected,
    };
  },
  computed: {
    ...mapState("event", {
      event: "current",
    }),
    ...mapState("comment", {
      commentsCount: "count",
    }),
    // commentsCount() {
    //   return this.persisted && this.commentCount;
    // },
    tasksCount() {
      return this.$store.getters["task/eventTaskCount"];
    },
    completeTasksCount() {
      return this.$store.getters["task/eventTaskCompletedCount"];
    },
    deliverablesCount() {
      return this.$store.getters["deliverable/count"];
    },
    persisted() {
      return this.event && this.event.id;
    },
    deliverablesPath() {
      return `/calendars/${this.event.calendar_id || this.calendar.id}/events/${
        this.event && this.event.id
      }/deliverables`;
    },
    tasksPath() {
      return `/calendars/${this.event.calendar_id || this.calendar.id}/events/${
        this.event && this.event.id
      }/tasks`;
    },
    eventReportPath() {
      return `/calendars/${this.event.calendar_id || this.calendar.id}/events/${
        this.event && this.event.id
      }/report`;
    },
    eventPath() {
      let path = this.event && this.event.url;
      if (!path) {
        path = `/calendars/${
          this.event.calendar_id || this.calendar.id
        }/events/${this.event && this.event.id}`;
      }

      if (this.currentTab) {
        path += `?eventTab=${this.currentTab}`;
      }
      return path;
    },
    autolinkedDescription() {
      return linkifyHtml(unescape(this.event.description));
    },
  },
  watch: {
    eventPath(val) {
      EventBus.$emit("eventPathChanged", val);
      this.$store.dispatch("event/setPath", { path: val });
    },
  },
  mounted() {
    if (this.initialEvent) {
      this.setEvent({ item: this.initialEvent });
    }

    this.$nextTick(() => {
      this.initTabs();
    });
  },
  destroyed() {
    this.$store.dispatch("event/unsetCurrent");
  },
  methods: {
    ...mapActions("event", {
      setEvent: "setCurrent",
    }),
    toggleTemplate() {
      this.event.is_template = !this.event.is_template;
      this.$store.dispatch("event/update", this.event);
    },
    loadEvent() {
      $.read(this.eventPath).success((data) => {
        this.setEvent = data.initialEvent;
      });
    },
    initTabs() {
      const $this = this;
      $($this.$el)
        .find(".tabular.menu .item")
        .tab({
          onLoad(tab) {
            $this.currentTab = tab;
          },
        })
        .tab("change tab", $this.initialSelected);
    },
    refreshModal() {
      this.$emit("refreshModal");
    },
    switchTab(args) {
      $(this.$el).find(".tabular.menu .item").tab("change tab", args);
    },
  },
};
</script>

<style lang="scss">
.ui.big.title.input input {
  font-size: 24px;
  font-weight: 700;
  margin: -1.142857rem -1.142857rem 0rem -1.14285714rem;
  max-width: none;
}

.top.banner.message {
  box-shadow: none !important;
  margin: -32px -32px 2rem -32px;
}
</style>
