<template lang="html">
  <div class="ui modal">
    <i class="ui close icon" />
    <div class="content very padded">
      <div v-if="!eventData && !error" class="ui form basic segment loading" />

      <div v-if="error">
        <h4>{{ error.errors }}</h4>
      </div>

      <event-view
        v-if="eventData && eventData.initialEvent && !error"
        :key="`event-${eventData.initialEvent.id}`"
        ref="eventView"
        v-bind="eventData"
        @refreshModal="refresh"
      />

      <div v-if="eventData && eventData.class_name == 'task'">
        <h2 v-if="!eventData.id">Add a Task</h2>

        <task-item
          :task="eventData"
          :name="eventData.name"
          @removeTask="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import store from "store";
import EventView from "views/events/show.vue";
import TaskItem from "views/tasks/TaskItem.vue";
import ResizeObserver from "resize-observer-polyfill";
import EventBus from "components/eventbus";
import { without, union } from "lodash";

const $ = window.jQuery;

export default {
  store,
  components: {
    EventView,
    TaskItem,
  },
  props: {
    initialEventData: null,
    // originalUrl: ""
  },
  data() {
    const $this = this;
    return {
      originalUrl: document.location.href,
      url: "",
      eventData: $this.initialEventData,
      error: null,
      activeRequest: null,
      dirty: [],
    };
  },
  computed: {
    isDirty() {
      return this.dirty.length > 0;
    },
  },
  mounted() {
    console.log("EventModal: Mounted");
    this.$nextTick(() => {
      this.initModal();
      this.initListeners();

      if (window.selectedEventUrl) {
        this.loadEvent(window.selectedEventUrl);
      }
    });
  },
  methods: {
    setEventData(data) {
      this.eventData = data;
      // this.refresh();
    },
    open() {
      this.dirty = [];

      this.originalUrl = document.location.href;
      $(this.$el).modal("hide"); //weird, but we need to make sure it's not already visble
      $(this.$el).modal("show");
      this.$store.dispatch("event/setPath", { path: this.url });
    },
    close() {
      $(this.$el).modal("hide");
    },
    refresh() {
      this.$nextTick(() => {
        $(this.$el).modal("refresh");
      });
    },
    fetchComplete(data) {
      console.log("EventModal: Fetch Complete: ", data);
      this.setEventData(data.responseJSON);
    },
    fetchError(data) {
      console.log("EventModal: Fetch Error: ", data);
      this.error = data.responseJSON;
    },
    fetchEvent() {
      console.log("EventModal: Fetch Event: ", this.url);
      const $this = this;
      return $.ajax({
        url: $this.url,
        dataType: "json",
        contentType: "application/json",
        complete: $this.fetchComplete,
        error: $this.fetchError,
        cache: false,
      });
    },
    cancelRequest() {
      if (this.activeRequest) {
        console.log("EventModal: Cancel modal request");
        this.activeRequest.abort();
        this.activeRequest = null;
      }
    },
    loadEvent(url) {
      console.log("EventModal: Open Event Modal: ", url);

      this.cancelRequest();
      this.url = url;
      this.setEventData(null);

      this.open();

      this.activeRequest = this.fetchEvent();
    },
    loadEventWithData(json) {
      this.setEventData(null);
      this.open();
      this.setEventData(json);
    },
    observeChanges() {
      const ro = new ResizeObserver(this.refresh);

      const content = this.$el.querySelector(".content");
      if (content) {
        ro.observe(content);
      }
    },
    initModal() {
      const $this = this;
      $($this.$el).modal({
        centered: false,
        detachable: true,
        observeChanges: false,
        dimmerSettings: {
          closable: true,
        },
        duration: 25,
        closable: true,
        allowMultiple: true,
        onHide: $this.modalHide,
        onHidden: $this.modalHidden,
        name: "EventModal",
      });

      $this.$nextTick($this.refresh);
      $this.$nextTick($this.observeChanges);
    },
    modalHide() {
      if (this.isDirty) {
        if (
          !confirm("You have unsaved changes. Are you sure you want to close?")
        ) {
          return false;
        }
      }

      this.dirty = [];
      this.resetState();
    },
    modalHidden() {
      window.history.pushState({}, "", this.originalUrl);
      if (window.modalHiddenCallback) {
        window.modalHiddenCallback();
      }
    },
    resetState() {
      console.log("EventModal: Reset State");
      this.eventData = null;
      this.$store.dispatch("event/unsetCurrent", { path: this.url });
      this.$store.dispatch("comment/setAll", []);
      this.$store.dispatch("comment/setCount", 0);
      this.$store.dispatch("task/resetState");
    },
    markDirty(key) {
      this.dirty = union(this.dirty, [key]);
    },
    markClean(key) {
      this.dirty = without(this.dirty, key);
    },
    initListeners() {
      EventBus.$on("openEventModal", this.loadEvent);

      EventBus.$on("openNewEventModal", this.loadEventWithData);

      // EventBus.$on("eventPathChanged", this.refresh);

      EventBus.$on("refreshEventModal", this.refresh);

      EventBus.$on("closeEventModal", this.close);

      EventBus.$on("eventModalDirty", this.markDirty);
      EventBus.$on("eventModalClean", this.markClean);
    },
  },
};
</script>

<style lang="css"></style>
