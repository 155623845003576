import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import Charts from "views/partner_campaigns/charts.vue";
import Avatar from "components/avatar.vue";

document.addEventListener("turbo:load", function () {
  document.querySelectorAll(".partner.card, .partner.segment").forEach((el) => {
    new Vue({
      el: el,
      components: {
        Charts,
        Avatar,
      },
    });
  });
});

window.makeCharts = function (element) {
  element.querySelectorAll("charts").forEach((el) => {
    new Vue({
      el: el,
      components: {
        Charts,
      },
    });
  });
};
