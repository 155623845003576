import Vue from "vue/dist/vue.esm";
import axios from "axios";
// import _ from "lodash";
import Toasted from "vue-toasted";

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
  singleton: true
});

const http = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

http.interceptors.request.use(
  config => {
    config.headers["X-CSRF-TOKEN"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    return config;
  },
  error => Promise.reject(error.response)
);

const resourcePath = comment => {
  if (comment.id) {
    return `/comments/${comment.id}.json`;
  }
  return `/comments.json`;
};

const ALL = "ALL";
const GET = "GET";
const CREATE = "CREATE";
const UPDATE = "UPDATE";
const DESTROY = "DESTROY";
const ERROR = "ERROR";

const updateItem = (item, all, remove) => {
  const thisItem = all.find(i => i.id === item.id);
  const thisItemIndex = all.indexOf(thisItem);
  if (thisItemIndex > -1) {
    if (remove || false) {
      all.splice(thisItemIndex, 1);
    } else {
      all.splice(thisItemIndex, 1, item);
    }
  }
};

const addItem = (addedComment, comments) => {
  comments.unshift(addedComment);
};

// initial state
const initialState = {
  newComment: {},
  all: [],
  count: 0,
  current: {},
  error: null
};

// getters
const storeGetters = {
  event(state, getters, rootState) {
    return rootState.event.current || {};
  }
};

// actions
const actions = {
  getAll({ commit }, params) {
    const requestParams = params || {};
    return new Promise((resolve, reject) => {
      http
        .get("/comments.json", { params: requestParams })
        .then(res => {
          const items = res.data;
          const count = parseInt(res.headers.total, 10);
          commit(ALL, { items, count });
          resolve(res);
        })
        .catch(err => {
          commit(ERROR, { err });
          reject(err);
        });
    });
  },

  setAll({ commit }, { items }) {
    commit(ALL, { items });
  },

  setCurrent({ commit }, { item }) {
    commit(GET, { item });
  },

  setCount({ commit }, integer) {
    commit("SET_COUNT", integer);
  },

  create({ commit }, item) {
    return new Promise((resolve, reject) => {
      http
        .post(resourcePath(item), { comment: item })
        .then(res => {
          commit(CREATE, { item: res.data });
          resolve(res);
        })
        .catch(err => {
          commit(ERROR, { err });
          reject(err);
        });
    });
  },

  update({ commit }, item) {
    return new Promise((resolve, reject) => {
      http
        .put(resourcePath(item), { comment: item })
        .then(res => {
          commit(UPDATE, { item: res.data });
          resolve(res);
        })
        .catch(err => {
          commit(ERROR, { err });
          reject(err);
        });
    });
  },

  destroy({ commit }, item) {
    return new Promise((resolve, reject) => {
      http
        .delete(resourcePath(item))
        .then(res => {
          commit(DESTROY, { item });
          resolve(res);
        })
        .catch(err => {
          commit(ERROR, { err });
          reject(err);
        });
    });
  }
};

// mutations
const mutations = {
  [CREATE](state, { item }) {
    state.error = null;
    state.current = item;
    state.newComment = {};
    addItem(item, state.all);
    Vue.toasted.success("Created");
  },
  [ALL](state, { items, count }) {
    state.error = null;
    state.all = items;
    state.count = count;
  },
  [GET](state, { item }) {
    state.error = null;
    state.current = item;
    updateItem(state.current, state.all);
  },
  [UPDATE](state, { item }) {
    state.error = null;
    state.current = item;
    updateItem(item, state.all);
    Vue.toasted.success("Updated");
  },
  [DESTROY](state, { item }) {
    state.error = null;
    state.current = null;
    updateItem(item, state.all, true);
    Vue.toasted.info("Deleted");
  },
  [ERROR](state, { err }) {
    state.error =
      err.response && err.response.data ? err.response.data : "Error";
    Vue.toasted.error(err.response.data.errors);
  },
  ["SET_COUNT"](state, integer) {
    state.count = integer;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters: storeGetters,
  actions,
  mutations
};
