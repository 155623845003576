const { moment } = window;

import checkboxField from "./checkboxField.vue";

export default [
  {
    name: checkboxField,
    titleClass: "center aligned",
    dataClass: "center aligned",
    width: "34px",
    visible: true,
  },
  {
    name: "name-slot",
    attribute: "name",
    title: "Name",
    sortField: "name",
    filterField: "name",
    filter: "text",
    visible: true,
  },
  {
    name: "dropdown-slot",
    attribute: "calendar_id",
    initialValue(rowData) {
      const obj = rowData;
      return (
        obj &&
        `<div class='ui mini margin-right-mini empty circular label grey' style='background-color: ${obj.calendar_color};'></div>${obj.calendar_name}`
      );
    },
    optionsEndpoint() {
      return `/calendars.json`;
    },
    optionTemplate(obj) {
      return `<div class='ui mini margin-right-mini empty circular label grey' style='background-color: ${obj.color};'></div>${obj.name}`;
    },
    title: "Calendar",
    sortField: "calendar_name",
    // filter: "text",
    filterField: "calendar_name",
    preventClearing: true,
    visible: false,
  },
  {
    name: "dropdown-slot",
    attribute: "event_category_id",
    initialValue(rowData) {
      const obj = rowData.event_category;
      return (
        obj &&
        `<div class='ui mini margin-right-mini empty circular label grey' style='background-color: ${obj.color};'></div>${obj.name}`
      );
    },
    optionsEndpoint(obj) {
      return `/calendars/${obj.calendar_id}/event_categories.json`;
    },
    optionTemplate(obj) {
      return `<div class='ui mini margin-right-mini empty circular label grey' style='background-color: ${obj.color};'></div>${obj.name}`;
    },
    title: "Category",
    sortField: "event_category_name",
    filter: "text",
    filterField: "event_category_name",
    visible: true,
  },

  {
    name: "dropdown-slot",
    attribute: "organization_id",
    initialValue(rowData) {
      return rowData.organization_name;
    },
    optionsEndpoint() {
      return `/organizations.json`;
    },
    transformResponse(obj, rowData) {
      return {
        name: obj.name,
        value: obj.name,
        selected: rowData.organization_id === obj.id,
      };
    },
    title: "Sponsor",
    sortField: "organization_name",
    filter: "text",
    filterField: "organization_name",
    visible: true,
  },

  {
    name: "dropdown-slot",
    attribute: "aasm_state",
    initialValue(rowData) {
      return `<span class='ui label ${rowData.aasm_state}'>${rowData.aasm_state}</span>`;
    },
    optionsEndpoint() {
      return `/events/statuses.json`;
    },
    transformResponse(obj, rowData) {
      return {
        name: obj.name,
        value: obj.name,
        text: `<span class='ui label ${obj.name}'>${obj.name}</span>`,
        selected: rowData.aasm_state === obj.name,
      };
    },
    title: "Status",
    sortField: "aasm_state",
    filter: ["", "proposed", "approved", "working", "completed", "rejected"],
    filterField: "aasm_state",
    visible: true,
  },
  {
    name: "dropdown-slot",
    title: "User",
    attribute: "user_id",
    preventClearing: true,
    initialValue(rowData) {
      return `<v-avatar
            class='ui image avatar margin-right-mini'
            username='${rowData.username}'
            src='${rowData.user_gravatar}'
            size='18'/></v-avatar>${rowData.username}`;
    },
    optionsEndpoint() {
      return `/users.json`;
    },
    optionTemplate(obj) {
      const string = `<v-avatar
            class='ui image avatar margin-right-mini'
            username='${obj.name}'
            src='${obj.gravatar}'
            size='18'/></v-avatar>${obj.name}`;

      return string;
    },
    sortField: "user_id",
    filter: "text",
    filterField: "user_login",
    visible: true,
  },
  // {
  //   name: "user-slot",
  //   title: 'User',
  //   sortField: "user_id",
  //   filter: "text",
  //   filterField: "user_login"
  // },
  {
    name: "starts_at",
    title: "Date",
    sortField: "starts_at",
    formatter(value) {
      return `<span class='ui small text'>${moment(value).format(
        "M/D/YY    h:mma"
      )}</span>`;
    },
    filter: "date-range",
    filterField: "starts_at",
    visible: true,
  },
  {
    name: "total_impressions",
    sortField: "total_impressions",
    attribute: "total_impressions",
    title: "Impressions",
    dataClass: "pointer expandable",
    visible: true,
    formatter(value) {
      return value ? value.toLocaleString() : "";
    },
  },
  {
    name: "total_views",
    sortField: "total_views",
    attribute: "total_views",
    title: "Views",
    dataClass: "pointer expandable",
    visible: true,
    formatter(value) {
      return value ? value.toLocaleString() : "";
    },
  },
  {
    name: "total_engagements",
    attribute: "total_engagements",
    sortField: "total_engagements",
    title: "Engagements",
    dataClass: "pointer expandable",
    visible: true,
    formatter(value) {
      return value ? value.toLocaleString() : "";
    },
  },
  {
    name: "rate",
    attribute: "rate",
    sortField: "rate",
    title: "Rate",
    dataClass: "pointer",
    visible: false,
    formatter(value) {
      return value ? value.toLocaleString() : "";
    },
  },
];
