<template lang="html">
  <div class="">
    <div ref="contextMenuPopup">
      <context-menu
        :event="selectedEvent"
        class="ui mini popup vertical menu padding-xy-zero inverted"
        @copy="copyEvent"
        @destroy="deleteEvent"
      />
    </div>

    <div id="pitches">
      <div class="clearing">
        <div class="right floated">
          <span
            :data-tooltip="`${showFilters ? 'Hide' : 'Show'} filters`"
            data-variation="mini"
            data-inverted="true"
            data-position="left center"
          >
            <i
              :class="{ vertical: showFilters, horizontal: !showFilters }"
              class="icon grey ellipsis link"
              @click="showFilters = !showFilters"
            />
          </span>

          <span
            :data-tooltip="sortIconTooltipText"
            data-variation="mini"
            data-inverted="true"
            data-position="top right"
          >
            <i
              :class="sortIconClass"
              class="icon grey sort link"
              @click="toggleSort"
            />
          </span>

          <a
            :href="newPitchLink"
            data-remote="true"
            data-tooltip="Add a proposed campaign"
            data-variation="mini"
            data-inverted="true"
            data-position="bottom right"
            class="ui mini button circular icon"
          >
            <i class="ui icon add" />
          </a>
        </div>

        <h4 class="margin-top-tiny ui header">
          <div class="padding-top-tiny pointer" @click="hideSidebar">
            Proposed
            <i v-if="sidebarIsShown" class="ui icon right caret link" />
          </div>
        </h4>

        <div
          v-if="
            showFilters &&
            (calendars.length > 1 || categories.length > 1 || users.length > 1)
          "
          class="content"
        >
          <div
            class="ui secondary menu mini"
            :vclass="[calendars.length > 1 ? 'three item' : 'two item']"
          >
            <dropdown
              v-if="calendars.length > 1"
              v-model="selectedCalendar"
              :items="calendarsAsOptions()"
              class="item flex-grow-1 flex-shrink-1"
              name="Calendar"
              @change="calendarChanged"
            >
              <i slot="menuIcon" class="icon calendar" />
              <span slot="label" slot-scope="props">
                {{ props.item.label }}
              </span>
            </dropdown>
            <dropdown
              v-if="categories.length > 1"
              v-model="selectedCategory"
              :items="categoriesAsOptions()"
              class="item flex-grow-1 flex-shrink-1"
              name="Category"
              @change="categoryChanged"
            >
              <i slot="menuIcon" class="icon tags" /><span
                slot="label"
                slot-scope="props"
                ><div
                  class="ui left floated red empty circular label"
                  :style="{
                    'background-color': props.item.color + ' !important',
                  }"
                />
                {{ props.item.label }}</span
              >
            </dropdown>
            <dropdown
              v-if="users.length > 1"
              v-model="selectedUser"
              :items="usersAsOptions()"
              class="item flex-grow-1 flex-shrink-1"
              name="User"
              @change="userChanged"
            >
              <i slot="menuIcon" class="icon user" /><span
                slot="menuName"
                class="text"
                >Everyone</span
              >
            </dropdown>
          </div>
        </div>
      </div>

      <div class="ui relaxed list content">
        <div v-if="pitches.length < 1" class="item">Nothing proposed yet</div>
        <div
          v-for="pitch in pitches"
          :key="pitch.id"
          class="item"
          @click.right.prevent="eventContextMenuHandler(pitch, $event)"
        >
          <span
            v-drag="true"
            :data-event="pitchJson(pitch)"
            class="fc-event"
            @click="openEvent(pitch)"
          >
            <div :style="pitch.styleObject" class="ui vertical border label" />

            <span class="fc-content">
              <span class="fc-title">
                {{ pitch.title }}

                <span v-if="pitch.event_category" class="fc-meta ui grey text">
                  {{ pitch.event_category.name }}
                </span>

                <div
                  class="margin-y-mini"
                  v-if="pitch.tag_list && pitch.tag_list != ''"
                >
                  <span
                    class="flex-inline ui label small margin-bottom-tiny"
                    v-for="tag in pitch.tag_list.split(',')"
                  >
                    {{ tag }}
                  </span>
                </div>

                <span class="fc-meta ui grey text">
                  <span v-if="pitch.tasks_count > 0" class="margin-right-tiny">
                    <i class="icon circle check fitted" />
                    {{ pitch.tasks_complete }}/{{ pitch.tasks_count }}
                  </span>

                  <span v-if="pitch.comments_count > 0">
                    <i class="icon comment fitted" />
                    {{ pitch.comments_count }}
                  </span>
                </span>
              </span>
              <v-avatar
                :username="pitch.username"
                :src="pitch.user_gravatar"
                :data-tooltip="pitch.username"
                data-variation="mini"
                data-inverted=""
                data-position="left center"
                class="avatar"
                size="24"
              />
            </span>
            <!-- <span class="right floated">({{ pitch.username }})</span> -->
          </span>
        </div>
      </div>
    </div>
    <!-- <div class="ui info message" v-if="pitches.length < 1"><span class="title"><a :href="'/calendars/'+calendar.id+'/pitches_help'" data-remote="true"><i class="ui icon help circle"></i>How do 'Pitches' work?</a></span></div> -->
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Dropdown from "components/dropdown.vue";
import _ from "lodash";
import EventBus from "components/eventbus";
import drag from "directives/drag";
import { mapState, mapActions } from "vuex";
import contextMenu from "views/events/components/_contextMenu.vue";

Vue.directive("drag", drag);

const $ = window.jQuery;

export default {
  components: {
    dropdown: Dropdown,
    contextMenu,
  },
  directives: {
    drag,
  },
  props: {
    newPitchLink: String,
    calendar: Object,
    calendars: Array,
  },
  data() {
    const $this = this;

    const initialSelectedCalendar =
      $this.calendars.length < 2 ? $this.calendars[0].id : "";

    return {
      lastLoadedUrl: "",
      pitches: [],
      selectedCalendar: initialSelectedCalendar,
      selectedCategory: "",
      selectedUser: "",
      selectedEvent: null,
      showFilters: false,
      sort:
        JSON.parse(
          localStorage.getItem(
            `calendar${initialSelectedCalendar}:pitches:sort`
          )
        ) || "desc",
    };
  },
  computed: {
    ...mapState("app", ["sidebarIsShown"]),
    categories() {
      return _.flatten(_.map(this.calendars, "event_categories"));
    },
    users() {
      return _.uniqBy(_.flatten(_.map(this.calendars, "users")), "id");
    },
    isVisible() {
      return this.$parent.show;
    },
    sortIconClass() {
      return {
        "amount up": this.sort == "asc",
        "amount down": this.sort == "desc",
      };
    },
    sortIconTooltipText() {
      if (this.sort != "submitted") {
        return `Sorted by publish date ${this.sort}`;
      } else {
        return `Sorted by date submitted`;
      }
    },
  },
  watch: {
    sort(val, oldVal) {
      const storageKey = `calendar${this.selectedCalendar}:pitches:sort`;
      const storageValue = JSON.stringify(this.sort);

      if (val != oldVal) {
        localStorage.setItem(storageKey, storageValue);
      }
    },
  },
  mounted() {
    const $this = this;
    $this.$nextTick(() => {
      $this.reloadData();
    });

    EventBus.$on("eventDragStart", (event) => {
      if (event.class_name != "event") {
        return;
      }
      $($this.$el).find("#pitches").addClass("droppable");
    });
    EventBus.$on("eventDragStop", (event) => {
      if (event.class_name != "event") {
        return;
      }

      $($this.$el).find("#pitches").removeClass("droppable");
    });

    EventBus.$on("reloadPitches", () => {
      $this.reloadData(true);
    });
  },
  methods: {
    ...mapActions("app", ["hideSidebar"]),
    pitchJson(pitch) {
      return JSON.stringify(_.omit(pitch, ["start", "end"]));
    },
    usersAsOptions() {
      return this.users.map((u) => ({ label: u.login, value: u.id }));
    },
    calendarsAsOptions() {
      return this.calendars.map((c) => ({
        label: c.name,
        value: c.id,
        color: c.color,
      }));
    },

    categoriesAsOptions() {
      return this.categories.map((c) => ({
        label: c.name,
        value: c.id,
        color: c.color,
      }));
    },
    styles(pitch) {
      const obj = pitch.styleObject;
      obj.color = this.getContrast50(pitch.color);
      // obj.color = pitch.color;
      return obj;
    },
    getContrast50(hexcolor) {
      if (hexcolor && parseInt(hexcolor.replace("#", ""), 16) > 0xffffff / 2) {
        return "black";
      }
      return "white";
    },
    openEvent(pitch) {
      window.EventBus.$emit("openEventModal", pitch.url);
    },
    copyEvent(theEvent) {
      if (theEvent.class_name === "event") {
        const eventCopy = JSON.parse(
          JSON.stringify(_.omit(theEvent, "source"))
        );

        $.create({
          url: `/events/${theEvent.id}/clone`,
          data: {
            starts_at: eventCopy.start,
            ends_at: eventCopy.end,
          },
        });
      }
    },
    deleteEvent(event) {
      if (confirm("Are you sure you want to delete this?")) {
        this.$store
          .dispatch("event/destroy", {
            id: event.id,
            calendar_id: event.calendar_id,
          })
          .then(() => {
            // this.setLoading(false);
          });
      }
    },
    reloadData(force) {
      if (!this.isVisible) {
        return false;
      }
      let url;
      const that = this;

      // url = `/calendars/${this.calendar.id}/events?pitches=true`
      url = `/events.json?pitches=true`;
      if (this.sort != "submitted") {
        url = url + `&sort=events.starts_at ${this.sort}`;
      }

      if (this.selectedCalendar) {
        url += `&calendar_id=${this.selectedCalendar}`;
      }
      if (this.selectedUser) {
        if (this.selectedUser !== "Everyone") {
          url += `&user_id=${this.selectedUser}`;
        }
      }
      if (this.selectedCategory) {
        if (this.selectedCategory !== "All Categories") {
          url += `&event_category_id=${this.selectedCategory}`;
        }
      }

      if (force || that.lastLoadedUrl !== url) {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            that.pitches = data;
          });

        that.lastLoadedUrl = url;
      }
    },
    calendarChanged(value) {
      this.selectedCalendar = value;
      this.reloadData();
    },
    categoryChanged(value) {
      this.selectedCategory = value;
      this.reloadData();
    },
    userChanged(value) {
      this.selectedUser = value;
      this.reloadData();
    },
    toggleSort() {
      if (this.sort == "asc") {
        this.sort = "desc";
      } else if (this.sort == "desc") {
        this.sort = "submitted";
      } else {
        this.sort = "asc";
      }
      this.reloadData();
    },
    showContextMenu(event) {
      console.log("context menu");
    },
    eventContextMenuHandler(event, jsEvent) {
      this.selectedEvent = event;
      const target = jsEvent.currentTarget;

      if ($(target).popup("is visible")) {
        $(target).popup("hide");
        return;
      }

      this.$nextTick(() => {
        $(target).popup({
          popup: $(this.$refs.contextMenuPopup).find(".popup"),
          on: "manual",
          delay: false,
          duration: 0,
          position: "bottom left",
          scrollContext: $("#pitches-list-view"),
          exclusive: true,
          movePopup: true,
          hideOnScroll: true,
          lastResort: true,
        });

        $(target).popup("show");
      });

      jsEvent.stopPropagation();
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#pitches {
  &.droppable {
    background: #ddd;
    padding-bottom: 3em;
    padding: 1em;
    border-radius: 1em;
    margin: -1em;
  }

  .fc-event {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    background-color: #fff;
    padding: 1.2em;
    box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
    min-height: 75px;

    &:hover {
      box-shadow: 0 4px 6px 0 rgba(34, 36, 38, 0.12),
        0 4px 12px 0 rgba(34, 36, 38, 0.15);
    }

    .vertical.border.label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 6px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0;
    }

    .fc-content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      z-index: 0 !important;

      .avatar {
        position: relative;
        margin-left: auto;
        padding-left: 1em;
        margin-top: -0.5em;
        margin-right: -0.5em;
      }

      .fc-title {
        .fc-meta {
          display: block;
          opacity: 0.6;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
