import Vue from "vue/dist/vue.esm";
import "idempotent-babel-polyfill";
import Vuex from "vuex";
import app from "./modules/app";
import task from "./modules/task";
import event from "./modules/event";
import comment from "./modules/comment";
import deliverable from "./modules/deliverable";
import calendar from "./modules/calendar";
import report from "./modules/report";
import sponsor from "./modules/sponsor";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    calendar,
    event,
    task,
    comment,
    deliverable,
    report,
    sponsor,
  },
});

document.addEventListener("turbo:load", function () {
  store.dispatch("app/setConfigFromMeta");
});

export default store;
