<template lang="html">
  <sui-modal
    v-model="isOpen"
    :close-icon="true"
    >
    <sui-modal-header v-if="header">{{header}}</sui-modal-header>

    <slot name="content">
      <sui-modal-content>
        <sui-modal-description>
          <sui-header>Default Modal Header</sui-header>
          <p>Default modal text</p>
        </sui-modal-description>
      </sui-modal-content>
    </slot>

    <slot name="actions">
    </slot>
  </sui-modal>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import SuiVue from "semantic-ui-vue";

Vue.use(SuiVue);

export default {
  data() {
    return {
      header: "",
      isOpen: false
    };
  },
  methods: {
    open() {
      this.$emit("open");
      this.isOpen = true;
    },
    close() {
      this.$emit("close");
      this.isOpen = false;
    }
  }
};
</script>

<style lang="css">
</style>
