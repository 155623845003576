<template>
  <div
    v-click-outside="cancelEditing"
    :class="{ completed, editing, overdue, duetoday }"
    class="task ui form item clearing"
  >
    <div v-show="!editing && task.id" class="content ui grid">
      <div
        class="ui checkbox column eleven wide"
        :class="{ checked: completed }"
      >
        <input
          class="hidden"
          type="checkbox"
          :checked="completed"
          @change="toggleComplete"
        />
        <label>
          <span @click.stop.prevent="startEditing">{{ task.name }}</span>
          <a
            v-if="showEvent"
            :href="task.url"
            class="event"
            @click.stop.prevent="openTask"
          >
            <i class="icon chevron right fitted" />
            {{ task.event.name }}
          </a>
        </label>
      </div>
      <div class="ui column three wide right aligned">
        <span v-if="task.due_on" class="date margin-right-tiny">
          {{ task.due_on | formatDate }}
        </span>

        <a
          :data-tooltip="'Show ' + task.user_login + '\'s tasks'"
          data-inverted
          data-variation="mini"
          @click.stop.prevent="clickAvatar"
        >
          <avatar
            v-if="task.user_login"
            class="ui image avatar"
            :title="task.user_login"
            :username="task.user_login"
            :src="task.user_gravatar"
            size="26"
          />
        </a>
      </div>
      <div class="ui icon buttons tiny compact nub column two wide">
        <a class="ui button" @click="startEditing">
          <i class="icon pencil alternate" />
        </a>
        <a class="ui button" :class="{ loading }" @click="removeTask">
          <i class="icon delete" />
        </a>
      </div>
    </div>

    <task-input
      v-if="editing || !task.id"
      class="content"
      :task="task"
      :users="users"
      :in-place="true"
      :parent-event="task.event"
      @cancelEditing="cancelEditing"
      @removeTask="removeTask"
    />
  </div>
</template>

<script>
import Avatar from "components/avatar.vue";
import ClickOutside from "vue-click-outside";
import TaskInput from "./TaskInput.vue";

const { $, moment } = window;

export default {
  directives: {
    ClickOutside,
  },
  components: {
    TaskInput,
    Avatar,
  },
  props: ["name", "task", "users", "grouping", "parentEvent"],
  data() {
    return {
      loading: false,
      newText: this.task.name,
      // editing: false
    };
  },
  computed: {
    showEvent() {
      return this.grouping != "event" && this.task.event && !this.parentEvent;
    },
    editing() {
      return this.task.editing;
    },
    completed() {
      return this.task.completed_on && this.task.completed_on !== "";
    },
    overdue() {
      return !this.completed && moment(this.task.due_on).isBefore();
    },
    duetoday() {
      return (
        !this.completed && moment(this.task.due_on).isSame(moment(), "day")
      );
    },
  },
  watch: {
    editing(val) {
      this.$emit("update:editing", val);
    },
  },
  mounted() {
    $(this.$el).find(".ui.checkbox").checkbox();
  },
  methods: {
    clickAvatar() {
      this.$emit("selectUser", this.task.user);
    },
    openTask() {
      this.$emit("openEvent", this.task);
    },
    toggleComplete() {
      if (this.task.completed_on) {
        this.$store.dispatch("task/markIncomplete", this.task);
      } else {
        this.$store.dispatch("task/markComplete", this.task);
      }
    },
    // addTask(addedTask) {
    //   this.editing = false;
    //   this.$emit("addTaskInPlace", this.task, addedTask);
    // },
    removeTask() {
      this.loading = true;
      this.$emit("removeTask");
      this.$store.dispatch("task/destroy", this.task);
    },
    startEditing() {
      this.$set(this.task, "editing", true);
      this.$nextTick(() => {
        $(this.$el).find("input.new-task").focus();
      });
    },
    cancelEditing() {
      if (this.task.id) {
        this.$set(this.task, "editing", false);
      }
      this.newText = this.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  padding-top: 0.5em !important;
}

.nub {
  visibility: hidden;
  /* margin-right: -25px; */
  // margin-left: 5px;
}

.item:hover .nub {
  visibility: visible;
}

.item label {
  word-break: break-all;
  display: block;
  line-height: 1.2;
  transition: color 0.4s;

  a.event {
    margin-left: 1em;
    font-size: 0.9em;
    color: #8e8e8e;
  }

  &:before,
  &:after {
    margin-top: -2px;
  }
}
.item.completed label,
.item.completed .ui.right.floated * {
  /*opacity: 0.5 !important;*/
}
.item.completed label,
.item.completed .ui.label {
  text-decoration: line-through;
}

.item.overdue {
  .date {
    color: rgb(235, 92, 107);
  }
}

.item.duetoday {
  .date {
    color: green;
  }
}
</style>
