<template lang="html">
  <div class="ui form sixteen wide" :class="{ loading }">
    <div v-if="errors" class="ui error message visible" v-html="errors" />
    <div
      v-if="duplicates && duplicates.length > 0"
      class="ui error message visible"
    >
      {{ duplicates.length }} duplicate deliverable{{
        duplicates.length > 1 ? "s" : ""
      }}
      not added.
    </div>

    <div class="field">
      <label for="" class="label"
        >Deliverable URLs or Names (one per line)</label
      >
      <div class="ui fluid search" ref="deliverables">
        <div class="ui input">
          <textarea
            v-model="urls"
            :class="{ 'file-hover': isFileHovering }"
            class="prompt"
            name="name"
            rows="3"
            cols="80"
            placeholder="Paste your deliverable URLs here and we'll label them automatically. Like magic!"
            @keydown.meta.enter.prevent="addUrls"
            @drop.prevent="handleFileDrop"
            @dragover.prevent="handleDragOver"
            @dragleave.prevent="handleDragLeave"
          />
        </div>
      </div>
    </div>
    <button class="ui button primary" type="submit" @click="addUrls">
      Add
    </button>
  </div>
</template>

<script>
const $ = window.jQuery;

export default {
  props: {
    endpoint: String,
  },
  data() {
    return {
      errors: null,
      duplicates: null,
      loading: false,
      urls: "",
      instagramReelsData: null,
      isFileHovering: false,
    };
  },
  watch: {
    urls(newVal) {
      this.alertForPfbidUrls(newVal);
      this.alertForIgStoriesUrl(newVal);
    },
  },
  mounted() {
    // const $this = this;
    this.$nextTick(() => {
      // $this.initAutocomplete();
    });
  },
  methods: {
    handleDragOver() {
      this.isFileHovering = true;
    },
    handleDragLeave() {
      this.isFileHovering = false;
    },
    handleFileDrop(event) {
      this.isFileHovering = false;
      const file = event.dataTransfer.files[0];
      if (
        file &&
        (file.type === "text/csv" || file.type === "text/tab-separated-values")
      ) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.urls = e.target.result;
          this.addUrls();
        };
        reader.readAsText(file);
      }
    },
    initAutocomplete() {
      const $this = this;
      $(this.$refs.deliverables).search({
        showNoResults: false,
        // type: "category",
        // debug: true,
        // verbose: true,
        cache: false,
        maxResults: 5,
        minCharacters: 2,
        duration: 50,
        searchDelay: 25,
        searchOnFocus: false,
        //selectFirstResult: true,
        // onSearchQuery(query) {

        //   return lastLine;
        // },
        onSelect(result) {
          let urls = $this.urls.split("\n");
          urls[urls.length - 1] = result.url;

          $this.urls = urls.join("\n");
          $($this.$refs.deliverables).search("hide results");
          return false;
        },
        apiSettings: {
          url:
            "/deliverables/search.json?providers[]=facebook&providers[]=instagram&providers[]=twitter&providers[]=pinterest&providers[]=tik_tok&q[deliverable_search_query]={query}",
          beforeSend: function (settings) {
            let lines = settings.urlData.query.split("\n");
            var lastLine = lines[lines.length - 1];
            settings.urlData.query = lastLine;

            if (lastLine.length < 3) {
              return false;
            }
            return settings;
          },
        },
        fields: {
          results: "results",
          title: "text",
          description: "description",
          image: "picture",
          url: "_",
        },
      });
    },
    alertForPfbidUrls(newVal) {
      if (
        newVal.includes("pfbid") ||
        newVal.includes("www.facebook.com/share")
      ) {
        this.showModalAlert(
          `Facebook post URLs of this format can no longer be added via a link. Please use Quick Search to search for your deliverable.`,
          () => {
            window.$("a.item[data-tab=search-pinterest]").click();
            window.$("div[data-tab=search-pinterest] .checkbox input").click();
            window
              .$(
                "div[data-tab=search-pinterest] .checkbox[data-for=facebook] input"
              )
              .click();
            this.urls = "";
          }
        );
      }
    },

    alertForIgStoriesUrl(newVal) {
      if (newVal.includes("instagram.com/stories")) {
        this.showModalAlert(
          `Instagram Stories can only be added from the Stories tab. If you're trying to add an expired Instagram Story, please follow <a href="https://help.influencekit.com/en/articles/5634991-how-to-add-expired-or-missing-instagram-stories-to-a-report" target="_blank">these steps</a>`,
          () => {
            window.$("a.item[data-tab=search]").click();
            window.$("div[data-tab=search] .addStory.link").click();
            this.urls = "";
          }
        );
      }
    },
    showModalAlert(content, action) {
      $("body")
        .modal({
          allowMultiple: true,
          class: "mini",
          closeIcon: false,
          content,
          actions: [
            {
              text: "Cancel",
              class: "deny",
            },
            {
              text: "Ok!",
              class: "primary approve",
            },
          ],
          onApprove() {
            if (action) {
              action();
            }
          },
        })
        .modal("show");
    },
    addUrls() {
      this.loading = true;
      this.errors = null;
      this.duplicates = null;

      const params = {
        deliverable_urls: this.urls,
      };
      if (this.$store && this.$store.state.report) {
        params.report_id = this.$store.state.report.id;
      }

      if (this.instagramReelsData) {
        params.instagram_reels_data = this.instagramReelsData;
        this.instagramReelsData = null;
      }

      $.create(`${this.endpoint}/create_multiple.json`, params)
        .done((data) => {
          this.$emit("create", data.saved);
          this.errors = data.errors;
          this.duplicates = data.duplicates;
          this.loading = false;
        })
        .fail((data) => {
          this.$emit("error", data.responseJSON.errors);
          this.errors = `Error: ${data.responseJSON.errors}`;
          this.loading = false;
        });
      this.urls = "";
    },
  },
};
</script>

<style lang="css"></style>
<style scoped>
textarea.file-hover {
  border: 2px dashed #a0a0a0 !important;
  background-color: #f0f8ff !important;
}
</style>
