import Vue from "vue/dist/vue.esm";
import axios from "axios";
import Toasted from "vue-toasted";
import _ from "lodash";

const http = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers["X-CSRF-TOKEN"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    return config;
  },
  (error) => Promise.reject(error.response)
);

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
  singleton: true,
});

const store = {
  namespaced: true,
  state: {
    saving: false,
    saved: false,
    errors: null,
    loading: false,
    loaded: false,
    editable: false,
    accessToken: null,
    report: {
      id: null,
      name: "New report",
      sections: {
        description: null,
        removeDuplicates: true,
        deliverables: {
          filters: {
            calendars: [],
            events: [],
            excluded_deliverables: [],
            categories: [],
            users: [],
            tags: [],
            organizations: [],
            sponsors: [],
            providers: [],
            start_date: null,
            end_date: null,
          },
        },
        queryObject: {
          logicalOperator: "and",
          children: [],
        },
      },
    },
    deliverables: [],
  },
  getters: {
    filters(state) {
      return state.report.sections.deliverables.filters;
    },
    queryObject(state) {
      return state.report.sections.queryObject;
    },
    hasQueryObject(state, getters) {
      return getters.queryObject["children"].length > 0;
    },
    hasFilters(state, getters) {
      return (
        _.compact(_.map(_.values(getters.filters), (v) => !_.isEmpty(v)))
          .length > 0
      );
    },
    deliverableErrorsCount(state) {
      return _.compact(
        _.map(_.values(state.deliverables), (d) => {
          return (
            !_.isEmpty(d.url) &&
            !_.isEmpty(d.last_attempted_at) &&
            _.isEmpty(d.last_fetched) &&
            !_.isEmpty(d.skip_refresh)
          );
        })
      ).length;
    },
    deliverableNotAttemptedCount(state) {
      return _.compact(
        _.map(_.values(state.deliverables), (d) => {
          return !_.isEmpty(d.url) && _.isEmpty(d.last_attempted_at);
        })
      ).length;
    },
    hasDeliverableErrors(state, getters) {
      return getters.deliverableErrorsCount > 0;
    },
  },
  mutations: {
    SHOW(state, report) {
      state.report = report;
      state.saved = true;
      return state;
    },
    SAVED(state, data) {
      state.report.id = data.id;
      state.saving = false;
      state.saved = true;
      return state;
    },
    DELIVERABLES(state, deliverables) {
      state.loading = false;
      state.loaded = true;
      state.deliverables = deliverables;
      return state;
    },
    SET_ACCESS_TOKEN(state, token) {
      state.accessToken = token;
      return state;
    },
    SET_EDITABLE(state, editable) {
      state.editable = editable;
      return state;
    },
    SET_LOADED(state, loaded) {
      state.loaded = loaded;
      return state;
    },
    SET_SAVED(state, saved) {
      state.saved = saved;
      return state;
    },
  },
  actions: {
    setLoaded({ commit }, loaded) {
      commit("SET_LOADED", loaded);
    },
    setSaved({ commit }, saved) {
      commit("SET_SAVED", saved);
    },
    setEditable({ commit }, editable) {
      commit("SET_EDITABLE", editable);
    },
    setAccessToken({ commit }, token) {
      commit("SET_ACCESS_TOKEN", token);
    },
    setDeliverables({ commit }, deliverables) {
      commit("DELIVERABLES", deliverables);
    },
    save({ commit, state }, report) {
      let url = `/reports`;
      let type = `post`;
      if (report.id) {
        url += `/${report.id}`;
        type = `patch`;
      }

      state.saving = true;
      state.errors = null;
      return new Promise((resolve, reject) => {
        window.$.ajax({
          url,
          type,
          dataType: "json",
          data: {
            report: {
              name: report.name,
              sections: JSON.stringify(report.sections),
            },
          },
          success(data) {
            commit("SAVED", data);
            resolve(data);
          },
          error(data) {
            state.saving = false;
            state.errors = data.responseJSON.errors;
            reject(data);
          },
        });
      });
    },
    loadDeliverables({ commit, state, getters }) {
      if (
        getters.filters.events.length > 0 ||
        getters.hasFilters ||
        getters.hasQueryObject
      ) {
        state.loading = true;
        return new Promise((resolve, reject) => {
          http
            .get("/deliverables.json", {
              paramsSerializer(params) {
                return window.$.param(params);
              },
              params: {
                query_object: getters.queryObject,
                report_id: state.report.id,
                access_token: state.accessToken,
                stats: true,
                q: {
                  calendar_id_in: getters.filters.calendars,
                  event_id_in: getters.filters.events,
                  id_not_in: getters.filters.excluded_deliverables,
                  user_id_in: getters.filters.users,
                  normalized_inferred_type_in: getters.filters.providers,
                  unscoped_event_event_category_id_in:
                    getters.filters.categories,
                  unscoped_event_tagged_with: getters.filters.tags,
                  unscoped_event_organization_id_in: getters.filters.sponsors,
                  unscoped_event_starts_at_dategteq: getters.filters.start_date,
                  unscoped_event_starts_at_datelteq: getters.filters.end_date,
                },
              },
            })
            .then((res) => {
              commit("DELIVERABLES", res.data);
              resolve(res);
            })
            .catch((err) => {
              state.loading = false;
              state.loaded = false;
              reject(err);
            });
        });
      }
      return false;
    },
    show({ commit, dispatch, state }, report) {
      commit("SHOW", report);
      if (_.isEmpty(state.deliverables)) {
        dispatch("loadDeliverables");
      }
    },
  },
};

export default store;
