<template>
  <div class="media_kit" :style="cssVars">
    <div class="frame_wrapper" style="">
      <template-loader
        :data="{ editable: false, mediaKit, templateOptions }"
        :type="mediaKit.template"
        :key="mediaKit.template"
      />
    </div>
  </div>
</template>

<script>
import templateLoader from "./templates/template_loader.vue";

export default {
  components: {
    templateLoader,
  },
  props: {
    mediaKit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      templateOptions: {},
    };
  },
  computed: {
    cssVars() {
      return {
        "--primary-color": this.mediaKit.primary_color,
        "--background-color": this.mediaKit.background_color,
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.media_kit {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary-color) !important;
  }
}
</style>
