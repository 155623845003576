<template>
  <div
    v-if="!loading"
    class="ui unstackable centered grid stretched"
    :class="classObject"
  >
    <div v-if="visibleMetrics.length < 1">
      <i class="icon pie chart large circular inverted margin-bottom-small" />
      <br />
      <p>No stats yet.</p>
    </div>
    <div
      v-for="type in visibleMetrics"
      :key="`${campaign.id}-${type}`"
      class="column"
    >
      <div
        v-if="chartData[type]"
        :class="{ 'ui flat bordered segment grid': !compact }"
      >
        <div class="four wide desktop column">
          <pie-chart
            height="50px"
            :legend="false"
            :donut="true"
            :colors="chartColors(type)"
            :data="chartData[type]"
          />
        </div>
        <div class="twelve wide desktop column">
          <div class="ui statistic mini">
            <div class="label">
              <span class="ui grey text">
                {{ type | humanize }}
                <span
                  v-if="type == 'engagement_rate'"
                  data-inverted=""
                  data-tooltip="Number of content engagements divided by impressions."
                  data-variation="mini"
                  class="ui tooltip"
                >
                  <i class="icon question mark circle grey" />
                </span>
              </span>
            </div>
            <div class="value">
              {{ statsTotals(type) | abbreviateNumber(1000, 1)
              }}{{ type == "engagement_rate" ? "%" : "" }}
            </div>

            <span
              v-if="statsChange(type) > 0 && !compact"
              class="ui small text"
              :class="{
                green: statsChange(type) > 0.05,
                grey: statsChange(type) < 0.05,
              }"
            >
              vs.
              {{ chartData["prev_month_" + type] | abbreviateNumber(10000, 1) }}
              last month
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="ui center aligned meta">
    <i class="icon pie chart large circular inverted margin-bottom-small" />
    <br />
    <p>Loading stats ...</p>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";

import Chartkick from "chartkick";
import VueChartkick from "vue-chartkick";
import { sum, mean, round, isEmpty } from "lodash";
import { mapState } from "vuex";
import store from "store";

Vue.use(VueChartkick, { Chartkick });
const $ = window.jQuery;

export default {
  store,
  props: {
    initialChartData: {
      type: Object,
      default() {
        return null;
      },
    },
    campaign: {
      type: Object,
      default() {
        return {};
      },
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const $this = this;
    return {
      chartData: {},
      loading: $this.initialChartData ? false : true,
    };
  },
  computed: {
    ...mapState("deliverable", {
      colors: "colors",
    }),
    classObject() {
      return {
        compact: this.compact,
        "four column": this.visibleMetrics.length == "4",
        "three column": this.visibleMetrics.length == "3",
        "two column": this.visibleMetrics.length == "2",
        "one column": this.visibleMetrics.length == "1",
      };
    },
    visibleMetrics() {
      let metrics = Object.keys(this.chartData);

      if (this.compact) {
        metrics = metrics.filter((m) => m != "engagement_rate");
      }

      metrics = metrics.filter(
        (m) => !m.includes("prev_month") && !m.includes("total_")
      );

      return metrics;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadChartData();
    });
  },

  methods: {
    loadChartData() {
      if (this.initialChartData) {
        this.chartData = this.initialChartData;
      } else {
        $.ajax({
          url: `/influencer_hub/${this.campaign.id}/pie_chart_data`,
        }).then((data) => {
          this.chartData = data;
          this.loading = false;
        });
      }
    },
    statsChange(type) {
      let prev = this.chartData[`prev_month_${type}`];
      let current = this.statsTotals(type);
      return (current - prev) / current;
    },
    statsTotals(t) {
      let total;
      if (t == "engagement_rate") {
        total = this.chartData["total_engagement_rate"];
      } else {
        total = sum(Object.values(this.chartData[t]));
      }

      return total;
    },
    chartColors(type) {
      const r = [];
      Object.keys(this.chartData[type]).forEach((k) => {
        r.push(this.colors[k]);
      });
      return r;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui.statistic.mini {
  max-width: 100%;
  text-overflow: ellipsis;

  .value {
    text-align: left;
    font-weight: 700;
    font-size: 2rem;
  }

  .label {
    text-align: left;
    font-weight: normal;
    font-size: 0.857142857em;
    white-space: nowrap;
    max-width: 100%;
    // overflow: hidden;
    text-overflow: ellipsis;
  }
}

.compact {
  .column {
    text-align: center;
  }

  .ui.statistic.mini {
    text-align: center;
    .label {
      text-align: center;
    }
    .value {
      text-align: center;
      font-weight: 500;
      font-size: initial;
    }
  }
}

i.circular.pie.chart {
  font-size: 2em;
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.16);
}
</style>
