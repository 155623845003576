// import "idempotent-babel-polyfill";
// import Vue from "vue/dist/vue.esm";

// document.addEventListener("turbo:load", function () {
//   if (document.querySelector("#notifications")) {
//     window.notificationsIndex = new Vue({
//       el: "#notifications",
//       data: {
//         searchString: "",
//       },
//       methods: {
//         showNotification(notification) {
//           if (this.searchString === "") {
//             return true;
//           }
//           return (
//             notification
//               .toLowerCase()
//               .indexOf(this.searchString.toLowerCase()) > -1
//           );
//         },
//       },
//     });
//   }
// });
