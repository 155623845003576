<template lang="html">
  <div class="ui segment">
    <div ref="bulkActionsMenu" class="ui basic popup">
      <div class="ui vertical secondary menu">
        <a
          class="item"
          data-do="edit"
          data-method="patch"
          @click.stop="bulkEdit"
        >
          <i class="icon edit" />
          Edit
        </a>
        <a
          class="item"
          data-do="destroy"
          data-method="patch"
          @click.stop="bulkDelete"
        >
          <i class="icon trash alternate outline" />
          Delete
        </a>
      </div>
    </div>

    <div class="ui grid margin-bottom-small">
      <div class="eight wide column">
        <filter-labels />
      </div>

      <div class="eight wide column right aligned">
        <div
          :class="{ disabled: rowsSelected < 1 }"
          class="ui button right labeled icon dropdown floating"
        >
          <i class="caret down icon" />
          <span class="text"
            >Bulk actions
            <span v-if="rowsSelected > 0">({{ rowsSelected }})</span>
          </span>
          <div id="bulkActions" class="menu">
            <a
              class="item"
              data-do="edit"
              data-method="patch"
              @click.stop="bulkEdit"
            >
              <i class="icon edit" />
              Edit
            </a>
            <a
              class="item"
              data-do="destroy"
              data-method="patch"
              @click.stop="bulkDelete"
            >
              <i class="icon trash alternate outline" />
              Delete
            </a>
          </div>
        </div>

        <div class="ui icon simple dropdown inline">
          <i class="icon grey ellipsis vertical padding-left-small" />
          <div class="menu icon buttons left">
            <div class="header">Customize columns</div>
            <div class="divider" />

            <div class="ui form margin-xy-small">
              <div class="grouped fields">
                <div
                  v-for="field in fieldDefinitions"
                  v-if="field.attribute"
                  class="field"
                >
                  <div class="ui checkbox">
                    <input
                      v-model="field.visible"
                      type="checkbox"
                      @change="reRenderFields"
                    />
                    <label>{{ field.title }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vuetable
      ref="vuetable"
      :api-url="eventsEndpoint"
      :append-params="appendParams"
      :fields="fieldDefinitions"
      :table-height="tableHeight"
      :css="{ tableClass: 'ui very basic table', tableBodyClass: '' }"
      :per-page="50"
      detail-row-component="event-detail-row"
      :row-class="rowClass"
      @vuetable:pagination-data="onPaginationData"
      @vuetable:header-event="onHeaderEvent"
      @vuetable:loading="startLoading"
      @vuetable:loaded="stopLoading"
      @vuetable:cell-clicked="onCellClicked"
    >
      <template slot="name-slot" slot-scope="props">
        <a :href="props.rowData.url" data-event-modal="true">
          {{ props.rowData.name }}
        </a>
        <span
          v-if="props.rowData.deliverables_count > 0"
          class="ui grey text report-link margin-left-small"
        >
          <a :href="`${props.rowData.url}/report`" target="_blank">
            <i class="pie chart icon" />
          </a>
        </span>
      </template>

      <template slot="input-slot" scope="props">
        <div class="ui input fluid hovering">
          <input
            v-model="props.rowData[props.rowField.inputValue]"
            @input="updateRow(props.rowData)"
          />
        </div>
      </template>

      <template slot="dropdown-slot" slot-scope="props">
        <dropdown-field v-bind="props" @change="updateRow(props.rowData)" />
      </template>

      <div slot="user-slot" slot-scope="props">
        <template v-if="props.rowData.username && props.rowData.user_gravatar">
          <v-avatar
            class="ui image avatar margin-right-tiny"
            :username="props.rowData.username"
            :src="props.rowData.user_gravatar"
            size="28"
          />{{ props.rowData.username }}
        </template>
        <template v-else>
          {{ props.rowData.email }}
        </template>
      </div>
    </vuetable>
    <div class="ui grid margin-top-zero tableFooter">
      <div class="column eight wide">
        <vuetable-pagination-info
          ref="paginationInfo"
        ></vuetable-pagination-info>

        <a
          :href="exportUrl"
          class="ui margin-left-small"
          target="_blank"
          data-tooltip="Download all as CSV"
          data-inverted="true"
          data-variation="mini"
          data-position="right center"
        >
          <i class="ui icon download"></i>
        </a>
      </div>
      <div class="column eight wide">
        <vuetable-pagination
          ref="pagination"
          class="tiny"
          @vuetable-pagination:change-page="onChangePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue/dist/vue.esm";
import Vuetable from "vuetable-2";
import fieldDefinitions from "./FieldDefinitions.js";
import filterLabels from "./_filterLabels.vue";
import eventDetailRow from "./components/_detailRow.vue";
import EventBus from "components/eventbus";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";

import { mapState, mapGetters, mapActions } from "vuex";
import { findIndex, debounce } from "lodash";

Vue.component("event-detail-row", eventDetailRow);

import dropdownField from "./dropdownField.vue";

const $ = window.jQuery;

export default {
  // store,
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    dropdownField,
    filterLabels,
  },
  props: {
    fixedHeight: {
      type: Boolean,
      default: true,
    },
    hiddenFields: {
      type: Array,
      default() {
        return [];
      },
    },
    eventsEndpoint: String,
    tasksEndpoint: String,
    editable: {
      type: Boolean,
      default: true,
    },
    initialSelectedCalendars: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      loading: false,
      fieldDefinitions,
      recomputeHeight: 0,
      rowsSelected: 0,
      initialSort: [
        {
          field: "starts_at",
          direction: "desc",
        },
      ],
      tableCss: {
        tableClass: "ui table",
        tableBodyClass: "",
      },
    };
  },
  computed: {
    ...mapState("calendar", [
      "calendars",
      "selectedCalendars",
      "selectedCategory",
      "selectedUser",
      "searchQuery",
      "fieldFilters",
    ]),
    ...mapGetters("calendar", [
      "categories",
      "users",
      "selectedStartDate",
      "selectedEndDate",
    ]),
    exportUrl() {
      let url = this.eventsEndpoint;
      let queryString = $.param(this.appendParams);

      return `${url}?export=csv&${queryString}`;
    },
    appendParams() {
      const $this = this;
      let params = {
        stats: true,
        calendar_ids: $this.selectedCalendars,
        event_category_id: $this.selectedCategory,
        user_id: $this.selectedUser,
        start: $this.selectedStartDate,
        end: $this.selectedEndDate,
      };

      if ($this.searchQuery && $this.searchQuery.indexOf("#") != -1) {
        params["q[tags_name_cont]"] = $this.searchQuery.replace("#", "");
      } else {
        params[
          "q[user_login_or_organization_name_or_email_or_description_or_name_or_tags_name_cont]"
        ] = $this.searchQuery;
      }

      for (let [key, obj] of Object.entries($this.fieldFilters)) {
        let { operator, value } = obj;
        if (operator && value) {
          params[`q[${key}_${operator}]`] = value;
        }
      }

      return params;
    },
    tableHeight() {
      if (!this.fixedHeight) {
        return null;
      }

      let recompute = this.recomputeHeight;
      let val = "auto";
      const thisOffset = $(this.$el).offset();
      if (thisOffset) {
        const headerHeight = $(this.$el)
          .find(".vuetable-head-wrapper")
          .height();

        const paginationHeight = $(this.$el).find(".tableFooter").height();
        val =
          $(window).height() -
          thisOffset.top -
          headerHeight -
          paginationHeight -
          88;
      }
      return `${val}px`;
    },
    blankSlate() {
      if (this.loading) {
        return `Loading ...`;
      } else {
        return `<div class='ui placeholder segment'>
          <div class='inline centered'>
            <h3>
              You have no campaigns.
            </h3>
            <p>
              <a class="ui button primary" href='/events/new'>Create a Campaign</a>
            </p>
            <p class="margin-top-large">
              <i class='icon question circle'></i>
              <a target="_blank" href='https://help.influencekit.com?q=campaigns'>Learn more about how campaigns work</a>.
            </p>

          </div>
        </div>
        `;
      }
    },
    currentHiddenFields() {
      return this.fieldDefinitions
        .filter((field) => !field.visible)
        .map((field) => field.attribute);
    },
  },

  watch: {
    currentHiddenFields(val, oldVal) {
      if (val != oldVal) {
        this.setLocalStorage("hiddenFields", JSON.stringify(val));
      }
    },
    fieldFilters: {
      handler() {
        this.refetchEvents();
        this.goToFirstPage();
      },
      deep: true,
    },
    rowsSelected() {
      this.$nextTick(() => {
        this.updateHeight();
      });
    },
    selectedCategory() {
      this.refetchEvents();
      this.goToFirstPage();
    },
    selectedCalendars() {
      if (this.calendars.length === 1) return;

      this.refetchEvents();
      this.goToFirstPage();
    },
    selectedUser() {
      this.refetchEvents();
      this.goToFirstPage();
    },
    searchQuery() {
      this.refetchEvents();
      this.goToFirstPage();
    },

    selectedStartDate() {
      this.refetchEvents();
      this.goToFirstPage();
    },

    selectedEndDate() {
      this.refetchEvents();
      this.goToFirstPage();
    },
  },

  mounted() {
    this.$store.commit("calendar/setEditable", this.editable);

    let hide = this.hiddenFields.concat(
      JSON.parse(this.getLocalStorage("hiddenFields", "[]"))
    );

    this.fieldDefinitions.map((f) => {
      if (hide.indexOf(f.attribute) != -1) {
        f.visible = false;
      }
      return f;
    });

    this.$nextTick(() => {
      window.fixCalendar();
      this.reRenderFields();

      this.updateHeight();
      $(this.$el).find(".ui.dropdown").dropdown();

      this.initPopups();

      if (this.calendars.length === 1) {
        this.$set(this.fieldDefinitions[2], "visible", false);
      }
    });

    $(window).on("resize", () => {
      this.updateHeight();
    });

    this.$watch(
      () => this.$refs.vuetable.selectedTo.length,
      (value) => {
        this.rowsSelected = value;
      }
    );

    EventBus.$on("table:refreshEvent", this.refreshEvent);
    EventBus.$on("table:refetchEvents", this.refetchEvents);
  },
  destroyed() {
    EventBus.$off("table:refreshEvent", this.refreshEvent);
    EventBus.$off("table:refetchEvents", this.refetchEvents);
  },
  methods: {
    ...mapActions("event", {
      update: "update",
      destroy: "destroy",
    }),
    ...mapActions("calendar", {
      setCalendarLoading: "setLoading",
    }),
    startLoading() {
      this.loading = true;
      this.setCalendarLoading(true);
    },
    stopLoading() {
      this.loading = false;
      this.setCalendarLoading(false);
    },
    updateRow: debounce(function (event) {
      this.update(event);
    }, 750),
    onHeaderEvent(type, payload) {
      if (type === "setFieldFilters") {
        this.$set(this, "fieldFilters", payload);
      }
      if (type === "toggleFilters") {
        this.toggleFilters();
      }
    },
    goToFirstPage() {
      this.$refs.vuetable.gotoPage(1);
    },
    bulkEdit() {
      let params = {
        ids: this.$refs.vuetable.selectedTo,
        do: "edit",
      };

      $.ajax({
        url: `/events/edit_multiple`,
        data: params,
        type: "patch",
      });
    },
    bulkDelete() {
      let params = {
        ids: this.$refs.vuetable.selectedTo,
        do: "destroy",
      };

      $.ajax({
        url: `/events/edit_multiple`,
        data: params,
        type: "patch",
      });
    },
    updateHeight() {
      this.$nextTick(() => {
        this.recomputeHeight++;
      });
    },

    refetchEvents() {
      this.$nextTick(() => {
        this.$refs.vuetable.selectedTo = [];
        this.$refs.vuetable.reload();
        this.updateHeight();
      });
    },
    removeEvent() {
      //noop
    },
    refreshEvent(event) {
      const $this = this;
      const foundIndex = findIndex($this.$refs.vuetable.tableData, {
        id: event.id,
      });
      if (foundIndex != undefined) {
        this.$set($this.$refs.vuetable.tableData, foundIndex, event);
      }
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);

      this.updateHeight();
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
      this.updateHeight();
    },
    reRenderFields() {
      this.$refs.vuetable.normalizeFields();
    },
    initPopups() {
      $(this.$refs.bulkActionsButton).popup({
        popup: $(this.$refs.bulkActionsMenu),
        position: "bottom center",
        on: "click",
      });
    },
    onCellClicked(event) {
      if (event.data.deliverables_count > 0) {
        this.$refs.vuetable.toggleDetailRow(event.data.id);
      }
    },
    rowClass(dataItem, index) {
      return {
        expanded: this.$refs.vuetable.isVisibleDetailRow(dataItem.id),
      };
    },

    localStorageKey(key) {
      let string = "";
      string = `calendar_${this.initialSelectedCalendars
        .map((c) => c.id)
        .join("_")}`;

      return `${string}:table:${key}`;
    },
    setLocalStorage(key, val) {
      return localStorage.setItem(this.localStorageKey(key), val);
    },
    getLocalStorage(key, fallback) {
      return localStorage.getItem(this.localStorageKey(key)) || fallback;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup.item.menu {
  width: 150px !important;
  .item {
    text-align: left !important;
    justify-content: start !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
}
</style>

<style lang="scss">
.vuetable-body-wrapper {
  overflow: auto;
}

table.vuetable {
  th.sortable {
    i.sort-icon {
      display: none !important;
    }
    &:hover {
      i.sort-icon {
        display: block !important;
      }
    }
  }

  thead {
    tr {
      th {
        background: #fff !important;
      }
    }
  }

  tr {
    span.report-link {
      display: none;
      a {
        color: #767676 !important;
      }
    }

    &:hover {
      span.report-link {
        display: inline-block;
      }

      td.expandable:not(:empty) {
        &:after {
          content: "\f107";
          font-family: Icons;
          margin-left: 1em;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    &.expanded {
      td.expandable:not(:empty) {
        &:after {
          content: "\f106";
          font-family: Icons;
          margin-left: 1em;
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  tr.vuetable-detail-row {
    border-top: none;
    td {
      border-top: none;
    }
  }

  tr + tr.vuetable-detail-row,
  tr.expanded {
    background: rgba(0, 0, 0, 0.04);
  }
}
</style>
