<template>
  <div>
    <div
      v-for="filter in fieldFiltersWithValues"
      :key="filter.value"
      class="ui label"
    >
      {{ filterAsLabel(filter) }}
      <i
        class="icon times fitted link"
        @click="clearFilter(filter.filterField)"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapState("calendar", ["fieldFilters"]),
    fieldFiltersWithValues() {
      let filters = [];
      for (const key in this.fieldFilters) {
        if (this.fieldFilters[key].value) {
          filters.push(this.fieldFilters[key]);
        }
      }
      return filters;
    }
  },
  methods: {
    ...mapActions("calendar", ["clearFilter"]),
    filterAsLabel(filter) {
      let operator = (() => {
        switch (filter.operator) {
          case "eq":
            return "is";
          case "not_eq":
            return "is not";
          case "cont":
            return "contains";
          case "not_cont":
            return "doesn't contain";
          case "gt":
            return "greater than";
          case "lt":
            return "less than";
          case "date_between":
            return "between";
          default:
            return "is";
        }
      })();

      return `${filter.title} ${operator} ${this.valueAsLabel(filter)}`;
    },
    valueAsLabel(filter) {
      let val = (() => {
        switch (filter.operator) {
          case "date_between":
            var [first, last] = filter.value.split(",");
            return `${first} and ${last}`;
          default:
            return filter.value;
        }
      })();

      return val;
    }
  }
};
</script>

<style lang="css" scoped></style>
