<template lang="html">
  <div v-if="event">
    <template v-if="isEvent">
      <div class="ui simple dropdown item">
        <span class="capitalize">
          {{ event.aasm_state }}
        </span>
        <i class="dropdown icon" />
        <div class="menu">
          <div
            v-for="option in statuses"
            :key="option"
            class="item"
            @click.stop.prevent="changeStatus(option)"
          >
            <div
              class="ui label small fluid"
              :class="option"
            >
              {{ option }}
            </div>
          </div>
        </div>
      </div>

      <div class="ui simple dropdown item">
        <span class="capitalize">
          {{ event.event_category ? event.event_category.name : "Category" }}
        </span>
        <i class="dropdown icon" />
        <div class="menu">
          <div
            v-for="option in categoriesAsOptions"
            :key="option.value"
            class="item"
            @click.stop.prevent="changeCategory(option.value)"
          >
            <div
              class="ui left floated red empty circular label"
              :style="{ 'background-color': option.color + ' !important' }"
            />

            {{ option.label }}
          </div>
        </div>
      </div>

      <a
        v-if="event.deliverables_count > 0"
        :href="reportPath"
        target="_blank"
        class="item"
        @click.stop.prevent="openReport"
      >
        <i class="pie chart icon" />
        View Report
      </a>

      <a
        href="#"
        class="item"
        @click.stop.prevent="copy"
      >
        <i class="icon copy" />Duplicate</a>
    </template>

    <a
      href="#"
      class="item"
      @click.stop.prevent="destroy"
    ><i class="icon trash" />Delete</a>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import store from "store";
import { mapActions, mapState } from "vuex";

const $ = window.jQuery;

export default {
  store,
  components: {},
  filters: {},
  directives: {
    ClickOutside,
  },
  props: ["event"],
  data() {
    return {};
  },
  computed: {
    ...mapState("calendar", ["statuses", "calendars"]),
    isEvent() {
      return this.event.class_name == "event";
    },
    categoriesAsOptions() {
      return this.categories.map((c) => ({
        label: c.name,
        name: c.name,
        value: c.id,
        color: c.color,
      }));
    },
    categories() {
      return this.calendars.find((c) => c.id == this.event.calendar_id)
        .event_categories;
    },
    reportPath() {
      return `/calendars/${this.event.calendar_id}/events/${
        this.event.id
      }/report`;
    },
  },
  methods: {
    ...mapActions("event", {
      updateEvent: "update",
      duplicateEvent: "duplicate",
    }),
    openReport() {
      window.open(this.reportPath, "_blank");
      this.hide();
    },
    copy() {
      this.duplicateEvent(this.event);
      this.hide();
    },
    destroy() {
      this.$emit("destroy", this.event);
      this.hide();
    },
    toggleCompleted() {
      this.$emit("toggleCompleted", this.event);
    },
    changeStatus(status) {
      this.event.aasm_state = status;
      this.updateEvent({
        id: this.event.id,
        aasm_state: this.event.aasm_state,
      }); //circular stuff!
      this.hide();
    },
    changeCategory(category_id) {
      this.event.event_category_id = category_id;
      this.updateEvent({
        id: this.event.id,
        event_category_id: this.event.event_category_id,
      }); //circular stuff!
      this.hide();
    },
    hide() {
      $(".popup").popup("hide all");
    },
  },
  watch: {},
  mounted() {},
  updated() {},
};
</script>

<style lang="scss" scoped>
.ui.dropdown.item .menu {
  top: 5px !important;
  border-bottom-left-radius: 6px !important;
}
</style>
