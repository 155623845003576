import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import VueDropzone from "views/uploads/dropzone.vue";

window.uploaders = [];

window.initDropzones = function initDropzones(el) {
  const uploader = new Vue({
    el,
    components: {
      VueDropzone,
    },
  });
  return uploader;
};

document.addEventListener("turbo:load", function () {
  window.jQuery(".v-dropzone").each((i, el) => {
    window.uploaders.push(window.initDropzones(el));
  });
});
