<template>
  <component
    ref="component"
    :is="component"
    v-bind="data"
    v-if="component"
    :key="type"
    @add-annotation="addAnnotation"
    @delete-annotation="deleteAnnotation"
    @save="save"
  />
</template>
<script>
export default {
  name: "TemplateLoader",

  props: ["data", "type"],
  data() {
    return {
      component: null,
    };
  },
  computed: {
    loader() {
      if (!this.type) {
        return null;
      }
      return () => import(`./${this.type}`);
    },
  },
  mounted() {
    this.loader()
      .then(() => {
        this.component = () => this.loader();
      })
      .catch(() => {
        this.component = () => import("./default");
      });
  },
  methods: {
    save() {
      this.$emit("save");
    },
    deleteAnnotation(annotation) {
      this.$emit("delete-annotation", annotation);
    },
    addAnnotation(dataset, position) {
      this.$emit("add-annotation", dataset, position);
    },
  },
};
</script>
