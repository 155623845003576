import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

let $ = window.jQuery;

export default class extends Controller {
  static targets = [
    "input",
    "status",
    "uploader",
    "submit",
    "submitWithUploads",
  ];
  static values = {
    autoScroll: { type: Boolean, default: true },
    commentable: Object,
    hasUploads: { type: Boolean, default: false },
  };

  connect() {
    this.initChannel();
    this.initObserver();
    window.initUploader(this.uploaderTarget);
  }

  disconnect() {
    consumer.subscriptions.remove(this.channel);
  }

  reset() {
    this.stoppedTyping();
    this.forceToBottom();
  }

  hasUploadsValueChanged() {
    if (this.hasUploadsValue) {
      this.submitWithUploadsTarget.classList.remove("hidden");
      this.submitTarget.classList.add("hidden");
    } else {
      this.submitWithUploadsTarget.classList.add("hidden");
      this.submitTarget.classList.remove("hidden");
    }
  }

  initObserver() {
    const target = this.element;
    const observer = new MutationObserver((mutationList) => {
      for (const mutation of mutationList) {
        if (
          mutation.type === "childList" &&
          mutation.addedNodes.length > 0 &&
          mutation.addedNodes[0].nodeName.includes("INPUT")
        ) {
          this.hasUploadsValue = true;
        } else if (
          mutation.type === "childList" &&
          mutation.removedNodes.length > 0 &&
          mutation.removedNodes[0].classList &&
          mutation.removedNodes[0].classList.contains("card")
        ) {
          this.hasUploadsValue = false;
        }
      }
    });
    observer.observe(target, { childList: true, subtree: true });
  }

  initChannel() {
    let $this = this;
    this.channel = consumer.subscriptions.create(
      { channel: "CommentChannel", commentable_id: this.commentableValue.id },
      {
        connected() {
          // Called when the subscription is ready for use on the server
        },

        disconnected() {
          // Called when the subscription has been terminated by the server
        },

        received(data) {
          if (window.currentUserId == data.user.id) {
            return false;
          }
          if (data.typing == "started") {
            $this.statusTarget.innerHTML = `${data.user.login} is typing...`;
          } else {
            $this.statusTarget.innerHTML = "";
          }
        },
      }
    );

    this.isTyping = false;
    this.typingHandler = this.typing.bind(this);
    this.inputTarget.addEventListener("keydown", this.typingHandler);

    this.stoppedTyping = this.stoppedTyping.bind(this);
    this.inputTarget.addEventListener("blur", this.stoppedTyping);
  }

  typing() {
    // Don't broadcast if we're already typing
    if (!this.isTyping) {
      this.isTyping = true;
      this.channel.perform("typing", {
        typing: "started",
        commentable_id: this.commentableValue.id,
      });
    }

    // Do this no matter what so it resets the timer
    this.startTypingTimer();
  }

  stoppedTyping() {
    this.isTyping = false;
    this.stopTypingTimer();
    this.channel.perform("typing", {
      typing: "stopped",
      commentable_id: this.commentableValue.id,
    });
  }

  startTypingTimer() {
    // Clear the old timer or it'll still fire after X seconds. We're effectively resetting the timer.
    this.stopTypingTimer();
    // No need to save a reference to bound function since we don't need to reference it to stop the timer.
    // After 10 seconds of not typing, don't consider the user to be typing
    this.typingTimeoutID = setTimeout(this.stoppedTyping.bind(this), 2000);
  }

  stopTypingTimer() {
    if (this.typingTimeoutID) {
      clearTimeout(this.typingTimeoutID);
    }
  }

  forceToBottom = function () {
    if (this.autoScrollValue) {
      $("#container")[0].scroll(0, $(".main").outerHeight() + 10);
    }
  };
}
