import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";

import CalendarHeader from "views/calendars/calendar-header.vue";
import FullCalendar from "vue-full-calendar";
import PitchesListView from "views/events/pitches/list.vue";
import MyPitchesListView from "views/events/pitches/my-list.vue";
import store from "store";
// import EventBus from "components/eventbus";
import { mapState, mapActions } from "vuex";

// import CalendarsShowView from "views/calendars/show.vue";

Vue.use(FullCalendar);

const $ = window.jQuery;

const initCalendarComponents = function () {
  if (document.getElementById("calendars-show-view")) {
    (async () => {
      const { default: CalendarsShowView } = await import(
        "views/calendars/show.vue"
      );

      window.calendarView = new Vue({
        el: "#calendars-show-view",
        components: {
          "calendars-show-view": CalendarsShowView,
          "full-calendar": FullCalendar,
        },
      });

      if (document.getElementById("calendar-header")) {
        window.calendarHeader = new Vue({
          el: "#calendar-header",
          components: {
            CalendarHeader,
          },
        });
      }

      if (document.getElementById("sidebar")) {
        new Vue({
          store,
          el: "#sidebar",
          components: {
            PitchesListView,
            MyPitchesListView,
          },
          data: {
            // path: null
          },
          computed: {
            ...mapState("app", ["sidebarIsShown"]),
            ...mapState("route", {
              path: "path",
            }),
            show() {
              return this.sidebarIsShown && this.shouldShowSidebar;
            },
            shouldShowSidebar() {
              return this.path != "/table" && this.path != "/board";
            },
          },
          watch: {
            show(val) {
              this.resizeColumns(val);
            },
          },
          mounted() {},

          methods: {
            ...mapActions("app", [
              "showSidebar",
              "hideSidebar",
              "toggleSidebar",
            ]),
            resizeColumns(val) {
              this.$nextTick(() => {
                if (!val) {
                  $("#main")
                    .removeClass(
                      "twelve wide computer sixteen wide mobile column"
                    )
                    .addClass(
                      "sixteen wide computer sixteen wide mobile column"
                    );
                } else {
                  $("#main")
                    .removeClass(
                      "sixteen wide computer sixteen wide mobile column"
                    )
                    .addClass(
                      "twelve wide computer sixteen wide mobile column"
                    );
                }
                if (typeof window.fixCalendar != "undefined") {
                  window.fixCalendar();
                }
              });
            },
          },
        });
      }
    })();
  }
};

document.addEventListener("turbo:load", initCalendarComponents);
