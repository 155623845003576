import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import UploadsIndexView from "views/uploads/index.vue";

window.uploaders = [];

const $ = window.jQuery;

window.initUploader = function initUploader(el) {
  const uploader = new Vue({
    el,
    components: {
      UploadsIndexView,
    },
  });
  return uploader;
};

document.addEventListener("turbo:load", function () {
  $(".v-uploads").each((i, el) => {
    window.uploaders.push(window.initUploader(el));
  });
});
