import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";

import EditMediaKitView from "views/media_kits/edit.vue";

document.addEventListener("turbo:load", function () {
  if (document.getElementById("edit-media-kit-view")) {
    new Vue({
      el: "#edit-media-kit-view",
      components: {
        EditMediaKitView,
      },
    });
  }
});
