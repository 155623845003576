<template>
  <div class="column">
    <h4 :class="{ move: dragEnabled }">
      <div
        v-if="column.color"
        class="ui circular label empty"
        :style="{ backgroundColor: column.color }"
      />

      <span class="capitalize">{{ column.name }}</span>

      <a
        href="#"
        class="ui circular icon label link right floated"
        @click.stop.prevent="newQuickEvent"
      >
        <i class="icon add fitted" />
      </a>

      <div ref="quickEventPopup">
        <quick-event-form
          class="ui popup"
          style="width: 300px;"
          @save="hideEventPopup"
          @cancel="hideEventPopup"
        />
      </div>
    </h4>

    <vue-custom-scrollbar
      :suppress-scroll-x="true"
      :style="{ height }"
    >
      <draggable
        :list="events"
        group="events"
        class="draggable-container"
        @change="eventDropped($event)"
      >
        <card
          v-for="event in sortedEvents"
          :key="event.id"
          :event="event"
        />
      </draggable>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import store from "store";
import vueCustomScrollbar from "vue-custom-scrollbar";
import { mapActions, mapState, mapGetters } from "vuex";
import draggable from "vuedraggable";
import Card from "./_card.vue";
import QuickEventForm from "views/events/quickForm.vue";
import { orderBy } from "lodash";

const { $, moment } = window;

export default {
  store,
  components: {
    Card,
    vueCustomScrollbar,
    draggable,
    QuickEventForm,
  },
  props: {
    columnKey: {
      type: String,
      default: "_",
    },
    dragEnabled: {
      type: Boolean,
      default: true,
    },
    column: {
      type: Object,
      default() {
        return { name: "Uncategorized" };
      },
    },
    events: {
      default() {
        return [];
      },
      type: Array,
    },
    name: { type: String, default: "" },
    height: { type: String, default: "" },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("calendar", ["selectedCalendars"]),
    ...mapGetters("calendar", {
      selectedSortParam: "selectedSortParam",
    }),
    sortedEvents() {
      const [sort, order] = this.selectedSortParam.split("|");
      return orderBy(
        this.events,
        [
          function(event) {
            return event[sort];
          },
        ],
        [order]
      );
    },
  },
  methods: {
    ...mapActions("event", ["update", "setNewEvent"]),

    hideEventPopup() {
      $(this.$el)
        .find(".popup")
        .popup("hide all");
    },
    newQuickEvent(evt) {
      let params = {
        starts_at: moment(),
        ends_at: moment().add(1, "hour"),
        aasm_state: "approved",
      };

      if (this.column.calendar_id) {
        params.calendar_id = this.column.calendar_id;
      } else {
        params.calendar_id = this.selectedCalendars[0];
      }

      params[this.columnKey] = this.column.id;

      this.setNewEvent(params);

      this.hideEventPopup();
      $(evt.target)
        .popup({
          popup: $(this.$refs.quickEventPopup).find(".popup"),
          on: "manual",
          exclusive: true,
          position: "bottom left",
          lastResort: "bottom left",
          hideOnScroll: true,
        })
        .popup("show");
    },

    eventDropped(e) {
      if (e.added) {
        let event = e.added.element;

        if (
          this.column.calendar_id &&
          this.column.calendar_id !== event.calendar_id
        ) {
          return false;
        }
        event[this.columnKey] = this.column.id;
        this.update(event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.column {
  width: 300px !important;
  min-width: 300px !important;

  .draggable-container {
    height: 100%;
    &:empty {
      background: rgba(0, 0, 0, 0.02);
      border: 1px solid #00000008;
      border-radius: 6px;
    }

    .sortable-ghost {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: inherit;
        // background: rgba(255, 255, 255, 0.9);
        background: #e8e8e8;
        z-index: 1;
      }
    }
  }

  h4 {
    &.move {
      cursor: move;
    }
    i.handle {
      visibility: hidden;
      cursor: move;
    }
    &:hover {
      i.handle {
        visibility: visible;
      }
    }
  }
}
</style>
