import Vue from "vue/dist/vue.esm";
import CommentsIndexView from "views/comments/index.vue";

document.addEventListener("turbo:load", function () {
  if (document.querySelector("comments-index-view")) {
    var parent = document.querySelector("comments-index-view").parentNode;
    window.commentsView = new Vue({
      el: parent,
      components: {
        CommentsIndexView,
      },
    });
  }
});
