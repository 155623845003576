import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import kanban from "views/events/kanban.vue";
import VueTimeago from "vue-timeago";
import enLocale from "vue-timeago/locales/en-US.json";

Vue.config.ignoredElements = ["v-avatar"];

Vue.use(VueTimeago, {
  name: "timeago", // component name, `timeago` by default
  locale: "en-US",
  locales: {
    // you will need json-loader in webpack 1
    "en-US": enLocale,
  },
});

document.addEventListener("turbo:load", function () {
  if (document.getElementById("cardsView")) {
    window.cardsView = new Vue({
      el: "#cardsView",
      components: {
        "cards-view": kanban,
      },
      data() {
        return {};
      },
    });
  }
});
