import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import TasksIndexView from "views/tasks/index.vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

document.addEventListener("turbo:load", function () {
  if (document.getElementById("tasks-index-view")) {
    window.tasksView = new Vue({
      el: "#tasks-index-view",
      components: {
        "tasks-index-view": TasksIndexView,
      },
    });
  }
});
