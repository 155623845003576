import Vue from "vue/dist/vue.esm";
import axios from "axios";
import _ from "lodash";
import Toasted from "vue-toasted";

Vue.use(Toasted, {
  position: "bottom-right",
  duration: 2000,
  singleton: true,
});

const http = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers["X-CSRF-TOKEN"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    return config;
  },
  (error) => Promise.reject(error.response)
);

const resourcePath = (calendar, event) => {
  if (event.id) {
    return `/events/${event.id}.json`;
  } else if (calendar) {
    if (event.id) {
      return `/calendars/${calendar}/events/${event.id}.json`;
    }
    return `/calendars/${calendar}/events.json`;
  } else {
    return `/events.json`;
  }
};

const ALL = "ALL";
const SET_NEW = "SET_NEW";
const GET = "GET";
const CREATE = "CREATE";
const UPDATE = "UPDATE";
const DESTROY = "DESTROY";
const ERROR = "ERROR";
const PATH = "TAB";
const SET_DELIVERABLES = "SET_DELIVERABLES";

const updateItem = (item, all, remove) => {
  const thisItem = all.find((i) => i.id === item.id);
  const thisItemIndex = all.indexOf(thisItem);
  if (thisItemIndex > -1) {
    if (remove || false) {
      all.splice(thisItemIndex, 1);
    } else {
      all.splice(thisItemIndex, 1, item);
    }
  }
};

const addItem = (addedEvent, events) => {
  events.unshift(addedEvent);
};

// initial state
const initialState = {
  newEvent: {},
  all: [],
  current: {},
  calendar: null,
  error: null,
  path: null,
  loading: false,
  currentDeliverables: [],
};

// getters
const getters = {};

// actions
const actions = {
  updateEvent({ commit }, event) {
    commit(UPDATE, { item: event });
  },

  setPath({ commit }, { path }) {
    commit(PATH, { path });
  },

  setAll({ commit }, { items }) {
    commit(ALL, { items });
  },

  setCurrent({ commit }, { item }) {
    commit(GET, { item });
  },

  unsetCurrent({ commit }) {
    commit(GET, { item: {} });
  },

  setNewEvent({ commit }, event) {
    commit(SET_NEW, event);
  },

  setCurrentDeliverables({ commit }, { deliverables }) {
    commit(SET_DELIVERABLES, { deliverables });
  },

  create({ commit, state }, item) {
    state.loading = true;
    return new Promise((resolve, reject) => {
      http
        .post(resourcePath(state.calendar, item), { event: item })
        .then((res) => {
          commit(CREATE, { item: res.data });
          resolve(res);
        })
        .catch((err) => {
          commit(ERROR, { err });
          reject(err);
        })
        .finally(() => {
          state.loading = false;
        });
    });
  },

  duplicate({ commit, state }, event) {
    const eventCopy = JSON.parse(JSON.stringify(_.omit(event, "source")));

    state.loading = true;
    return new Promise((resolve, reject) => {
      http
        .post(`/events/${event.id}/clone.json`, {
          starts_at: eventCopy.start,
          ends_at: eventCopy.end,
        })
        .then((res) => {
          commit(CREATE, { item: res.data });
          resolve(res);
        })
        .catch((err) => {
          commit(ERROR, { err });
          reject(err);
        })
        .finally(() => {
          state.loading = false;
        });
    });
  },

  update({ commit, state }, item) {
    state.loading = true;
    return new Promise((resolve, reject) => {
      http
        .put(resourcePath(state.calendar, item), { event: item })
        .then((res) => {
          commit(UPDATE, { item: res.data });
          resolve(res);
        })
        .catch((err) => {
          commit(ERROR, { err });
          reject(err);
        })
        .finally(() => {
          state.loading = false;
        });
    });
  },

  destroy({ commit, state }, item) {
    return new Promise((resolve, reject) => {
      http
        .delete(resourcePath(item.calendar_id, item))
        .then((res) => {
          commit(DESTROY, { item });
          resolve(res);
        })
        .catch((err) => {
          commit(ERROR, { err });
          reject(err);
        })
        .finally(() => {
          state.loading = false;
        });
    });
  },
};

// mutations
const mutations = {
  [PATH](state, { path }) {
    state.path = path;

    // if (document.location.pathname !== path) {
    window.history.pushState({}, "", path);
    // }
  },
  [SET_NEW](state, event) {
    state.error = null;
    state.calendar = event.calendar_id;
    state.newEvent = event;
    state.loading = false;
  },
  [CREATE](state, { item }) {
    state.error = null;

    //merge the current state with the item returned from the server
    //to avoid overwriting the current state with stale data
    // state.current = Object.assign(item, state.current);
    state.current = _.mergeWith(
      {},
      state.current,
      _.omit(item, ["name", "description"]),
      (a, b) => (b === null ? a : undefined)
    );

    state.calendar = item.calendar_id;
    state.newEvent = {};
    addItem(item, state.all);
    Vue.toasted.success("Created");
  },
  [ALL](state, { items }) {
    state.error = null;
    state.all = items;
  },
  [GET](state, { item }) {
    state.error = null;
    state.current = item;
    state.calendar = item.calendar_id;
    updateItem(state.current, state.all);
  },
  [UPDATE](state, { item }) {
    state.error = null;
    state.current.id = item.id;
    state.current.last_synced_at = item.last_synced_at;
    state.current.wordpress_edit_url = item.wordpress_edit_url;
    // state.calendar = item.calendar_id;
    updateItem(item, state.all);
    Vue.toasted.success("Updated");
  },
  [DESTROY](state, { item }) {
    state.error = null;
    state.current = null;
    updateItem(item, state.all, true);
  },
  [ERROR](state, { err }) {
    if (err.response && err.response.data) {
      state.error = err.response.data;
      Vue.toasted.error(err.response.data.errors);
    } else {
      state.error = "Error";
    }
  },
  [SET_DELIVERABLES](state, { deliverables }) {
    state.currentDeliverables = deliverables;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
