import Vue from "vue/dist/vue.esm";
import axios from "axios";
import _ from "lodash";

const { $ } = window;

const http = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const tasksPerPage = 100;

http.interceptors.request.use(
  (config) => {
    config.headers["X-CSRF-TOKEN"] = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
    return config;
  },
  (error) => Promise.reject(error.response)
);

const resourcePath = (resource, params) => {
  if (params.id) {
    return `/${resource}/${params.id}.json`;
  }
  return `/${resource}.json`;
};

const ALL = "ALL";
// const GET = "GET";
const CREATE = "CREATE";
const UPDATE = "UPDATE";
const DESTROY = "DESTROY";
const ERROR = "ERROR";

const updateItem = (updatedTask, all, remove) => {
  const shouldRemove = remove || false;
  _.forOwn(all, (tasks) => {
    const itemIndex = shouldRemove
      ? _.findIndex(tasks, updatedTask)
      : _.findIndex(tasks, { id: updatedTask.id });

    if (shouldRemove && itemIndex !== -1) {
      tasks.splice(itemIndex, 1);
    } else if (itemIndex !== -1) {
      Vue.set(tasks, itemIndex, updatedTask);
    }
  });
};

const addItem = (addedTask, tasks) => {
  _.forEach(tasks, (group) => {
    group.unshift(addedTask);
  });
};

// initial state
const initialState = {
  newTask: {},
  all: {
    tasks: [],
    eventTasks: [],
    suggestedEventTasks: [],
  },
  current: {},
  error: "",
  loading: false,
  pagination: {
    tasks: {
      currentPage: 1,
      perPage: tasksPerPage,
      totalResults: 0,
    },
    eventTasks: {
      currentPage: 1,
      perPage: tasksPerPage,
      totalResults: 0,
    },
  },
};

// getters
const getters = {
  new: (state) => (parent) => {
    const e = state.newTask;
    if (parent) {
      e.event_id = parent.id;
    }
    return e;
  },
  all: (state) => (parent) => {
    if (parent) {
      return state.all.eventTasks;
    }
    return state.all.tasks;
  },
  suggestedEventTasks: (state) => (parent) => {
    if (parent) {
      return state.all.suggestedEventTasks;
    } else {
      return [];
    }
  },
  pagination: (state) => (parent) => {
    if (parent) {
      return state.pagination.eventTasks;
    }
    return state.pagination.tasks;
  },
  eventTaskCount: (state) => state.all.eventTasks.length,
  eventTaskCompletedCount: (state) => {
    return state.all.eventTasks.filter((t) => t.completed == true).length;
  },
};

// actions
const actions = {
  assignSuggestedTo({ state }, user) {
    state.all.suggestedEventTasks.forEach((task) => {
      task.user = user;
      task.user_id = user.id;
      task.user_login = user.login;
    });
  },
  updateTask({ state }, task) {
    updateItem(task, state.all);
  },
  resetState({ state }) {
    state.all.eventTasks = [];
    state.pagination.eventTasks = {
      currentPage: 1,
      perPage: tasksPerPage,
      totalResults: 0,
    };
  },

  loadAll({ commit, getters, state }, { parentEvent, page, queryParams }) {
    state.loading = true;
    commit("CURRENT_PAGE", { page, parentEvent });

    return new Promise((resolve, reject) => {
      http;

      let data = {
        per: getters.pagination(parentEvent).perPage,
        page: getters.pagination(parentEvent).currentPage,
      };
      if (queryParams) {
        data.q = queryParams.q;
        data.user_id = queryParams.user_id;
        data.sort = queryParams.sort;
      }
      if (parentEvent) {
        data.event_id = parentEvent.id;
      }

      // .get("/tasks.json", {
      //   params: {
      //     event_id: parentEvent && parentEvent.id,
      //     per: getters.pagination(parentEvent).perPage,
      //     page: getters.pagination(parentEvent).currentPage,
      //     q: queryParams.q,
      //     user_id: queryParams.user_id
      //   }
      // })

      $.ajax({
        url: "/tasks.json",
        data,
      })
        .then((data, status, res) => {
          commit("TOTAL_RESULTS", {
            totalResults: parseInt(res.getResponseHeader("total"), 10),
            parentEvent,
          });
          commit(ALL, { items: data.collection, parent: parentEvent });
          commit("SUGGESTED", {
            items: data.suggested,
            parent: parentEvent,
          });

          state.loading = false;
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setAll({ commit }, { items, parent }) {
    commit(ALL, { items, parent });
  },
  setAllFromSuggested({ commit }, { items, parent }) {
    const itemsToAdd = _.map(items, (t) => {
      Vue.set(t, "event_id", parent.id);
      Vue.set(t, "editing", true);
      return t;
    });
    commit(ALL, { items: itemsToAdd, parent });
  },
  saveAll({ dispatch, state }) {
    state.loading = true;
    const promises = [];
    _.each(state.all.eventTasks, (t) => {
      promises.push(dispatch("create", { item: t, inPlace: true }));
    });
    state.loading = false;
    return Promise.all(promises);
  },
  create({ commit }, { item, inPlace }) {
    return new Promise((resolve, reject) => {
      http
        .post("/tasks.json", item)
        .then((res) => {
          const createdItem = res.data;
          commit(CREATE, { originalItem: item, item: createdItem, inPlace });
          resolve(res);
        })
        .catch((err) => {
          commit(ERROR, { err });
          reject(err);
        });
    });
  },
  toggleComplete({ dispatch }, item) {
    if (item.completed_on) {
      dispatch("markIncomplete", item);
    } else {
      dispatch("markComplete", item);
    }
  },
  markIncomplete({ dispatch }, item) {
    item.completed_on = "";
    dispatch("update", item);
  },
  markComplete({ dispatch }, item) {
    item.completed_on = new Date().toISOString();
    dispatch("update", item);
  },
  update({ commit }, item) {
    return new Promise((resolve, reject) => {
      http
        .put(resourcePath("tasks", item), item)
        .then((res) => {
          commit(UPDATE, { item: res.data });
          resolve(res);
        })
        .catch((err) => {
          commit(ERROR, { err });
          reject(err);
        });
    });
  },
  destroy({ commit }, item) {
    if (!item.id) {
      return commit(DESTROY, { item });
    }

    return new Promise((resolve, reject) => {
      http
        .delete(resourcePath("tasks", item))
        .then((res) => {
          commit(DESTROY, { item });
          resolve(res);
        })
        .catch((err) => {
          commit(ERROR, { err });
          reject(err);
        });
    });
  },
};

// mutations
const mutations = {
  [CREATE](state, { originalItem, item, inPlace }) {
    state.error = null;
    state.current = item;
    state.newTask = {};

    if (inPlace) {
      Vue.set(originalItem, "id", item.id);
      Vue.set(originalItem, "user", item.user);
      Vue.set(originalItem, "user_id", item.user_id);
      Vue.set(originalItem, "user_login", item.user_login);
      Vue.set(originalItem, "editing", false);
    } else {
      addItem(item, state.all);
    }
  },
  [ALL](state, { items, parent }) {
    state.error = null;
    if (parent) {
      Vue.set(state.all, "eventTasks", items);
    } else {
      Vue.set(state.all, "tasks", items);
    }
  },
  ["SUGGESTED"](state, { items, parent }) {
    if (parent) {
      Vue.set(state.all, "suggestedEventTasks", items);
    }
  },
  [UPDATE](state, { item }) {
    state.error = null;
    state.current = item;
    updateItem(item, state.all);
  },
  [DESTROY](state, { item }) {
    state.error = null;
    state.current = null;
    updateItem(item, state.all, true);
  },
  [ERROR](state, { err }) {
    state.error = err.response && err.response.data ? err.response.data : err;
  },
  ["CURRENT_PAGE"](state, { page, parentEvent }) {
    if (parentEvent) {
      Vue.set(state.pagination.eventTasks, "currentPage", page);
    } else {
      Vue.set(state.pagination.tasks, "currentPage", page);
    }
  },
  ["TOTAL_RESULTS"](state, { totalResults, parentEvent }) {
    if (parentEvent) {
      Vue.set(state.pagination.eventTasks, "totalResults", totalResults);
    } else {
      Vue.set(state.pagination.tasks, "totalResults", totalResults);
    }
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
