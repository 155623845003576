<template>
  <div :key="rowData.id + '-' + rowField.attribute">
    <div class="ui dropdown search fluid hovering labeled selection tiny">
      <input ref="input" type="hidden" :value="rowData[rowField.attribute]" />
      <div class="text" v-html="initialValue"></div>
      <i class="icon dropdown"></i>
    </div>
  </div>
</template>

<script>
import VuetableFieldMixin from "vuetable-2/src/components/VuetableFieldMixin";

const $ = window.$;

export default {
  mixins: [VuetableFieldMixin],
  props: ["rowData", "rowField"],

  data() {
    return {
      options: []
    };
  },
  computed: {
    initialValue() {
      if (this.rowField.initialValue) {
        return this.rowField.initialValue(this.rowData);
      } else {
        return this.rowData[this.rowField.attribute];
      }
    }
  },
  methods: {
    emitChange() {
      this.$emit("change");
    },
    transformResponse(data) {
      this.options = Object.values(data);
      const options = Object.values(data).map(obj => {
        if (this.rowField.transformResponse) {
          return this.rowField.transformResponse(obj, this.rowData);
        } else {
          return {
            name: obj.name,
            value: obj.id,
            text: this.rowField.optionTemplate(obj),
            selected: this.rowData[this.rowField.attribute] === obj.id
          };
        }
      });

      return {
        success: true,
        results: options
      };
    },
    init() {
      const $this = this;
      $this.$nextTick(() => {
        $($this.$el)
          .find(".ui.dropdown")
          .dropdown({
            context: $this.$parent.$el,
            clearable: $this.rowField.preventClearing ? false : true,
            onChange(val) {
              $this.rowData[$this.rowField.attribute] = val;
              $this.emitChange();
            },
            preserveHTML: true,
            forceSelection: false,
            apiSettings: {
              cache: false,
              url: $this.rowField.optionsEndpoint($this.rowData),
              onResponse: $this.transformResponse
            },
            filterRemoteData: true,
            fullTextSearch: true
          });
      });
    }
  },
  mounted() {
    this.init();
  },
  updated() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
/deep/ .ui.dropdown > .remove.icon {
  z-index: 4 !important;
}
</style>
