import "idempotent-babel-polyfill";
import Vue from "vue/dist/vue.esm";
import store from "store";

const EventBus = new Vue({
  store,
  created() {
    this.$on("eventUpdated", this.updateEventInVuex);
    this.$on("eventDeliverablesUpdated", (deliverables) => {
      console.log("eventDeliverablesUpdated", deliverables);
      this.$store.dispatch("event/setCurrentDeliverables", { deliverables });
    });
  },
  methods: {
    updateEventInVuex(event) {
      if (event.class_name == "task") {
        // console.log("vuex:task/updateTask", event);
        this.$store.dispatch("task/updateTask", event);
      }
    },
  },
});

export { EventBus as default };
